import React, { useState } from 'react';
import axios from 'axios';
import './PersonalTrainingFormModal.css';

const PersonalTrainingFormModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    height: '',
    weight: '',
    contactNumber: '',
    currentFitnessGoal: '',
    biggestStruggles: '',
    medicalHistory: '',
    specialNote: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://prod-api.fitnastichealth.in/api/v1/leads/personalTraining', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        console.log('Form Data:', response.data);
        // Clear the form data
        setFormData({
          fullName: '',
          height: '',
          weight: '',
          contactNumber: '',
          currentFitnessGoal: '',
          biggestStruggles: '',
          medicalHistory: '',
          specialNote: ''
        });
        // Close the modal
        onClose();
      }
    } catch (error) {
      console.error('There was a problem with the axios operation:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Get 1 on 1 Training From Our ACSM Certified Trainers</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Full Name</label>
            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Height</label>
            <input type="text" name="height" value={formData.height} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Weight</label>
            <input type="text" name="weight" value={formData.weight} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Contact Number</label>
            <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>What's your current fitness goal</label>
            <input type="text" name="currentFitnessGoal" value={formData.currentFitnessGoal} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>What are the 3 biggest struggles you're facing with achieving them</label>
            <textarea name="biggestStruggles" value={formData.biggestStruggles} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Do you have any medical history we should be aware of?</label>
            <textarea name="medicalHistory" value={formData.medicalHistory} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>Special note</label>
            <textarea name="specialNote" value={formData.specialNote} onChange={handleChange} />
          </div>
          <button type="submit">Enquire Now</button>
        </form>
      </div>
    </div>
  );
};

export default PersonalTrainingFormModal;
