import React from 'react'
import './EmpSideProfile.css';
import logo from '../../../images/logo.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const EmpSideProfile = () => {
   
    const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { name: 'Home', path: '/emp/dashboard' },
    { name: 'Result', path: '/emp/result' },
    { name: 'Recommendations', path: '/emp/recommendations' },
    { name: 'Profile Settings', path: '/emp/profile-settings' }
  ];

  const handleItemClick = (path) => {
    navigate(path);
  };


  return (
    <div className='emp-usp-container'>
       <div style={{width:'100%'}}>
       <img className="dhr-usp-logo" src={logo} alt="logo" />
      <div style={{marginTop:'2rem'}} className="dhr-usp-content font-1">
        {menuItems.map((item) => (
          <div
            key={item.name}
            className={`dhr-usp-content-item ${location.pathname === item.path ? 'active-item-dhr-usp' : ''}`}
            onClick={() => handleItemClick(item.path)}
          >
            {item.name}
          </div>
        ))}
      </div>
        </div> 
     
      <div className="dhr-usp-logout font-1">Logout</div>
    </div>
  )
}

export default EmpSideProfile;