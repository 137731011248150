import React, { useEffect, useState, useRef } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function DifferenceComponent({details}) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 200});
  }, []);

  return (
    <>
      {
      (!isMobile) ? 
      <div>
        {
            details.map((detail, idx) => (
                <div key={idx} style={{
                    display:'flex',
                    borderBottom:'1px solid #CC203B',
                    marginTop:'20px',
                    paddingBottom: '15px'
                }}>
                  <div style={{
                    }}>
                    <img style={{}} width='60px' height = '60px' src={detail.img} alt="" />
                  </div>
            
                  <div style={{
                    marginLeft:'20px', 
                    }}>
                    <p className='font-1'style={{fontWeight:'bold', fontSize:'20px', marginBottom:'0rem'}}>
                        {detail.name}
                    </p>
                    <p className='font-1' style={{width:'350px', fontSize:'15px'}}>
                        {detail.description}
                    </p>
                  </div>
                </div>
            ))
        }

    </div>
      : 
      <>
        <div>
        {
            details.map((detail, idx) => (
                <div data-aos="fade-up" data-aos-easing="linear" key={idx} style={{
                    display:'flex',
                    borderBottom:'1px solid #CC203B',
                    marginTop:'20px',
                    paddingBottom: '15px',
                    marginInline:'1rem'
                }}>
                  <div style={{
                      width:"15%"
                    }}>
                    <img style={{
                      width:'20vw'
                    }} src={detail.img} alt="" />
                  </div>
            
                  <div style={{
                    marginLeft:'20px',
                    width:'70%'
                    }}>
                    <p className='font-1'style={{fontWeight:'bold', fontSize:'20px', marginBottom:'0rem'}}>
                        {detail.name}
                    </p>
                    <p className='font-1' style={{fontSize:'15px'}}>
                        {detail.description}
                    </p>
                  </div>
                </div>
            ))
        }

    </div>
      </>
    }
    </>
  );
}

export default DifferenceComponent;
