import React, { useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";

const MobileThankModal = ({ onClose }) => {
 

  return (
    <>
      <div
        className="modal"
        // onRequestClose={onClose}
        contentLabel="Example Modal"
      >
        <div
          style={{
            width: "80%",
            height: "18rem",
            background: "white",
            borderRadius: "25px",
            boxShadow: "0px 0px 8px 0px #00000040",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            animationName: "fadeIn",
            animationDuration: "0.5s"
          }}
        >
            <div style={{width:'100%',height:'100%', display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'start'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',paddingTop:'1.5rem',paddingBottom:'1rem',borderBottomWidth:'1.5px',borderBottomColor:'#CC203B',width:'100%'}}>
            <p className="font-1" style={{fontSize:'1.6rem',color:'#CC203B',marginBottom:'0rem',fontWeight:'700'}}>Thank you!</p>
              </div>
            <p className="font-1" style={{fontSize:'1.1rem',textAlign:'center',marginTop:'2rem'}}>Our team will get in <br />touch with you shortly.</p>
            <button
            className="cib-btn font-1"
            onClick={onClose}
              style={{
                border: "1.5px",
                borderColor: "#CC203B",
                borderStyle: "solid",
                borderRadius: "50px",
                paddingTop: "0.7rem",
                paddingBottom: "0.7rem",
                paddingLeft: "1.6rem",
                paddingRight: "1.6rem",
                color: "#CC203B",
                fontSize: "1.1rem",
                marginTop: "0.7rem",
                fontWeight: 600,
                width:'7.5rem'
              }}
            >
              OKAY
            </button>
         
            </div>
          
         
        </div>
      </div>
    </>
  );
};

export default MobileThankModal;
