import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { HomePage } from './pages/HomePage';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import AboutPage from './pages/AboutPage';
import TechPage from './pages/TechPage';
import ProgramsPage from './pages/ProgramsPage';
import ResourcesPage from './pages/ResourcesPage';
import CorporatePage from './pages/CorporatePage';
import NavBar from './components/NavbarBox/NavBar';
import EntryQuizPage from './pages/EntryQuizPage';
import EntryQuizAnim from './components/EntryQuizAnimation/EntryQuizAnim';
import { motion, AnimatePresence } from "framer-motion";
import DashboardLogin from './components/Dashboard/DashboardLogin';
import Dashboard from './components/Dashboard/Dashboard';
import HealthReportComponent from './components/HealthReportPage/HealthReportComponent';
import ContactPage from './pages/ContactPage';
import LoginPage from './dashboardHR/Pages/LoginPage/LoginPage';
import DashboardPage from './dashboardHR/Pages/DashboardPage/Dashboard';
import StaffPage from './dashboardHR/Pages/StaffPage/StaffPage';
import Participants from './dashboardHR/Pages/ParticipantsPage/Participants';
import EmpHomePage from './dashboardEmployee/Pages/DashboardPage/Dashboard';
import ResultPage from './dashboardEmployee/Pages/ResultPage/ResultPage';
import Recommendations from './dashboardEmployee/Pages/RecommendationsPage/Recommendations';
import ProfilePage from './dashboardEmployee/Pages/ProfilePage/ProfilePage';

const router = createBrowserRouter([
  //  Customer routers
  {
    path: "/",
    element: <><EntryQuizAnim/></>
  }, 
  {
    path:"/quiz",
    element:<><EntryQuizPage/></>
  },
  {
    path: "/home",
    element: <><NavBar/><HomePage/></>
  }, 
  {
    path: "/freeHealthReport",
    element: <HealthReportComponent type={"direct"}/>
  },
  {
    path: "/freeHealthReportQuiz",
    element: <HealthReportComponent type={"quiz"}/>
  },
  {
    path: "/programs",
    element: <><NavBar/><CorporatePage/></>
  },
  {
    path: "/ourTech",
    element: <><NavBar/><TechPage/></>
  },
  {
    path: "/resources",
    element: <><NavBar/><ResourcesPage/></>
  },
  {
    path: "/quiz",
    element: <><EntryQuizPage/></>
  },
  {
    path: "/dashboardLogin",
    element: <><DashboardLogin/></>
  },
  {
    path: "/dashboard",
    element: <><Dashboard/></>
  },
  {
    path: "/contact",
    element: <><NavBar/><ContactPage/></>
  },
  {
    path: "/about",
    element: <><NavBar/><AboutPage/></>
  },

  // HR dashboard routes
  {
    path: "/hr/dashboard/login",
    element: <><LoginPage/></>
  }
  ,{
    path: "/hr/dashboard",
    element: <><DashboardPage/></>
  }
  ,{
    path: "hr/all-staff",
    element: <><StaffPage/></>
  }
  ,{
    path: "/hr/participants",
    element: <><Participants/></>
  }
  ,{
    path: "/hr/profile-settings",
    element: <><DashboardPage/></>
  },

   // Employees dashboard routes
  {
    path: "/hr/dashboard/login",
    element: <><LoginPage/></>
  }
  ,{
    path: "/emp/dashboard",
    element: <><EmpHomePage/></>
  }
  ,{
    path: "emp/result",
    element: <><ResultPage/></>
  }
  ,{
    path: "/emp/recommendations",
    element: <><Recommendations/></>
  }
  ,{
    path: "/emp/profile-settings",
    element: <><ProfilePage/></>
  }

])

function App() {
  return (
    <>
      <AnimatePresence>
        <RouterProvider router={router}/>
      </AnimatePresence>
    </>
  );
}


export default App;

