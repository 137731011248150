import React from 'react';
 
import '../../App.css'
import img1 from '../../images/differencesImages/Asset 16.svg'
import img2 from '../../images/differencesImages/Asset 17.svg'
import img4 from '../../images/differencesImages/Asset 19.svg'
import img3 from '../../images/differencesImages/Asset 18.svg'
import img5 from '../../images/differencesImages/Asset 20.svg'
import img6 from '../../images/differencesImages/Asset 15.svg'
import './IntroBox.css';
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
 
import BannerPlans from '../Banner/BannerPlans';
import BannerPeople from '../Banner/BannerPeople';
import BannerLiveClass from '../Banner/BannerLiveClass'
import Slider from "react-slick";
import { faListSquares } from '@fortawesome/free-solid-svg-icons';
 
function IntroBox() {
  const [isMobile, setIsMobile] = useState(false);
  const settings = {
 //   dots: true,
    infinite: true,
    loop:true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  const settingsMobile = {
    //   dots: true,
       infinite: true,
       loop:true,
       slidesToShow: 4,
       slidesToScroll: 1,
       autoplay: true,
       speed: 2000,
       autoplaySpeed: 2000,
       cssEase: "linear",
     };
 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
 
    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size
 
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
       {!isMobile ? (<div style={{height:'55vw',
    // paddingTop:'7vw'
    }}>
      <Swiper
      style={{
        zIndex:'0'
      }}
      effect={'coverflow'}
      slidesPerView={'auto'}
      loop={true}
      autoplay={
        {
          delay:2500,
          disableOnInteraction:false
        }
      }
      navigation={false}
      pagination={false}
      modules={[Navigation, Autoplay, EffectCoverflow, Pagination]}>
        <SwiperSlide><BannerPeople/></SwiperSlide>
        <SwiperSlide><BannerPlans/></SwiperSlide>
        <SwiperSlide><BannerLiveClass/></SwiperSlide>
      </Swiper>
 
      {/* <div className='bottom-div'> */}
      <div className="slider-container-1">
      <div className='font-1' style={{height:'100%', width:'18%',display:'flex',justifyContent:'end',alignItems:"center",color:'rgb(174 26 49)',fontSize:'1.05rem',fontWeight:'500',paddingRight:'3%'}}>
       TRUSTED BY:
      </div>
      <Slider {...settings} >
      <img src={img4} alt="image1" className='image-style-1' />
          <img src={img3} alt="image2" className='image-style-2' />
          <img src={img2} alt="image3" className='image-style-3' />
          <img src={img1} alt="image4" className='image-style-4' />
          <img src={img5} alt="image5" className='image-style-5' />
          <img src={img6} alt="image5" className='image-style-6' />
         
       
      </Slider>
      </div>
      {/* </div> */}
     
     
    </div>):
    <div style={{
      // height:'80vh',
    // paddingTop:'7vw'
    }}>
      <Swiper
      style={{
        zIndex:'0'
      }}
      effect={'coverflow'}
      slidesPerView={'auto'}
      loop={true}
      autoplay={
        {
          delay:2500,
          disableOnInteraction:false
        }
      }
      navigation={false}
      pagination={false}
      modules={[Navigation, Autoplay, EffectCoverflow, Pagination]}>
        <SwiperSlide><BannerPeople/></SwiperSlide>
        <SwiperSlide><BannerPlans/></SwiperSlide>
        <SwiperSlide><BannerLiveClass/></SwiperSlide>
      </Swiper>
 
      <div className='slider-container-2' style={{}}>
          {/* <img alt="image1" className='m-image-style-1' />
          <img alt="image1" className='m-image-style-1' />
          <img alt="image1" className='m-image-style-1' />
          <img alt="image1" className='m-image-style-1' />
          <img alt="image1" className='m-image-style-1' /> */}
          {/* <div className='m-image-style-1'></div>
          <div className='m-image-style-1'></div>
          <div className='m-image-style-1'></div>
          <div className='m-image-style-1'></div> */}
          <Slider {...settingsMobile} >
          <img src={img4} alt="image1" className='m-image-style-1' />
          <img src={img3} alt="image2" className='m-image-style-2' />
          <img src={img2} alt="image3" className='m-image-style-3' />
          <img src={img1} alt="image4" className='m-image-style-4' />
          <img src={img5} alt="image5" className='m-image-style-5' />
          <img src={img6} alt="image5" className='m-image-style-6' />
          </Slider>
       
          {/* <img alt="image1" className='m-image-style-1' /> */}
      </div>
    </div>
    }
     
    </>
  );
}
 
export default IntroBox;