import React from 'react'
import './ResultPage.css'
import EmpSideProfile from '../../Components/EmpSideProfile/EmpSideProfile'

const ResultPage = () => {
  return (
    <div className='erp-main-container-dash'>
    <div className='dhr-main-container-left'>
     <EmpSideProfile/>
    </div>
    <div className="result-health-report-container">
      <header className="header">
        <div>
        <h1>Welcome XYZ!</h1>
        <p>A preview of your complete health report.</p>
        </div>
        <button
                style={{
                  border: "1.5px",
                  borderColor: "#CC203B",
                  borderStyle: "solid",
                  borderRadius: "50px",
                  paddingTop: "0.7rem",
                  paddingBottom: "0.5rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  // marginTop: "5%",
                  color: "#CC203B",
                  fontSize: "1.3vw",
                  fontWeight: 400,
                }}
                className="font-1 download-report-btn"
              >
               Download Report
              </button>
      </header>
      <div className="content">
        <div className="score-and-parameters">
          <div className="health-score">
            <span>00</span>
            <p style={{marginBottom:'0rem',color:'grey'}}>Health Score</p>
          </div>
          <div className="parameters">
            <div className="parameter param1">Parameter 1</div>
            <div className="parameter param2">Parameter 2</div>
            <div className="parameter param3">Parameter 3</div>
            <div className="parameter param4">Parameter 4</div>
          </div>
          <div className="mid-parameters">
            <div style={{height:'240px',width:'65%',backgroundColor:'white',borderRadius:'8px',display:'flex',alignItems:'end',padding:'1rem'}}>
            parameter 5
            </div>
            <div style={{height:'240px',width:"30%",display:'flex',flexDirection:'column',gap:'15px'}}>
                <div style={{backgroundColor:'white',height:'112.5px',width:'100%',borderRadius:'8px',display:'flex',alignItems:'end',padding:'1rem'}}>parameter 6</div>
                <div style={{backgroundColor:'white',height:'112.5px',width:'100%',borderRadius:'8px',display:'flex',alignItems:'end',padding:'1rem'}}>parameter 7</div>
            </div>

            
          </div>
          <div className="mid-parameters">
            <div style={{height:'240px',width:'65%',backgroundColor:'white',borderRadius:'8px',display:'flex',alignItems:'end',padding:'1rem'}}>
            parameter 5
            </div>
            <div style={{height:'240px',width:"30%",display:'flex',flexDirection:'column',gap:'15px'}}>
                <div style={{backgroundColor:'white',height:'112.5px',width:'100%',borderRadius:'8px',display:'flex',alignItems:'end',padding:'1rem'}}>parameter 6</div>
                <div style={{backgroundColor:'white',height:'112.5px',width:'100%',borderRadius:'8px',display:'flex',alignItems:'end',padding:'1rem'}}>parameter 7</div>
            </div>

            
          </div>
        </div>
        <div className="body-diagram">
          
        </div>
      </div>
     
    </div>
</div>
  )
}

export default ResultPage