import React, { useEffect, useState } from 'react';
// import figureBox from '../../images/figureBox.png';
import { useInView } from 'react-intersection-observer';
import '../FigureBox/FigureBox.css'
import axios from 'axios';
import BASE_URL from '../../Utils/Constants'

function FigureBox() {
  const [odometerValues, setOdometerValues] = useState([0, 0, 0]);

  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const [data, setData] = useState({
    clientsAcquired: null,
    testsDone: null,
    yearsExperience: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${BASE_URL}api/v1/figures/count`)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (inView) {
      // Dynamically load the CSS file
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = 'https://github.hubspot.com/odometer/themes/odometer-theme-default.css';
      document.head.appendChild(link);

      // Dynamically load the JavaScript file
      const script = document.createElement('script');
      script.src = 'https://github.hubspot.com/odometer/odometer.js';
      script.async = true;
      script.onload = () => {
        // Once the script is loaded, initialize the odometers
        const odometer1 = new window.Odometer({ el: document.getElementById('odometer1'), value: odometerValues[0] });
        const odometer2 = new window.Odometer({ el: document.getElementById('odometer2'), value: odometerValues[1] });
        const odometer3 = new window.Odometer({ el: document.getElementById('odometer3'), value: odometerValues[2] });

        setTimeout(() => {
          // setOdometerValues([6503, 2891, 3]);
          setOdometerValues([data.clientsAcquired, data.testsDone, data.yearsExperience]);
        }, 100);
      };
      document.body.appendChild(script);
    }
  }, [inView, odometerValues])

  return (
    <div ref={ref} style={{ display: 'flex', justifyContent: 'center' }}>
      <div className='figBox'  style={{
    borderRadius: '2.5rem', /* 40px / 16px = 2.5rem */
    backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/figureBox.png)`,
    padding: '1.25rem', /* 20px / 16px = 1.25rem */
    backgroundSize: 'cover',
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    height: '15rem', /* No conversion needed since it's already in rem */
    margin: '1.875rem 4.375rem', /* 30px / 16px = 1.875rem, 70px / 16px = 4.375rem */
    marginTop: '1.875rem', /* 30px / 16px = 1.875rem */
    marginBottom: '1.875rem', /* 30px / 16px = 1.875rem */
    alignItems: 'center',
    width: '62.5rem', /* 1000px / 16px = 62.5rem */
    paddingLeft: '6.25rem', /* 100px / 16px = 6.25rem */
    paddingRight: '6.25rem', /* 100px / 16px = 6.25rem */
    justifyContent: 'space-between'
}}
>
        <div className='dataTextContent'  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center',justifyContent:'center' }}>
        <div style={{display:'flex', alignItems:'center', justifyContent:'center',alignContent:'center'}}>
            <div style={{ color: 'transparent', fontSize: '4vw', fontWeight: '800',WebkitTextStroke:"1.5px white" }} className="odometer font-1" id="odometer1">
              {odometerValues[0]}</div>
            <div style={{ color: 'transparent', fontSize: '4vw', fontWeight: '800',WebkitTextStroke:"1.5px white",marginLeft:'3px' }}>+</div>
          </div>          
          <p style={{ color: 'white', fontSize: '1.5vw' }}>Clients Acquired</p>
        </div>
        <div className='dataTextContent' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
          <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div style={{ color: 'transparent', fontSize: '4vw', fontWeight: '800',WebkitTextStroke:"1.5px white" }} className="odometer" id="odometer2">{odometerValues[1]}</div>
            <div style={{ color: 'transparent', fontSize: '4vw', fontWeight: '800',WebkitTextStroke:"1.5px white" }}>+</div>
          </div>
          <p style={{ color: 'white', fontSize: '1.5vw' }}>Tests Done</p>
        </div>
        <div className='dataTextContent' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
        <div style={{ color: 'transparent', fontSize: '4vw', fontWeight: '800',WebkitTextStroke:"1.5px white" }} className="odometer" id="odometer3">{odometerValues[2]}</div>
          <p style={{ color: 'white', fontSize: '1.5vw' }}>Years Experience</p>
        </div>
      </div>
    </div>
  );
}

export default FigureBox;
