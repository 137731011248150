import React from 'react';
import './StaffTable.css';

const StaffTable = () => {
  const users = [
    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },
    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },

    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },
    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },
    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },
    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },
    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },
    { fullName: 'Lorem ipsum', phoneNumber: '0000000000', email: 'abc@gmail.com', lastActive: '30 July \'24' },
   

    // Add more user data here...
  ];

  return (
    <div className="staff-table-container">
      <table className="user-table">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Last Active</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{user.fullName}</td>
              <td>{user.phoneNumber}</td>
              <td>{user.email}</td>
              <td>{user.lastActive}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button className="prev-button">❮</button>
        <span className="page-info">1/10</span>
        <button className="next-button">❯</button>
      </div>
    </div>
  );
};

export default StaffTable;
