import React from 'react'
import 'swiper/css/bundle';
import { Container } from 'react-bootstrap';

import { Swiper, SwiperSlide } from "swiper/react";

import '../../App.css'
import { useState,useEffect } from 'react';
import PersonalTrainingFormModal from '../FormModal/PersonalTrainingForm/PersonalTrainingFormModal';
import PersonalizedNutritionFormModal from '../FormModal/PersonalizedNutritionForm/PersonalizedNutritionFormModal';
import MetabolicHealthFormModal from '../FormModal/MetabolicHealthFormModal/MetabolicHealthFormModal'
import LiveWorkoutFormModal from '../FormModal/LiveWorkoutFormModal/LiveWorkoutFormModal';

import { EffectCoverflow, Autoplay, Pagination, Navigation } from 'swiper/modules';
import './CorporateCrousel.css'

const CorporateCrousel = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isPersonalTrainingModalOpen, setPersonalTrainingModalOpen] = useState(false);
  const [isNutritionModalOpen, setNutritionModalOpen] = useState(false);
  const [metabolicHealthModalOpen, setMetabolicHealthModalOpen] = useState(false);
  const [liveWorkoutFormModalOpen, setLiveWorkoutModalOpen] = useState(false);


  const openPersonalTrainingModal = () => setPersonalTrainingModalOpen(true);
  const closePersonalTrainingModal = () => setPersonalTrainingModalOpen(false);

  const openNutritionModal = () => setNutritionModalOpen(true);
  const closeNutritionModal = () => setNutritionModalOpen(false);

  const openMetabolicHealthModal = () => setMetabolicHealthModalOpen(true);
  const closeMetabolicHealthModal = () => setMetabolicHealthModalOpen(false);

  const openLiveWorkoutModal = () => setLiveWorkoutModalOpen(true);
  const closeLiveWorkoutModal = () => setLiveWorkoutModalOpen(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <>
    {!isMobile ?<div className="outerCrousel" style={{ backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporatePage/CorporateCarouselAsset.svg)`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', paddingTop: '7.5vw' }}>
        <div className="upperContent" style={{ textAlign: 'center', marginLeft: '10vw', marginRight: '10vw', borderBottom: '1px solid #CC203B', marginBottom: '5vw', paddingBottom: '5vw' }}>
          <h1 className='font-1' style={{ color: '#CC203B', fontSize: '3rem', lineHeight: '25%' }}>Team Building</h1>
          <h1 className='font-2' style={{ color: '#CC203B', fontSize: '3.25rem' }}>Wellness Challanges</h1>
          <p className='font-1' style={{ marginLeft: '5vw', marginRight: '5vw', paddingLeft: '10vw', paddingRight: '10vw', marginTop: '2vw', fontWeight: '400' }}>What's unique with our fun wellness challenges is also the fact that we don't just give diet plans and make employees do workouts-but, our challenges also instill accountability, leadership, team building & communication skills within employees.</p>
        </div>
        <div style={{ border: '2x solid red' }}></div>
        <h3 className="crouselHeading font-2" style={{ textAlign: 'center', marginBottom: '3vw', fontWeight: '500', color: '#CC203B' }}>Fun activities we do for corporates</h3>
        <div className="mainCrousel" style={{ paddingBottom: '7vw' }}>
          <Container style={{ height: '320px' }}>

            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              autoplay={
                {
                  delay: 1500,
                  disableOnInteraction: false
                }
              }
              navigation={true}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
              }}

              pagination={false}
              modules={[Navigation, Autoplay, EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide onClick={openLiveWorkoutModal} style={{ height: '325px', width: '400px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{
                    display: 'flex',
                    background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/asianwomen.jpg)`,
                    height: '325px',
                    width: '400px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}>
                  </div>
                  <div>
                    <h2 style={{
                      backgroundColor: '#023020',
                      color: 'white',
                      zIndex: 10,
                      position: 'absolute',
                      bottom: '0%',
                      marginBottom: 0,
                      marginTop: 0,
                      textAlign: 'center',
                      width: '400px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingTop: '15px',
                      paddingBottom: '15px'
                    }}>LIVE Workouts</h2>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide onClick={openNutritionModal} style={{ height: '325px', width: '400px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{
                    display: 'flex',
                    background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/foodbowl.jpg)`,
                    width: '400px',
                    height: '325px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}>
                  </div>
                  <div>
                    <h3 style={{
                      backgroundColor: '#023020',
                      color: 'white',
                      zIndex: 10,
                      position: 'absolute',
                      bottom: '0%',
                      marginBottom: 0,
                      marginTop: 0,
                      textAlign: 'center',
                      width: '400px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingTop: '15px',
                      paddingBottom: '15px'
                    }}>Personlized Nutrition Plan</h3>
                  </div>
                </div>
              </SwiperSlide>
              
              <SwiperSlide onClick={openPersonalTrainingModal}  style={{ height: '325px', width: '400px' }}>
          <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/personalTraining.jpg)`,
                  height:'350px',
                  width:'400px',
                  backgroundSize: 'contain', 
                  backgroundRepeat:'no-repeat',
                  backgroundPosition: '0px 0px'
                  }}>
                </div>
                <div>
                  <h2 style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>Personal Training</h2>
                </div>
              </div>
          </SwiperSlide>
          <SwiperSlide onClick={openMetabolicHealthModal} style={{ height: '325px', width: '400px' }}>
          <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/microscopeview.jpg)`,
                  height:'325px',
                  width:'400px',
                  backgroundSize: 'contain', 
                  backgroundPosition: '0px 0px',
                  backgroundRepeat:'no-repeat'
                  }}>
                </div>
                <div>
                  <h2 style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>Metabolic Health Report</h2>
                </div>
              </div>
          </SwiperSlide>
            </Swiper>

          </Container>
      <PersonalizedNutritionFormModal isOpen={isNutritionModalOpen} onClose={closeNutritionModal} />
      <PersonalTrainingFormModal isOpen={isPersonalTrainingModalOpen} onClose={closePersonalTrainingModal} />
      <MetabolicHealthFormModal isOpen={metabolicHealthModalOpen} onClose={closeMetabolicHealthModal} />
      <LiveWorkoutFormModal isOpen={liveWorkoutFormModalOpen} onClose={closeLiveWorkoutModal}/>
        </div>
      </div>:(
        <div style={{width:'100%',backgroundColor:'#F3F3F4',paddingTop:'15%'}}>
          <div className='mid'>
            <h1 className='m-crousel-heading2 font-1'>Team Building</h1>
            <h1 className='m-crousel-heading1 font-2'>Wellness Challenges</h1>
            <p className='font-1'>What’s unique with our fun wellness challenges is also the fact that we don’t just give diet plans and make employees do workouts - but, our challenges also instill accountability, leadership, team building & communication skills within employees.
            </p>
          </div>
          <div className='mid-head'>
          <p className='font-1'>Fun activities we do for corporates
          </p>
          <Container style={{ height: '320px' }}>

            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              autoplay={
                {
                  delay: 1500,
                  disableOnInteraction: false
                }
              }
              navigation={false}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
              }}

              pagination={false}
              modules={[Navigation, Autoplay, EffectCoverflow, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide onClick={openLiveWorkoutModal} style={{ height: '325px', width: '400px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{
                    display: 'flex',
                    background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/asianwomen.jpg)`,
                    height: '325px',
                    width: '400px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}>
                  </div>
                  <div>
                    <h2 style={{
                      backgroundColor: '#023020',
                      color: 'white',
                      zIndex: 10,
                      position: 'absolute',
                      bottom: '0%',
                      marginBottom: 0,
                      marginTop: 0,
                      textAlign: 'center',
                      width: '400px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingTop: '15px',
                      paddingBottom: '15px'
                    }}>LIVE Workouts</h2>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide onClick={openNutritionModal} style={{ height: '325px', width: '400px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{
                    display: 'flex',
                    background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/foodbowl.jpg)`,
                    width: '400px',
                    height: '325px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}>
                  </div>
                  <div>
                    <h3 style={{
                      backgroundColor: '#023020',
                      color: 'white',
                      zIndex: 10,
                      position: 'absolute',
                      bottom: '0%',
                      marginBottom: 0,
                      marginTop: 0,
                      textAlign: 'center',
                      width: '400px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingTop: '15px',
                      paddingBottom: '15px'
                    }}>Personlized Nutrition Plan</h3>
                  </div>
                </div>
              </SwiperSlide>
              
              <SwiperSlide onClick={openPersonalTrainingModal}  style={{ height: '325px', width: '400px' }}>
          <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/personalTraining.jpg)`,
                  height:'350px',
                  width:'400px',
                  backgroundSize: 'contain', 
                  backgroundRepeat:'no-repeat',
                  backgroundPosition: '0px 0px'
                  }}>
                </div>
                <div>
                  <h2 style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>Personal Training</h2>
                </div>
              </div>
          </SwiperSlide>
          <SwiperSlide onClick={openMetabolicHealthModal} style={{ height: '325px', width: '400px' }}>
          <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/microscopeview.jpg)`,
                  height:'325px',
                  width:'400px',
                  backgroundSize: 'contain', 
                  backgroundPosition: '0px 0px',
                  backgroundRepeat:'no-repeat'
                  }}>
                </div>
                <div>
                  <h2 style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>Metabolic Health Report</h2>
                </div>
              </div>
          </SwiperSlide>
            </Swiper>

          </Container>
      <PersonalizedNutritionFormModal isOpen={isNutritionModalOpen} onClose={closeNutritionModal} />
      <PersonalTrainingFormModal isOpen={isPersonalTrainingModalOpen} onClose={closePersonalTrainingModal} />
      <MetabolicHealthFormModal isOpen={metabolicHealthModalOpen} onClose={closeMetabolicHealthModal} />
      <LiveWorkoutFormModal isOpen={liveWorkoutFormModalOpen} onClose={closeLiveWorkoutModal}/>
        </div>
        </div>
      ) }
      
    </>
  )
}

export default CorporateCrousel