import React from 'react'
import { useState, useEffect } from 'react';
import bg from '../../../images/AboutUs/Web/AboutIntro.svg'
import './AboutInto.css'
import aboutMobilebg from '../../../images/AboutUs/Mobile/AboutIntroMobile.svg'

const AboutIntro = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };
  
      window.addEventListener("resize", handleResize);
      handleResize();
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
  
    return (
      <>
      {!isMobile ? <div>
        
        <div className="ai-container" style={{backgroundImage:`url(${bg})`}}>
        <div>
            <p className="ai-heading-b font-1">We’re a Health Tech company on a mission to</p>
            <p className="ai-heading-a font-2"> “Make Healthcare Reach Every Family <br />At An Affordable & Accessible Cost”.</p>
        </div>
        </div>
      </div>:(
       <div
       style={{
         paddingTop:'35%',
         paddingBottom:'30%',
         height:'80vh',
         backgroundImage: `url(${aboutMobilebg})`,
         backgroundRepeat: "no-repeat",
         backgroundSize: "cover",
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "space-evenly",
       }}
     >
       <div >
         <h1 className="font-1 m-ab-banner-heading2" >We’re a Health Tech </h1>
         <h1 className="font-1 m-ab-banner-heading2" style={{marginBottom:'10%'}}>company on a mission to</h1>

         <h1 className="font-2 m-ab-banner-heading1">“Make Healthcare</h1>
         <h1 className="font-2 m-ab-banner-heading1">Reach Every Family</h1>
         <h1 className="font-2 m-ab-banner-heading1">At An Affordable &</h1>
         <h1 className="font-2 m-ab-banner-heading1">
         Accessible Cost”.
         </h1>
       </div>
     </div>
      )}
      </>
    );
}

export default AboutIntro