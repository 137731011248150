import React, { useState } from "react";
import "./ThankModal.css";
import { useNavigate } from "react-router";
import axios from "axios";

const ThankModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    mobileNumber: "",
  });

//   const isValidEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   const isValidMobileNumber = (mobileNumber) => {
//     const mobileRegex = /^[6-9]\d{9}$/;
//     return mobileRegex.test(mobileNumber);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!formData.email || !formData.mobileNumber) {
//       alert("All fields are required.");
//       return;
//     }

//     if (!isValidEmail(formData.email)) {
//       alert("Please enter a valid email address.");
//       return;
//     }

//     if (!isValidMobileNumber(formData.mobileNumber)) {
//       alert("Please enter a valid mobile number.");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         "https://dev-api.fitnastichealth.in/api/v1/info/formInfo",
//         formData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         console.log("Form Data:", response.data);
//         setFormData({
//           email: "",
//           mobileNumber: "",
//         });
//         onClose();
//         navigate("/home", { replace: true });
//       }
//     } catch (error) {
//       console.error("There was a problem with the axios operation:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const navigate = useNavigate();

//   const closeModal = () => {
//     navigate("/home", { replace: true });
//   };

  return (
    <>
      <div
        className="modal"
        // onRequestClose={onClose}
        contentLabel="Example Modal"
      >
        <div
          style={{
            width: "80%",
            height: "94vw",
            background: "white",
            borderRadius: "35px",
            boxShadow: "0px 0px 8px 0px #00000040",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            animationName: "fadeIn",
            animationDuration: "0.5s"
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "25%",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                // borderBottomWidth: "1.5px",
                // borderColor: "#CC203B",
              }}
            >
              <p
                className="font-2"
                style={{
                  color: "#CC203B",
                  fontSize: "3rem",
                  marginBottom: "9px",
                  fontWeight: "400",
                }}
              >
                Thank You!
              </p>
            </div>
            <div
              style={{
                width: "64%",
                height: "57%",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                flexDirection: "column",
                padding: "0px 10px",
              }}
            >
              <p
                className="font-1"
                style={{
                  color:'#CC203B',  
                  fontSize: "1.3rem",
                  marginTop: "-1rem",
                  marginBottom: "0rem",
                  textAlign:'center'
                }}
              >
                Please check your whatsapp & email for next steps
              </p>
              {/* <span className="font-1"
                style={{
                  fontSize: "1.1vw",
                }}
              >
                Health Report
              </span> */}
              <div
                style={{
                  width: "100%",
                  marginTop: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                <button
                  onClick={onClose}
                  className="font-1 pm2-get-start"
                  style={{
                    border: "1.75px",
                    borderColor: "#CC203B",
                    borderStyle: "solid",
                    borderRadius: "50px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    marginTop: "10px",
                    marginBottom: "30px",
                    color: "#CC203B",
                    fontWeight: "400",
                    fontSize: "16px",
                    width: "10rem",
                  }}
                >
                  OKAY
                </button>
              </div>
            </div>
          </div>
          {/* <button onClick={onClose}>Close</button> */}
        </div>
      </div>
    </>
  );
};

export default ThankModal;
