import React, { useState } from "react";
import "./PrizeModal2.jsx";
import logo from "../../../../images/quizModalLogo.svg";
import Lottie from "lottie-react";
import prizemodalconfetti from "../../../../lottiejsons/prizemodalconfetti.json";
import { useNavigate } from "react-router";
import axios from "axios";

const PrizeModal2 = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    mobileNumber: "",
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobileNumber) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobileNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.mobileNumber) {
      alert("All fields are required.");
      return;
    }

    if (!isValidEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!isValidMobileNumber(formData.mobileNumber)) {
      alert("Please enter a valid mobile number.");
      return;
    }

    try {
      const response = await axios.post(
        "https://prod-api.fitnastichealth.in/api/v1/info/formInfo",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Form Data:", response.data);
        setFormData({
          email: "",
          mobileNumber: "",
        });
        onClose();
     //   navigate("/home", { replace: true });
      }
    } catch (error) {
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const closeModal = () => {
    navigate("/home", { replace: true });
  };

  return (
    <>
      <div
        className="modal"
        onRequestClose={onClose}
        contentLabel="Example Modal"
      >
        <div
          style={{
            width: "90%",
            height: "130vw",
            background: "white",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            animationName: "fadeIn",
            animationDuration: "0.5s",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "25vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-8vh",
            }}
          >
            <img src={logo} alt="" style={{}} />
          </div>
          <div className="pm2-lottie-background">
            <Lottie
              animationData={prizemodalconfetti}
              style={{ height: "37vh", width: "15rem" }}
            />
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: "-3.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className="font-2"
              style={{
                color: "rgb(204, 32, 59)",
                fontSize: "2.8rem",
                lineHeight: "1",
                marginBottom:"2rem",
              }}
            >
              Congratulation!
            </p>
            <p
              className="font-1"
              style={{
                color: "rgb(204, 32, 59)",
                fontSize: "1.6rem",
                lineHeight: "1",
                width: "95%",
                marginBottom:"0.7rem",
              }}
            >
              You WIN 7-Days LIVE Workout With Our Coaches <br /> (Worth Rs. 499)
            </p>
          </div>
          <div className="" style={{width:'85%'}}>
            <form action="" className="">
              <div className="form-group" style={{marginBottom:"1rem"}}>
                <input
                  className="form-group-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  required
                />
              </div>
              <div className="form-group" style={{marginBottom:"0.5rem"}}>
                <div className="dFlex-pm2 customInput-pm2">
                  <div className="custom91">+91</div>
                  <input
                    className="form-group-inputNone"
                    type="text"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    placeholder="Enter mobile number"
                    required
                  />
                </div>
              </div>
            </form>
          </div>
          <button
            onClick={handleSubmit}
            className="font-1 pm2-get-start"
            style={{
              width:'10rem',
              border: "1.75px",
              borderColor: "#CC203B",
              borderStyle: "solid",
              borderRadius: "50px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "10px",
              marginBottom: "30px",
              color: "#CC203B",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            SUBMIT
          </button>
          {/* <button onClick={onClose}>Close</button> */}
        </div>
      </div>
    </>
  );
};

export default PrizeModal2;
