import React from "react";
import "./CorporateIntroBox.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function CorporateIntroBox() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const btnStyle = {
    border: "1.5px solid rgb(255, 255, 255)",
    borderRadius: "50px",
    paddingTop: "0.8rem",
    paddingBottom: "0.85rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "3vw",
    marginBottom: "3vw",
    color: "rgb(255, 255, 255)",
    fontSize: "1rem",
    fontWeight: "600",
  };
  return (
    <>
      {!isMobile ? (
        <div
          className="imageBg"
          style={{
            backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporatePage/CorporateIntoAsset.svg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            // marginTop:'7rem'
            height: "70vh",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="font-2"
              style={{
                fontSize: "3vw",
                color: "white",
                paddingLeft: "10vw",
                paddingRight: "10vw",
                marginRight: "3vw",
                marginLeft: "3vw",
                marginTop: "3vw",
                lineHeight: "1.25",
              }}
            >
              Get a 3X Return On Your Wellness Programs & Increase Your Employee
              Satisfaction Score By 74.3%
            </div>
            <br></br>
            <h4
              className="font-1"
              style={{
                color: "white",
                paddingLeft: "3vw",
                paddingRight: "3vw",
                fontWeight: "350",
                fontSize: "2vw",
              }}
            >
              With Our First Of Its Kind Smart AI Holistic Solutions
            </h4>
            <Link to="/freeHealthReport">
            <button style={btnStyle} className="font-1">
              FREE HEALTH REPORT
            </button>
            </Link>
          </div>
        </div>
      ) : (
        <div
          style={{
            paddingTop:'35%',
            paddingBottom:'30%',
            height:'80vh',
            backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateMobile/Corporate+Banner/mobBg4.svg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{marginBottom:'20%'}}>
            <h1 className="font-2 m-banner-heading1">Get a 3X Return On Your</h1>
            <h1 className="font-2 m-banner-heading1">Wellness Programs &</h1>
            <h1 className="font-2 m-banner-heading1">Increase Your Employee</h1>
            <h1 className="font-2 m-banner-heading1">
              Satisfaction Score By
            </h1>
            <h1 className="font-2 m-banner-heading1">74.3%</h1>
            <h1 className="font-1 m-banner-heading2 " style={{marginTop:'8%'}}>With Our First Of Its</h1>
            <h1 className="font-1 m-banner-heading2">Kind Smart AI Holistic</h1>
            <h1 className="font-1 m-banner-heading2">Solutions</h1>
          </div>
          <Link to="/freeHealthReport" style={{marginTop:'-15%'}}>
           <button
             style={{
               border: "1.5px",
               borderColor:"#FFFFFF",
               borderStyle: "solid",
               borderRadius: "50px",
               paddingTop: "0.7rem",
               paddingBottom: "0.5rem",
               paddingLeft: "1rem",
               paddingRight: "1rem",
               marginTop: "5%",
               color:'#FFFFFF',
               fontSize: "4.8vw",
               fontWeight: 400,
               textAlign:'center'
             }}
             className="font-1"
           >
            GET FREE HEALTH REPORT
           </button>
         </Link>
        </div>
      )}
    </>
  );
}

export default CorporateIntroBox;
