import React from "react";
import "./PriceModal.css";
import logo from '../../images/quizModalLogo.svg'
import Lottie from 'lottie-react'
import prizemodalconfetti from '../../lottiejsons/prizemodalconfetti.json'
import { useNavigate } from "react-router";

const DialogComponent = ({ onClose }) => {
  
  const navigate = useNavigate()

  /**
   * Go to home screen for on completing the quiz.
   */
  const endQuiz = () => {
    navigate("/freeHealthReportQuiz", {replace: true})
  }

  return (
    <>
      <div className='modal' onRequestClose={onClose} contentLabel="Example Modal">
      <div style={{
        width: '57%', height: '55vh', background: 'white', borderRadius: '30px', display: 'flex',
        justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column',animationName:'fadeIn',animationDuration:'1s'
      }}>
        <div style={{ width: '100%', height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-7.8vh' }}>
          <img src={logo} alt="" style={{ height: '160%' }} />

        </div>
        <div className="pm-lottie-background">
          <Lottie animationData={prizemodalconfetti} style={{ height: '15rem', width: '15rem' }} />
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '-2rem' }}>
          <p
            className="font-2"
            style={{
              color: "rgb(204, 32, 59)",
              fontSize: "2.4rem",
              lineHeight: "1",
            }}
          >
            Congratulations!
          </p>
          <p
            className="font-1"
            style={{
              color: "rgb(204, 32, 59)",
              fontSize: "1.5rem",
              lineHeight: "1",
            }}
          >
            Get Your Health Report For FREE (Worth Rs. 499)
          </p>
        </div>
        <button onClick={endQuiz} className='font-1 pm-get-start' style={{
          border: '1.75px',
          borderColor: '#CC203B',
          borderStyle: 'solid',
          borderRadius: '50px',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginTop: '10px',
          marginBottom:'30px',
          color: '#CC203B',
          fontWeight: '600',
          fontSize: '18px'
        }}>
          START NOW
        </button>
        {/* <button onClick={onClose}>Close</button> */}
      </div>
    </div>
    </>
  );
};

export default DialogComponent;
