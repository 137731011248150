import React, { useEffect, useState, useRef } from "react";
import "./Timeline.css";
import "../timelinecontainer/ProgressBox.css";
import {
  Asset2,
  Asset3,
  Asset4,
  Asset5,
  Asset6,
  Asset7,
} from "../../constants/Svgs";

const Timeline = () => {

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if(ref.current) {
            observer.unobserve(ref.current);
          }
        }
      },
      { threshold: 1 } // Adjust threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    // Cleanup function
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div ref={ref} className="timeline-container" style={{height:'60%' }}>
      <img
        alt="timeline-asset"
        className="timeline-bg"
        src="/assets/Asset1.svg"
      />
      {
        isVisible && 
        <div  className={`pb-fade-in ${isVisible ? 'pb-visible' : ''}`}>
          <div className="timeline-item-group-1">
            <div className={`timeline-item timeline-item-1`}>
              <Asset2 />
            </div>
            <div className={`timeline-item timeline-item-2 animate-delay-3`}>
              <Asset3 />
            </div>
            <div className={`timeline-item timeline-item-3 animate-delay-6`}>
              <Asset4 />
            </div>
            <div className={`timeline-item timeline-item-4 animate-delay-9`}>
              <Asset5 />
            </div>
          </div>
          <div className="timeline-item-group-2">
            <div className={`timeline-item animate-delay-12`}>
              <Asset6 />
            </div>
          </div>
          <div className="timeline-item-group-3">
            <div className={`timeline-item animate-delay-15`}>
              <Asset7 />
            </div>
          </div>
          <div className="timeline-item-group-4">
            <img
              alt="timeline-asset"
              className={`timeline-item animate-delay-18`}
              src="/assets/Asset8.svg"
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Timeline;
