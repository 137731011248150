import React from 'react'
import imageBg from '../../../images/TechPageImages/TechBannerImages/bannertech1.svg'
import imageBgMobile from '../../../images/TechPageImages/TechBannerImages/techBannerMobile.svg'
import './TechBanner.css'

import { Link } from 'react-router-dom'
import { useState,useEffect } from 'react'
const TechBanner = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    {!isMobile ?<div className="tb-container" style={{backgroundImage: `url(${imageBg})`}}>
        <div className="tb-content">
            <div className="tb-heading font-2">Over 1,53,384 Successfully Tested!</div>
            <div className="tb-subHeading font-1">Get Your Disease Risk Score, Metabolic Age, Lifestyle Contribution To Diseases & A Lot More With Our Smart Bio AI Health Reports</div>
            <div className="tb-desc font-2">(Clinically Validated - Over 93.2% Accuracy - Follow WHO Guidelines)</div>
            <div className="tb-btn">
            <Link to="/freeHealthReport">
            <button className='font-1' style={{
          border: '2px', 
          borderColor:'#FFFFFF', 
          borderStyle: 'solid', 
          borderRadius: '50px', 
          paddingTop:'10px', 
          paddingBottom:'12px', 
          paddingLeft:'20px', 
          paddingRight:'20px',
          marginTop: '4rem',
          color:'#FFFFFF',
          fontWeight: '600'  
          }}>
            GET FREE HEALTH REPORT            
        </button>
        </Link>
            </div>
        </div>
    </div>:
      <div
      style={{
        paddingTop:'35%',
        paddingBottom:'30%',
        height:'80vh',
        backgroundImage: `url(${imageBgMobile})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <div style={{marginBottom:'20%'}}>
            <h1 className="font-2 m-tech-banner-heading1">Over 1,53,384 </h1>
            <h1 className="font-2 m-tech-banner-heading1">Successfully Tested!</h1>
            
            <h1 className="font-1 m-tech-banner-heading2 " style={{marginTop:'5%'}}>Get Your Disease Risk Score, Metabolic</h1>
            <h1 className="font-1 m-tech-banner-heading2">Age, Lifestyle Contribution To Diseases</h1>
            <h1 className="font-1 m-tech-banner-heading2">& A Lot More With Our Smart Bio AI</h1>
            <h1 className="font-1 m-tech-banner-heading2">Health Reports</h1>
            <h1 className="font-2 m-tech-banner-heading3"style={{marginTop:'12%'}}>{"(Clinically Validated - Over 93.2% Accuracy -)"} </h1>
            <h1 className="font-2 m-tech-banner-heading3">{"(Follow WHO Guidelines)"} </h1>

          </div>
          <Link to="/freeHealthReport" style={{marginTop:'-15%'}}>
           <button
             style={{
               border: "1.5px",
               borderColor:"#FFFFFF",
               borderStyle: "solid",
               borderRadius: "50px",
               paddingTop: "0.7rem",
               paddingBottom: "0.5rem",
               paddingLeft: "1rem",
               paddingRight: "1rem",
               marginTop: "5%",
               color:'#FFFFFF',
               fontSize: "1rem",
               fontWeight: 400,
               textAlign:'center'
             }}
             className="font-1"
           >
            GET FREE HEALTH REPORT
           </button>
         </Link>
      
      </div>
    }
    </>
    
  )
}

export default TechBanner