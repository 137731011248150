import React from "react";
import "./FitToday.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const FitToday = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const containerStyle = {
    height: "auto",
    backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporatePage/fitTodayBg.jpg)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px -130px",
  };
  return (
    <>
      {!isMobile ? (
        <div style={containerStyle} id="fit-today-maindiv">
          <div className="fit-today-inner-container">
            <div style={{ display: "flex" }}>
              <h1
                className="font-1"
                style={{ color: "white", marginTop: "0.3rem" }}
              >
                Try Fitnastic
              </h1>
              <h1
                className="font-2"
                style={{ color: "white", marginLeft: "0.2rem" }}
              >
                {" "}
                TODAY!
              </h1>
            </div>
            <p
              className="font-1"
              style={{
                marginLeft: "8vw",
                marginRight: "8vw",
                textAlign: "center",
                color: "white",
                marginTop: "0.8vw",
                fontSize: "1.7vw",
                fontWeight: 300,
              }}
            >
              Join 1000+ individuals and companies who are actively using
              Fitnastic's wellness program to get healthy, fit and increase
              their performance in any walk of life.
            </p>
            <Link to="/contact">
            <button
              onClick={scrollToTop}
              style={{
                border: "1.5px",
                borderColor: "#FFFFFF",
                borderStyle: "solid",
                borderRadius: "50px",
                paddingTop: "0.75rem",
                paddingBottom: "0.5rem",
                paddingLeft: "1.6rem",
                paddingRight: "1.6rem",
                color: "#FFFFFF",
                fontSize: "1.2rem",
                marginTop: "2vw",
                fontWeight: 440,
              }}
              className="font-1"
            >
              GET IN TOUCH
            </button>
            </Link>
          </div>
        </div>
      ) : (
        <div
          style={{
            paddingTop:'18%',
            paddingBottom:'18%',
            width: "100%",
            backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporatePageMobile/fitToday.png)`,
            backgroundSize: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
             paddingTop:'10%',
             paddingBottom:'5%',
              background: "rgba(183, 0, 49, 0.65)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 className="font-1 m-fit-heading2">
              Try Fitnastic{" "}
              <span className="font-2 m-fit-heading1">TODAY!</span>
            </h1>
            <div
              className="font-1 midPhrase"
              style={{ width: "82%" ,display:"flex",flexDirection:'column',alignItems:'center'}}
            >
              <p style={{color:'white'}}>
              Join 1000+ individuals and companies who are actively using Fitnastic’s Wellness Program to get healthy, fit and increase their performance in any walk of life.
              </p>
              <Link to="/contact">
            <button
              onClick={scrollToTop}
              style={{
                border: "1.5px",
                borderColor: "#FFFFFF",
                borderStyle: "solid",
                borderRadius: "50px",
                paddingTop: "0.8rem",
                paddingBottom: "0.6rem",
                paddingLeft: "1.6rem",
                paddingRight: "1.6rem",
                color: "#FFFFFF",
                fontSize: "1.1rem",
                marginTop: "2vw",
                fontWeight: 440,
              }}
              className="font-1"
            >
              GET IN TOUCH
            </button>
            </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FitToday;
