import React from 'react'
import './TechTable.css'
import logo1 from '../../../images/ComparisonTable/BrandImages/logo1.svg'
import tickLogo from '../../../images/ComparisonTable/tick.svg'
import crossLogo from '../../../images/ComparisonTable/cross.svg'
import { useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom';





const TechTable = () => {
    const [isMobile, setIsMobile] = useState(false);
    
    const location = useLocation();
  
    const renderHeading = () => {
      if (location.pathname === '/ourTech') {
        return (
          <div className="m-tt-heading-italics font-2">Us vs Others</div>
        );
      } else {
        return (
          <>
            <div className="m-tt-heading font-1">Try Fitnastic Today & Witness</div>
            <div className="m-tt-heading-italics font-2">Awesome Results For Your Organization</div>
          </>
        );
      }
    };


    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 770);
      };
  
      window.addEventListener("resize", handleResize);
      handleResize(); // Call handler right away so state gets updated with initial window size
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  return (
    <>
    {!isMobile?
    <div className="tt-container">
        <div className="tt-heading font-1">Us Vs Others</div>
        <div className="tt-table">
            <table style={{ borderCollapse: 'collapse',border:'none' }}>
                <thead className='tt-thead'>
                    <tr>
                        <td className='tt-thead-td font-1'>Market Players</td>
                        <td className='tt-thead-td font-1'>One-stop Wellness Solutions</td>
                        <td className='tt-thead-td font-1'>Affordability</td>
                        <td className='tt-thead-td font-1'>Bio AI-Driven Smart Reports</td>
                        <td className='tt-thead-td font-1'>Metabolic Insights</td>
                        <td className='tt-thead-td font-1'>Disease Risk Scores</td>
                        <td className='tt-thead-td font-1'>Physiological Blood-Marker Analysis</td>
                    </tr>
                </thead>
                <tbody className='tt-tbody'>
                    <tr>
                        <td className='tt-tbody-td1'>
                        <img src={logo1} alt="Header Image" className='tt-headCompImg' />
                        </td>
                        <td className='tt-tbody-td1'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td1'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td1'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td1'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td1'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td1'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                    </tr>
                    <tr>
                    <td className='tt-tbody-td2 tt-tbody-td2-text font-2'>Others</td>
                    <td className='tt-tbody-td2'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td><td className='tt-tbody-td2'>
                        <img className='tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td2'>
                        <img className='tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td2'>
                        <img className='tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td2'>
                        <img className='tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='tt-tbody-td2'>
                        <img className='tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    :(
        <div className="m-tt-container">
            {/* <div className="m-tt-heading font-1">Try Fitnastic Today & Witness</div>
            <div className="m-tt-heading-italics font-2">Awesome Results For Your Organization</div> */}
            {renderHeading()}
            <div className="m-tt-table">
                <table className="m-tt-t">
                    <thead className="m-tt-thead">
                    <td className='m-tt-thead-td font-1'>Market Players</td>
                    <td className='m-tt-tbody-td1'>
                        <img src={logo1} alt="Header Image" className='m-tt-headCompImg' style={{maxWidth:'200%'}} />
                    </td>
                    <td className='m-tt-tbody-td2 m-tt-tbody-td2-text font-2'>Others</td>
                    </thead>
                    <tbody className="m-tt-tbody">
                        <tr>
                        <td className='m-tt-thead-td font-1'>One-stop Wellness Solutions</td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        </tr>
                        <tr>
                        <td className='m-tt-thead-td font-1'>Affordability</td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        </tr>
                        <tr>
                        <td className='m-tt-thead-td font-1'>Bio AI-Driven Smart Reports</td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "20px", minHeight: "20px" }} />
                        </td>
                        </tr>
                        <tr>
                        <td className='m-tt-thead-td font-1'>Metabolic Insights</td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "20px", minHeight: "20px" }} />
                        </td>
                        </tr>
                        <tr>
                        <td className='m-tt-thead-td font-1'>Disease Risk Scores</td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "20px", minHeight: "20px" }} />
                        </td>
                        </tr>
                        <tr>
                        <td className='m-tt-thead-td font-1'>Physiological Blood-Marker Analysis</td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={tickLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "25px", minHeight: "25px" }} />
                        </td>
                        <td className='m-tt-tbody-td2'>
                        <img className='m-tt-compImage' src={crossLogo} alt="Data Image" style={{ display: 'block', margin: 'auto', minWidth: "20px", minHeight: "20px" }} />
                        </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )}
    </>
    
  )
}

export default TechTable