import React from "react";
import bg from "../../../images/quizMobile/bg.svg";
import score from "../../../images/quizMobile/grp2.svg";
import AssetFitPerson from "../../../images/quiz/AssetFitPerson.svg";
import AssetMuscular from "../../../images/quiz/AssetMuscular.svg";
import skipArrow from "../../../images/skipArrow.svg";
import prevArrow from "../../../images/previousArrow.svg";
import DialogComponent from "./PrizeModal.jsx";
import PrizeModal2 from "./PrizeModal2/PrizeModal2.jsx";
import ThankModal from "./ThankModal/ThankModal.jsx";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Modal from "react-modal";

const questions = [
  {
    qId: 1,
    qText: "Who's Fitter?",
  },
  {
    qId: 2,
    qText: "Who's Healthier?",
  },
  {
    qId: 3,
    qText: "What Do You Prefer?",
  },
];

const MobileQuiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [optionSelected, setOptionSelected] = useState([null, null, null]);
  const [showModal, setShowModal] = useState(false);
  const [showModalMuscular, setShowModalMuscular] = useState(false);
  const [showThankModal, setShowThankModal] = useState(false);
  const nextQuestion = () => {
    if (optionSelected[currentQuestion] !== null) {
      if (currentQuestion === 2) {
        const option1Count = optionSelected.filter((opt) => opt === 1).length;
        const option2Count = optionSelected.filter((opt) => opt === 2).length;
        if (option1Count > 2 || option1Count == 2) {
          setShowModal(true);
        }
        if (option2Count > 2 || option2Count == 2) {
          setShowModalMuscular(true);
        }
      } else {
        setCurrentQuestion(currentQuestion + 1);
      }
    }
  };

  const navigate = useNavigate();
  const closeThankModal=()=>{
    navigate("/home", { replace: true });
  }

  const skipQuiz = () => {
    navigate("/home", { replace: true });
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const optionSelect = (opId) => {
    const updatedOptions = [...optionSelected];
    updatedOptions[currentQuestion] =
      updatedOptions[currentQuestion] === opId ? null : opId;
    setOptionSelected(updatedOptions);
  };

  useEffect(() => {
    const option1Count = optionSelected.filter((opt) => opt === 1).length;
    const option2Count = optionSelected.filter((opt) => opt === 2).length;
    if (option1Count > 2) {
      setShowModal(true);
    } else if (option2Count > 2) {
      setShowModalMuscular(true);
    } else {
      nextQuestion();
    }
  }, [optionSelected]);

  const closeModal = () => {
    setShowModal(false);
    setShowModalMuscular(false);
    setShowThankModal(true)
  };

  useEffect(() => {
    setTimeout(() => {
      nextQuestion();
    }, 500);
  }, [optionSelected]);

  return (
    <>
      {currentQuestion == 0 && (
        <div
          style={{
            backgroundImage: `url(${bg})`,
            height: "100vh",
            width: "100vw",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              height: "24%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              width: "100%",
              animationName: "fadeIn",
              animationDuration: "1s",
            }}
          >
            <p
              className="font-1"
              style={{ color: "white", fontSize: "7vw", fontWeight: "400" }}
            >
              {currentQuestion + 1}. {questions[currentQuestion].qText}
            </p>
          </div>
          <div style={{ height: "66%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-evenly",
                width: "100%",
                height: "65%",
                paddingTop: "10%",
              }}
            >
              <div
                onClick={() => optionSelect(1)}
                className={
                  optionSelected[currentQuestion] === 1 ? "eqc-option-22" : ""
                }
                style={{
                  height: "95%",
                  width: "45%",
                  borderColor: "white",
                  borderWidth: "2px",
                  borderRadius: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={AssetFitPerson} alt="" style={{ height: "85%" }} />
              </div>
              <div
                onClick={() => optionSelect(2)}
                className={
                  optionSelected[currentQuestion] === 2 ? "eqc-option-22" : ""
                }
                style={{
                  height: "95%",
                  width: "45%",
                  borderColor: "white",
                  borderWidth: "2px",
                  borderRadius: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={AssetMuscular} alt="" style={{ height: "85%" }} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                visibility: "hidden",
                animationName: "fadeIn",
                animationDuration: "1s",
              }}
            >
              <img src={score} alt="" style={{ width: "75%" }} />
            </div>
          </div>
          <div
            style={{
              height: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <img
              onClick={prevQuestion}
              src={prevArrow}
              alt=""
              style={{ width: "11%", marginLeft: "3%", visibility: "hidden" }}
            />
            <div
              onClick={skipQuiz}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "end",
                marginRight: "3%",
              }}
            >
              <p
                className="font-1"
                style={{
                  color: "white",
                  fontSize: "5.5vw",
                  fontStyle: "italic",
                  marginBottom: "0rem",
                  fontWeight: "200",
                  marginRight: "2%",
                }}
              >
                Skip
              </p>
              <img src={skipArrow} alt="" style={{ width: "12%" }} />
            </div>
          </div>
        </div>
      )}
      {currentQuestion >= 1 && (
        <div
          style={{
            backgroundImage: `url(${bg})`,
            height: "100vh",
            width: "100vw",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              height: "24%",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              width: "100%",
              animationName: "fadeIn",
              animationDuration: "1s",
            }}
          >
            <p
              className="font-1"
              style={{ color: "white", fontSize: "7vw", fontWeight: "400" }}
            >
              {currentQuestion + 1}. {questions[currentQuestion].qText}
            </p>
          </div>
          <div style={{ height: "66%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-evenly",
                width: "100%",
                height: "65%",
                paddingTop: "10%",
              }}
            >
              <div
                onClick={() => optionSelect(1)}
                className={
                  optionSelected[currentQuestion] === 1 ? "eqc-option-22" : ""
                }
                style={{
                  height: "95%",
                  width: "45%",
                  borderColor: "white",
                  borderWidth: "2px",
                  borderRadius: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={AssetFitPerson} alt="" style={{ height: "85%" }} />
              </div>
              <div
                onClick={() => optionSelect(2)}
                className={
                  optionSelected[currentQuestion] === 2 ? "eqc-option-22" : ""
                }
                style={{
                  height: "95%",
                  width: "45%",
                  borderColor: "white",
                  borderWidth: "2px",
                  borderRadius: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={AssetMuscular} alt="" style={{ height: "85%" }} />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                animationName: "fadeIn",
                animationDuration: "1s",
              }}
            >
              <img src={score} alt="" style={{ width: "75%" }} />
            </div>
          </div>
          <div
            style={{
              height: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <img
              onClick={prevQuestion}
              src={prevArrow}
              alt=""
              style={{ width: "11%", marginLeft: "3%" }}
            />
            <div
              onClick={skipQuiz}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "end",
                marginRight: "3%",
              }}
            >
              <p
                className="font-1"
                style={{
                  color: "white",
                  fontSize: "5.5vw",
                  fontStyle: "italic",
                  marginBottom: "0rem",
                  fontWeight: "200",
                  marginRight: "2%",
                }}
              >
                Skip
              </p>
              <img src={skipArrow} alt="" style={{ width: "12%" }} />
            </div>
          </div>
        </div>
      )}
      <Modal isOpen={showModal} onRequestClose={closeModal}>
        <DialogComponent onClose={closeModal} />
      </Modal>
      <Modal
        isOpen={showModalMuscular}
        // onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
         <PrizeModal2 onClose={closeModal} />
      </Modal>
      <Modal isOpen={showThankModal} >
        <ThankModal onClose={closeThankModal}/>
      </Modal>
    </>
  );
};
export default MobileQuiz;
