import React, { useState } from 'react';
import '../../App.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect } from "react";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import 'swiper/css/bundle';
import nayanImg from '../../images/Testimonial/nayan.svg'
import nancyImg from '../../images/Testimonial/nancyB.jpg'
import sahilImg from '../../images/Testimonial/sahilJ.jpg'

import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import '../Testimonial/Testimonial.css'

const TestimonialTest = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 750);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile? (<div className='imageBg1'  
      style={{
        backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/testimonialBG.png)`}}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          autoplay={
            {
              delay: 2500,
              disableOnInteraction: false
            }
          }
          pagination={false}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
          style={
            {
              height: "auto",
              
            }
          }
        >
          <SwiperSlide className='swiperSlide'>
            <div className='content flex justify-between items-center smm:gap-2 sm:gap-4 md:gap-6 w-3/5 smm:w-full w-100 px-10 py-10 siwperSlideDiv' style={{alignItems:'start'}}>
              <div className='swiperChild'style={{width:'270px'}}>
              <img className='customer-img h-20 w-20 mdm:h-28 mdm:w-28 mt-2 profileImg' alt='avatar' src={nayanImg} />
              </div>
              <div className='d-flex flex-column justify-content-start tt-text-center'>
                <div style={{fontSize: "30px"}} className='name text-xl font-semibold mt-2 text-light ' >

                   <p style={{margin:0, padding:0}}><span style={{fontWeight: 650}}> NAYAN</span> <span style={{fontWeight: 300}} > RATANDHARAYA </span></p>
                   <br/>
                </div>
                <div style={{marginTop: "20px"}} className='customer-review mt-0 text-light '>

                    <p className='pText' style={{marginTop: "-5px"}}>In just 45 days, Fitnastic's wellness program yielded incredible outcomes. I shed 7 kg and witnessed remarkable health improvements.</p>
                    
                    <p className='pText'>Triglycerides dropped significantly from 257.3 to 136, maintaining stable HDL. Glucose levels notably improved, with averages dropping from 154.2 to 128.37, fasting glucose from 138.7 to 122.</p>
                    
                    <p className='pText'>Notably, my Vitamin B12 levels doubled. Fitnastic's tailored approach has truly revolutionized my health journey.</p>

                  </div>
              </div>

            </div>
          </SwiperSlide>
          <SwiperSlide className='swiperSlide'>
            <div className='content flex justify-between items-center smm:gap-2 sm:gap-4 md:gap-6 w-3/5 smm:w-full w-100 px-10 py-10 siwperSlideDiv' style={{alignItems:'start'}}>
              <div className='swiperChild'>
              <img className='customer-img h-20 w-20 mdm:h-28 mdm:w-28 mt-2 profileImg' alt='avatar' src={nancyImg} />
              </div>
              <div className='d-flex flex-column justify-content-start tt-text-center' style={{marginLeft:'28px'}} >
                <div style={{fontSize: "30px"}} className='name text-xl font-semibold mt-2 text-light ' >

                   <p style={{margin:0, padding:0}}><span style={{fontWeight: 650}}> NANCY</span> <span style={{fontWeight: 300}} > B </span></p>
                   <br/>
                </div>
                <div  className='customer-review mt-0 text-light ' >

                    <p className='pText' style={{marginTop: "-5px"}}>I led a sedentary life, and the Covid-era had a more adverse effect. Soon I was diagnosed with Calcium and Vitamin D deficiency, Grade 1 Fatty Liver, and borderline Cholesterol. Through a work colleague, I learned about Fitnastic's Smart Health and Wellness services.</p>
                    
                    <p className='pText'>Two years into working with Fitnastic, my health concerns are back to normal, and i've become more disciplined with my health.</p>

                  </div>
              </div>
               </div>
          </SwiperSlide>
          <SwiperSlide className='swiperSlide'>
            <div className='content flex justify-between items-center smm:gap-2 sm:gap-4 md:gap-6 w-3/5 smm:w-full w-100 px-10 py-10 siwperSlideDiv'style={{alignItems:'start'}}>
            <div className='swiperChild' style={{width:'210px'}}>
              <img className='customer-img h-20 w-20 mdm:h-28 mdm:w-28 mt-2 profileImg' alt='avatar' src={sahilImg} />
              </div>
              <div className='d-flex flex-column justify-content-start tt-text-center'>
                <div style={{fontSize: "30px"}} className='name text-xl font-semibold mt-2 text-light ' >

                   <p style={{margin:0, padding:0}}><span style={{fontWeight: 650}}> DR.</span> <span style={{fontWeight: 300}} > SAHIL J </span></p>
                   <br/>
                </div>
                <div style={{marginTop: "20px"}} className='customer-review mt-0 text-light '>

                    <p className='pText' style={{marginTop: "-5px"}}>I have always struggled to keep myself fit because work keeps me very busy. However, Fitnastic has consistently kept me motivated through it all.</p>
                    
                    <p className='pText'>The well structured, energetic sessions make me feel healthy and motivated.</p>
                    
                    <p className='pText'>Fitnastic's team of experts have always been there to guide me, whether it's been about exercise or my diet.</p>

                  </div>
              </div>

            </div>
          </SwiperSlide>
        </Swiper>
      </div>):
      <div className='imageBg1'  
      style={{
        backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/Testimonial/testBgFinal.png)`,backgroundSize:'cover',height:'auto',paddingBottom:'5%'}}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={false}
          autoplay={
            {
              delay: 2500,
              disableOnInteraction: false
            }
          }
          pagination={{
            clickable: true,
          }}
          navigation={false}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
          style={
            {
              height: "auto",
              
            }
          }
        >
          <SwiperSlide className='swiperSlide'>
            <div className='content flex justify-between items-center smm:gap-2 sm:gap-4 md:gap-6 w-3/5 smm:w-full w-100 px-10 py-10 siwperSlideDiv'>
              <div className='swiperChild'>
              <img className='customer-img h-20 w-20 mdm:h-28 mdm:w-28 mt-2' alt='avatar' src={nayanImg}  />
              <div style={{fontSize: "4vw",padding:'3%'}} className='name text-xl font-semibold mt-2 text-light ' >

                   <p style={{margin:0, padding:0}}><span style={{fontWeight: 600}}> NAYAN</span></p> 
                   <p style={{margin:0, padding:0}}><span style={{fontWeight: 600}}>  RATANDHARAYA </span></p>
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start tt-text-center'>
                
                <div style={{marginTop: "20px"}} className='customer-review mt-0 text-light '>

                    <p className='pText font-1' style={{marginTop: "-5px",textAlign:'center'}}>In just 45 days, Fitnastic's wellness program yielded incredible outcomes. I shed 7 kg and witnessed remarkable health improvements.</p>
                    
                    <p className='pText font-1'>Triglycerides dropped significantly from 257.3 to 136, maintaining stable HDL. Glucose levels notably improved, with averages dropping from 154.2 to 128.37, fasting glucose from 138.7 to 122.</p>
                    
                    <p className='pText font-1'>Notably, my Vitamin B12 levels doubled. Fitnastic's tailored approach has truly revolutionized my health journey.</p>

                  </div>
              </div>

            </div>
          </SwiperSlide>
          <SwiperSlide className='swiperSlide'>
            <div className='content flex justify-between items-center smm:gap-2 sm:gap-4 md:gap-6 w-3/5 smm:w-full w-100 px-10 py-10 siwperSlideDiv'>
              <div className='swiperChild'>
              <img className='customer-img h-20 w-20 mdm:h-28 mdm:w-28 mt-2' alt='avatar' src={nancyImg} />
              <div style={{fontSize: "4vw",padding:'3%'}} className='name text-xl font-semibold mt-2 text-light ' >

                   <p style={{margin:0, padding:0,fontWeight:'600'}}>NANCY B</p> 
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start tt-text-center'>
                
                <div style={{marginTop: "20px"}} className='customer-review mt-0 text-light '>

                    <p className='pText font-1' style={{marginTop: "-5px",textAlign:'center'}}>I led a sedentary life, and the Covid-era had a more adverse effect. Soon I was diagnosed with Calcium and Vitamin D deficiency, Grade 1 Fatty Liver, and borderline Cholesterol. Through a work colleague, I learned about Fitnastic's Smart Health and Wellness services.</p>
                    
                    <p className='pText font-1'>Two years into working with Fitnastic, my health concerns are back to normal, and i've become more disciplined with my health.</p>

                  </div>
              </div>

            </div>
          </SwiperSlide>
          <SwiperSlide className='swiperSlide'>
            <div className='content flex justify-between items-center smm:gap-2 sm:gap-4 md:gap-6 w-3/5 smm:w-full w-100 px-10 py-10 siwperSlideDiv'>
              <div className='swiperChild'>
              <img className='customer-img h-20 w-20 mdm:h-28 mdm:w-28 mt-2' alt='avatar' src={sahilImg}  />
              <div style={{fontSize: "4vw",padding:'3%'}} className='name text-xl font-semibold mt-2 text-light ' >

                   <p style={{margin:0, padding:0,fontWeight:'600'}}>DR.SAHIL J</p> 
                </div>
              </div>
              <div className='d-flex flex-column justify-content-start tt-text-center'>
                
                <div style={{marginTop: "20px"}} className='customer-review mt-0 text-light '>

                    <p className='pText font-1' style={{marginTop: "-5px",textAlign:'center'}}>I have always struggled to keep myself fit because work keeps me very busy. However, Fitnastic has consistently kept me motivated through it all.</p>
                    
                    <p className='pText font-1'>The well structured, energetic sessions make me feel healthy and motivated.</p>
                    <p className='pText font-1'>Fitnastic's team of experts have always been there to guide me, whether it's been about exercise or my diet.</p>

                  </div>
              </div>

            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      }
    </>
  )
}

export default TestimonialTest