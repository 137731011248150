import React from "react";
import "./HealthReportComponent.css";
import bg from "../../images/Healthbg.png";
import skipArrow from "../../images/skipArrowReport.svg";
import nextArrow from "../../images/nextArrowReport.svg";
import prevArrow from "../../images/previousArrowReport.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import EmailModal from "./EmailModal/EmailModal";
import ThankModal from "./ThankModal/ThankModal";
import Modal from "react-modal";
import Select from "react-select";
import WaistModal from "./WaistModal/WaistModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import HealthReportMobile from "../HealthReportPageMobile/HealthReportMobile";

const questions = [
  {
    qId: 1,
    qText: "Enter your full name.",
  },
  {
    qId: 2,
    qText: "Select your gender.",
  },
  {
    qId: 3,
    qText: "Select your date of birth.",
  },
  {
    qId: 4,
    qText: "What's your height?",
  },
  {
    qId: 5,
    qText: "What's your current weight?",
  },
  {
    qId: 6,
    qText: "What's your current waist measurement?",
  },
  {
    qId: 7,
    qText: "What is your current hip measurement?",
  },
  {
    qId: 8,
    qText:
      "Do you know your current body fat percentage? If so, please provide.",
  },
  {
    qId: 9,
    qText: "How much time do you spend actively walking each day?",
  },
  {
    qId: 10,
    qText:
      "How long do you typically sit continuously each day due to work or lifestyle?",
  },
  {
    qId: 11,
    qText:
      "On a typical week, how much time do you dedicate to leisure activities, workouts or sports?",
  },
  {
    qId: 12,
    qText:
      "On an average week, how would you rate the intensity of your activities or workouts?",
  },
  {
    qId: 13,
    qText: "How often do you fall sick in a year?",
  },
  {
    qId: 14,
    qText: "Are you diagnosed with the following diseases?",
  },
  {
    qId: 15,
    qText: "What are your primary health and wellness priorities?",
  },
  {
    qId: 16,
    qText:
      "Which aspect of your lifestyle changes would you like to prioritize?",
  },
];

const HealthReportComponent = ({type}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isDateSelected, setIsDateSelected] = useState(false);

  const [unit, setUnit] = useState({ value: "cm", label: "cm" });

  //const [waistUnit, setWaistUnit] = useState({ value: "cm", label: "cm" });

 // const [hipUnit, setHipUnit] = useState({ value: "cm", label: "cm" });

  const [time, setTime] = useState("");
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showThankModal, setShowThankModal] = useState(false);
  const [showWaistModal, setShowWaistModal] = useState(false);
  const [timeunit, setTimeUnit] = useState({
    value: "minutes daily",
    label: "minutes daily",
  });
  const [answers, setAnswers] = useState({
    name: "",
    surname: "",
    gender: "",
    dob: "",
    height: { cm: "", ft: "", in: "" },
    heightUnit: "cm",
    weight: "",
    weightUnit: "kg",
    waist: "",
    waistUnit: "cm",
    hip: "",
    hipUnit: "cm",
    bodyFat: "",
    walkingTime: "",
    walkingTimeUnit: "",
    sitTime: "",
    leisureTime: "",
    activityIntensity: "",
    sicknessFrequency: "",
    diseases: [],
    priorities: [],
    lifestylePriorities: "",
  });

  const timeOptions = [
    { value: "minutes daily", label: "minutes daily" },
    { value: "hours daily", label: "hours daily" },
  ];

  const waistOptions = [
    { value: "cm", label: "cm" },
    { value: "in", label: "in" },
  ];

  const hipOptions = [
    { value: "cm", label: "cm" },
    { value: "in", label: "in" },
  ];

  const heightOptions = [
    { value: "cm", label: "cm" },
    { value: "ft/in", label: "ft/in" },
  ];

  const handleTimeChange = (e) => {
    setTime(e.target.value);
    answers.walkingTime = e.target.value;
  };

  const handleTimeUnitChange = (selectedOption) => {
    setTimeUnit(selectedOption);
    answers.walkingTimeUnit = selectedOption;
  };

  const handleWaistUnitChange = (selectedOption) => {
    if (selectedOption) {
      const newValue = selectedOption.value;
      setAnswers(prevState => ({
        ...prevState,
        waistUnit: newValue
      }));
    }
  };
  const handleHipUnitChange = (selectedOption) => {
    if (selectedOption) {
      const newValue = selectedOption.value;
      setAnswers(prevState => ({
        ...prevState,
        hipUnit: newValue
      }));
    }
  };
  const handleHeightUnitChange = (selectedOption) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      heightUnit: selectedOption.value,
      height: { cm: "", ft: "", in: "" },
    }));
  };

  // const handleHeightUnitChange = (selectedOption) => {
  //   setUnit(selectedOption);
  //   answers.heightUnit=selectedOption;
  // };

  const navigate = useNavigate();
  const skipToNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#cc203b', // Adjust as needed
    }),
    // Add more styles as needed, like option, singleValue, etc.
  };

  const hoverCheck=()=>{
    if (currentQuestion < questions.length) {
      if (
        currentQuestion === 0 &&
        answers.name !== "" &&
        answers.surname !== ""
      )
        return false;
      if (currentQuestion === 1 && answers.gender !== "")
        return false;
      if (currentQuestion === 2 && answers.dob !== "")
        return false;
      if (
        (currentQuestion === 3 && answers.height.cm !== "") ||
        (answers.height.ft !== "" && answers.height.in !== "")
      )
        return false;
      if (currentQuestion === 4 && answers.weight !== "")
        return false;
      if (currentQuestion === 5 && answers.waist !== "")
        return false;
      if (currentQuestion === 6 && answers.hip !== "")
        return false;
      if (currentQuestion === 7 && answers.bodyFat !== "")
        return false;
      if (currentQuestion === 8 && answers.walkingTime !== "")
        return false;
      if (currentQuestion === 9 && answers.sitTime !== "")
        return false;
      if (currentQuestion === 10 && answers.leisureTime !== "")
        return false;
      if (currentQuestion === 11 && answers.activityIntensity !== "")
        return false;
      if (currentQuestion === 12 && answers.sicknessFrequency !== "")
        return false;
      if (currentQuestion === 13 && answers.diseases.length !== 0)
        return false;
      if (
        currentQuestion === 14 &&
        answers.priorities.length !== 0 &&
        answers.priorities.length <= 2
      )
        return false;
      if (currentQuestion === 15 && answers.lifestylePriorities !== "") {
        return false;
      }
      return true;
    }
  }

  const nextQuestion = () => {
    if (currentQuestion < questions.length) {
      if (
        currentQuestion === 0 &&
        answers.name !== "" &&
        answers.surname !== ""
      )
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 1 && answers.gender !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 2 && answers.dob !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (
        (currentQuestion === 3 && answers.height.cm !== "") ||
        (answers.height.ft !== "" && answers.height.in !== "")
      )
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 4 && answers.weight !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 5 && answers.waist !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 6 && answers.hip !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 7 && answers.bodyFat !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 8 && answers.walkingTime !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 9 && answers.sitTime !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 10 && answers.leisureTime !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 11 && answers.activityIntensity !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 12 && answers.sicknessFrequency !== "")
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 13 && answers.diseases.length !== 0)
        setCurrentQuestion(currentQuestion + 1);
      if (
        currentQuestion === 14 &&
        answers.priorities.length !== 0 &&
        answers.priorities.length <= 2
      )
        setCurrentQuestion(currentQuestion + 1);
      if (currentQuestion === 15 && answers.lifestylePriorities !== "") {
        setShowEmailModal(true);
      }
    }
  };

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const skipQuiz = () => {
    navigate("/home", { replace: true });
  };

  const closeWaistModal = () => {
    setShowWaistModal(false);
  };

  const openWaistModal = () => {
    setShowWaistModal(true);
  };
  const CancelEmailModal = () => {
    setShowEmailModal(false);
  };
  const SubmitEmailModal = () => {
    setShowEmailModal(false);
    setShowThankModal(true);
  };
  const closeThankModal = () => {
    navigate("/home", { replace: true });
  };

  const submitAnswers = () => {
    console.log("Submitted answers:", answers);
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundImage: `url(${bg})`,
            backgroundSize: "100% 100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ height: "87%", width: "100%" }}>
            {currentQuestion === 0 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      className="font-1 input-custom-HRC"
                      type="text"
                      id="fullNameInput"
                      placeholder="Name"
                      value={answers.name}
                      onChange={(e) =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          name: e.target.value,
                        }))
                      }
                    />
                    <input
                      className="font-1 input-custom-HRC"
                      type="text"
                      id="fullNameInput"
                      placeholder="Surname"
                      value={answers.surname}
                      onChange={(e) =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          surname: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 1 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <button
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          gender: "Male",
                        }))
                      }
                      className={`font-1 ${
                        answers.gender === "Male" ? "selectedButton" : ""
                      }`}
                      id="reportButton"
                    >
                      Male
                    </button>
                    <button
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          gender: "Female",
                        }))
                      }
                      className={`font-1 ${
                        answers.gender === "Female" ? "selectedButton" : ""
                      }`}
                      id="reportButton"
                    >
                      Female
                    </button>
                    <button
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          gender: "Other",
                        }))
                      }
                      className={`font-1 ${
                        answers.gender === "Other" ? "selectedButton" : ""
                      }`}
                      id="reportButton"
                    >
                      Other
                    </button>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 2 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      className="font-1 input-custom-HRC"
                      type="date"
                      id="fullNameInput"
                      name="dob"
                      placeholder="dd/mm/yy"
                      value={answers.dob}
                      onChange={(e) => {
                        const selectedDate = e.target.value;
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          dob: selectedDate,
                        }));
                        setIsDateSelected(!!selectedDate);
                      }}
                      style={{
                        width: "12rem",
                        paddingLeft: "1rem",
                        color: isDateSelected ? "black" : "#BFBFC5",
                      }}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 3 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1.3px solid #CC203B",
                        borderRadius: "7px",
                      }}
                    >
                      {answers.heightUnit === "cm" ? (
                        <input
                          type="number"
                          id="heightInputCm"
                          name="heightCm"
                          value={answers.height.cm}
                          onChange={(e) =>
                            setAnswers((prevAnswers) => ({
                              ...prevAnswers,
                              height: {
                                ...prevAnswers.height,
                                cm: e.target.value,
                              },
                            }))
                          }
                          style={{
                            width: "10rem",
                            padding: "5px",
                            borderRadius: "7px",
                            fontSize: "14px",
                            outline: "none",
                            textAlign: "start",
                          }}
                          className="font-1 input-custom-HRC"
                        />
                      ) : (
                        <>
                          <input
                            type="number"
                            id="heightInputFt"
                            name="heightFt"
                            value={answers.height.ft}
                            onChange={(e) =>
                              setAnswers((prevAnswers) => ({
                                ...prevAnswers,
                                height: {
                                  ...prevAnswers.height,
                                  ft: e.target.value,
                                },
                              }))
                            }
                            placeholder="feet"
                            style={{
                              width: "5rem",
                              padding: "5px",
                              borderRadius: "7px",
                              fontSize: "14px",
                              outline: "none",
                              textAlign: "start",
                            }}
                            className="font-1 input-custom-HRC"
                          />
                          <input
                            type="number"
                            id="heightInputIn"
                            name="heightIn"
                            value={answers.height.in}
                            onChange={(e) =>
                              setAnswers((prevAnswers) => ({
                                ...prevAnswers,
                                height: {
                                  ...prevAnswers.height,
                                  in: e.target.value,
                                },
                              }))
                            }
                            placeholder="inches"
                            style={{
                              width: "5rem",
                              padding: "5px",
                              borderRadius: "7px",
                              fontSize: "14px",
                              outline: "none",
                              textAlign: "start",
                            }}
                            className="font-1 input-custom-HRC"
                          />
                        </>
                      )}
                      <Select
                        classNames="font-1 select-custom"
                        options={heightOptions}
                        onChange={handleHeightUnitChange}
                        value={{
                          value: answers.heightUnit,
                          label: answers.heightUnit,
                        }}
                        className="select-custom input-custom-HRC"
                        isSearchable={false}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "0px solid #CC203B",
                            borderRadius: "7px",
                            fontSize: "14px",
                            width: "5rem",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#fff', // Adjust as needed
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            fontSize: "14px",
                          }),
                          option: (provided,state) => ({
                            ...provided,
                            fontSize: "14px",
                            color: state.isSelected ? 'white' : 'black',
                        backgroundColor:  state.isSelected ? '#CC203B' : 'white',
                            transition: '0s'
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 4 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1.3px solid #CC203B",
                        borderRadius: "7px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="font-1 input-custom-HRC"
                        type="number"
                        id="fullNameInput"
                        value={answers.weight}
                        onChange={(e) =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            weight: e.target.value,
                          }))
                        }
                        style={{ padding: "5px", borderWidth: "0px" }}
                      />
                      <p
                        className="font-1"
                        style={{ marginBottom: "0rem", marginRight: "0.3rem" }}
                      >
                        Kg
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 5 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1.3px solid #CC203B",
                        borderRadius: "7px",
                      }}
                    >
                      <input
                        type="number"
                        id="waistInput"
                        name="waist"
                        value={answers.waist}
                        onChange={(e) =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            waist: e.target.value,
                          }))
                        }
                        style={{
                          width: "10rem",
                          padding: "5px",
                          borderRadius: "7px",
                          fontSize: "14px",
                          outline: "none",
                          textAlign: "start",
                        }}
                        className="font-1 input-custom-HRC"
                      />
                      <Select
                        options={waistOptions}
                        onChange={handleWaistUnitChange}
                         value={{value:answers.waistUnit,
                      label:answers.waistUnit
                       }}
                        className="select-custom"
                        isSearchable={false}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "0px solid #CC203B",
                            borderRadius: "7px",
                            fontSize: "14px",
                            width: "5rem",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#fff', // Adjust as needed
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            fontSize: "14px",
                          }),
                          option: (provided,state) => ({
                            ...provided,
                            fontSize: "14px",
                            color: state.isSelected ? 'white' : 'black',
                        backgroundColor:  state.isSelected ? '#CC203B' : 'white',
                            transition: '0s'
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <p
                    className="font-1"
                    onClick={openWaistModal}
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "30px",
                      fontStyle: "italic",
                      color: "#CC203B",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    See how to measure waist circumference?
                  </p>
                </div>
              </>
            )}
            {currentQuestion === 6 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1.3px solid #CC203B",
                        borderRadius: "7px",
                      }}
                    >
                      <input
                        type="number"
                        id="waistInput"
                        name="waist"
                        value={answers.hip}
                        onChange={(e) =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            hip: e.target.value,
                          }))
                        }
                        style={{
                          width: "10rem",
                          padding: "5px",
                          borderRadius: "7px",
                          fontSize: "14px",
                          outline: "none",
                          textAlign: "start",
                        }}
                        className="font-1 input-custom-HRC"
                      />
                      <Select
                        options={hipOptions}
                        onChange={handleHipUnitChange}
                         value={{value:answers.hipUnit,
                      label:answers.hipUnit
                       }}
                        className="select-custom"
                        isSearchable={false}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "0px solid #CC203B",
                            borderRadius: "7px",
                            fontSize: "14px",
                            width: "5rem",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#fff', // Adjust as needed
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            fontSize: "14px",
                          }),
                          option: (provided,state) => ({
                            ...provided,
                            fontSize: "14px",
                            color: state.isSelected ? 'white' : 'black',
                        backgroundColor:  state.isSelected ? '#CC203B' : 'white',
                            transition: '0s'
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <p
                    className="font-1"
                    onClick={skipToNextQuestion}
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "30px",
                      fontStyle: "italic",
                      color: "#CC203B",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Skip to next question
                  </p>
                </div>
              </>
            )}
            {currentQuestion === 7 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p
                    className="font-1"
                    style={{ fontSize: "1.3rem", cursor: "default" }}
                  >
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1.3px solid #CC203B",
                        borderRadius: "7px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="font-1 input-custom-HRC"
                        type="number"
                        id="fullNameInput"
                        value={answers.bodyFat}
                        onChange={(e) =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            bodyFat: e.target.value,
                          }))
                        }
                        style={{ padding: "5px", borderWidth: "0px" }}
                      />
                      <p
                        className="font-1"
                        style={{ marginBottom: "0rem", marginRight: "0.3rem" }}
                      >
                        %
                      </p>
                    </div>
                  </div>
                  <p
                    className="font-1"
                    onClick={skipToNextQuestion}
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "30px",
                      fontStyle: "italic",
                      color: "#CC203B",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Skip to next question
                  </p>
                </div>
              </>
            )}
            {currentQuestion === 8 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p
                    className="font-1"
                    style={{ fontSize: "1.3rem", marginBottom: "0rem" }}
                  >
                    {questions[currentQuestion].qText}
                  </p>
                  <p
                    className="font-1"
                    style={{
                      fontSize: "0.8rem",
                      marginBottom: "0rem",
                      color: "#BFBFC5",
                    }}
                  >
                    (Includes commuting to work, breaks at work, and household
                    chores)
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        border: "1.3px solid #CC203B",
                        borderRadius: "7px",
                      }}
                    >
                      <input
                        type="number"
                        id="timeInput"
                        name="time"
                        value={time}
                        onChange={handleTimeChange}
                        style={{
                          width: "10rem",
                          padding: "5px",
                          borderRadius: "7px",
                          fontSize: "14px",
                          outline: "none",
                          textAlign: "start",
                        }}
                        className="font-1 input-custom-HRC"
                      />
                      <Select
                        options={timeOptions}
                        onChange={handleTimeUnitChange}
                        value={timeunit}
                        className="select-custom timeChange-2"
                        isSearchable={false}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "0px solid #CC203B",
                            borderRadius: "7px",
                            fontSize: "14px",
                            width: "5rem",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#fff', // Adjust as needed
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            fontSize: "14px",
                          }),
                          option: (provided,state) => ({
                            ...provided,
                            fontSize: "14px",
                            color: state.isSelected ? 'white' : 'black',
                        backgroundColor:  state.isSelected ? '#CC203B' : 'white',
                            transition: '0s'
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <p
                    className="font-1"
                    onClick={skipToNextQuestion}
                    style={{
                      fontSize: "0.8rem",
                      marginTop: "30px",
                      fontStyle: "italic",
                      color: "#CC203B",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Skip to next question
                  </p>
                </div>
              </>
            )}
            {currentQuestion === 9 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.sitTime === "Less than 1 hour"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sitTime: "Less than 1 hour",
                        }))
                      }
                    >
                      Less than 1 hour
                    </button>
                    <button
                      className={`font-1 ${
                        answers.sitTime === "1-4 hours" ? "selectedButton" : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sitTime: "1-4 hours",
                        }))
                      }
                    >
                      1-4 hours
                    </button>
                    <button
                      className={`font-1 ${
                        answers.sitTime === "More than 4 hours"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sitTime: "More than 4 hours",
                        }))
                      }
                    >
                      More than 4 hours
                    </button>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 10 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.leisureTime === "Rarely or Never"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          leisureTime: "Rarely or Never",
                        }))
                      }
                    >
                      Rarely or never
                    </button>
                    <button
                      className={`font-1 ${
                        answers.leisureTime === "Less than 1 hour"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          leisureTime: "Less than 1 hour",
                        }))
                      }
                    >
                      {" "}
                      Less than 1 hour
                    </button>
                    <button
                      className={`font-1 ${
                        answers.leisureTime === "1 to 3 hours"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          leisureTime: "1 to 3 hours",
                        }))
                      }
                    >
                      1 to 3 hours
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "1.5%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.leisureTime === "4 to 8 hours"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          leisureTime: "4 to 8 hours",
                        }))
                      }
                    >
                      4 to 8 hours
                    </button>
                    <button
                      className={`font-1 ${
                        answers.leisureTime === "More than 8 hours"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          leisureTime: "More than 8 hours",
                        }))
                      }
                    >
                      More than 8 hours
                    </button>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 11 && (
              <>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div>
                      <button
                        className={`font-1 ${
                          answers.activityIntensity === "Low intensity"
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            activityIntensity: "Low intensity",
                          }))
                        }
                      >
                        Low intensity
                      </button>
                      <div className="tooltip">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          e.g., walking, gentle stretching, low impact aerobics
                        </span>
                      </div>
                    </div>
                    <div>
                      <button
                        className={`font-1 ${
                          answers.activityIntensity === "Moderate intensity"
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            activityIntensity: "Moderate intensity",
                          }))
                        }
                      >
                        Moderate intensity
                      </button>
                      <div className="tooltip">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          e.g., brisk walking, cycling, casual cricket &
                          badminton games, moderate weightlifting
                        </span>
                      </div>
                    </div>
                    <div>
                      <button
                        className={`font-1 ${
                          answers.activityIntensity === "High intensity"
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            activityIntensity: "High intensity",
                          }))
                        }
                      >
                        High intensity
                      </button>
                      <div className="tooltip">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          e.g., running, football, competitive cricket &
                          badminton games, HIIT workouts, intense weightlifting
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 12 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p
                    className="font-1"
                    style={{ fontSize: "1.3rem", marginBottom: "0rem" }}
                  >
                    {questions[currentQuestion].qText}
                  </p>
                  <p
                    className="font-1"
                    style={{
                      fontSize: "0.8rem",
                      marginBottom: "0rem",
                      color: "#BFBFC5",
                    }}
                  >
                    Required at least a day of bed rest.
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.sicknessFrequency === "Rarely or Never"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sicknessFrequency: "Rarely or Never",
                        }))
                      }
                    >
                      Rarely or never
                    </button>
                    <button
                      className={`font-1 ${
                        answers.sicknessFrequency === "1 to 2 times"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sicknessFrequency: "1 to 2 times",
                        }))
                      }
                    >
                      1 to 2 times
                    </button>
                    <button
                      className={`font-1 ${
                        answers.sicknessFrequency === "2 to 3 times"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sicknessFrequency: "2 to 3 times",
                        }))
                      }
                    >
                      2 to 3 times
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "1.5%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.sicknessFrequency === "4 to 5 times"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sicknessFrequency: "4 to 5 times",
                        }))
                      }
                    >
                      4 to 5 times
                    </button>
                    <button
                      className={`font-1 ${
                        answers.sicknessFrequency === "More than 6 times"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          sicknessFrequency: "More than 6 times",
                        }))
                      }
                    >
                      More than 6 times
                    </button>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 13 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Type 2 diabetes")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Type 2 diabetes", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Type 2 diabetes",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Type 2 diabetes
                      </button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Fatty liver")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Fatty liver", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Fatty liver",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Fatty liver
                      </button>
                      <div className="tooltip" style={{ marginTop: "0px" }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">NAFLD</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Heart ailments")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Heart ailments", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Heart ailments",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Heart ailments
                      </button>
                      <div className="tooltip" style={{ marginTop: "0px" }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          e.g., Heart disease, heart attack or stroke
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "2%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Hypertension")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Hypertension", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Hypertension",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Hypertension
                      </button>
                      <div className="tooltip" style={{ marginTop: "0px" }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">High blood pressure</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Lipid disorders")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Lipid disorders", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Lipid disorders",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Lipid disorders
                      </button>
                      <div className="tooltip" style={{ marginTop: "0px" }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          e.g., High cholesterol, triglycerides
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Stroke")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Stroke", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Stroke",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Stroke
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "2%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Thyroid disorders")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Thyroid disorders", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Thyroid disorders",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Thyroid disorders
                      </button>
                      <div className="tooltip" style={{ marginTop: "0px" }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          e.g., hypothyroidism
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("PCOS")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "PCOS", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "PCOS",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        PCOS
                      </button>
                      <div className="tooltip" style={{ marginTop: "0px" ,zIndex:'1'}}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          Polycystic Ovary Syndrome{" "}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Mental Health")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Mental Health", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Mental Health",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Mental Health
                      </button>
                      <div className="tooltip" style={{ marginTop: "0px" }}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{
                            color: "#cc203b",
                            cursor: "pointer",
                            fontSize: "0.9rem",
                          }}
                        />
                        <span className="tooltiptext">
                          Stress / Depression / Other psychological disorders
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "2%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      {" "}
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("Other")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() => {
                          setAnswers((prevAnswers) => {
                            let updatedDiseases;
                            // Check if 'None' is present in the diseases array
                            const noneIndex =
                              prevAnswers.diseases.indexOf("None");
                            if (noneIndex !== -1) {
                              // Remove 'None' and add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases.slice(0, noneIndex), // Copy items before 'None'
                                ...prevAnswers.diseases.slice(noneIndex + 1), // Copy items after 'None'
                                "Other", // Add 'Type 2 diabetes'
                              ];
                            } else {
                              // 'None' is not present, just add 'Type 2 diabetes'
                              updatedDiseases = [
                                ...prevAnswers.diseases,
                                "Other",
                              ];
                            }

                            return {
                              ...prevAnswers,
                              diseases: updatedDiseases,
                            };
                          });
                        }}
                      >
                        Other
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("None")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        onClick={() =>
                          setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            diseases: ["None"],
                          }))
                        }
                      >
                        None
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "5px",
                        width: "12rem",
                      }}
                    >
                      <button
                        className={`font-1 ${
                          answers.diseases.includes("4 to 5 times")
                            ? "selectedButton"
                            : ""
                        }`}
                        id="reportButton"
                        style={{ visibility: "hidden", cursor: "default" }}
                      >
                        1 to 2 times
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 14 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p
                    className="font-1"
                    style={{ fontSize: "1.3rem", marginBottom: "0rem" }}
                  >
                    {questions[currentQuestion].qText}
                  </p>
                  <p
                    className="font-1"
                    style={{
                      fontSize: "0.8rem",
                      marginBottom: "0rem",
                      color: "#BFBFC5",
                    }}
                  >
                    Choose your top two priority.
                  </p>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "6%",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.priorities.includes("Weight Loss")
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() => {
                        setAnswers((prevAnswers) => {
                          // Check if 'Weight Loss' is already in the array
                          const isWeightLossPresent =
                            prevAnswers.priorities.includes("Weight Loss");

                          // Create a new array without 'Weight Loss' if already present
                          let updatedPriorities = isWeightLossPresent
                            ? prevAnswers.priorities.filter(
                                (priority) => priority !== "Weight Loss"
                              )
                            : [...prevAnswers.priorities];

                          // Add 'Weight Loss' only if there are less than 2 elements in the array
                          if (
                            updatedPriorities.length < 2 &&
                            !isWeightLossPresent
                          ) {
                            updatedPriorities = [
                              ...updatedPriorities,
                              "Weight Loss",
                            ];
                          }

                          // Update the state with the modified priorities array
                          return {
                            ...prevAnswers,
                            priorities: updatedPriorities,
                          };
                        });
                      }}
                    >
                      Weight Loss
                    </button>
                    <button
                      className={`font-1 ${
                        answers.priorities.includes(
                          "Improving Metabolic Health"
                        )
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() => {
                        setAnswers((prevAnswers) => {
                          // Check if 'Weight Loss' is already in the array
                          const isWeightLossPresent =
                            prevAnswers.priorities.includes(
                              "Improving Metabolic Health"
                            );

                          // Create a new array without 'Weight Loss' if already present
                          let updatedPriorities = isWeightLossPresent
                            ? prevAnswers.priorities.filter(
                                (priority) =>
                                  priority !== "Improving Metabolic Health"
                              )
                            : [...prevAnswers.priorities];

                          // Add 'Weight Loss' only if there are less than 2 elements in the array
                          if (
                            updatedPriorities.length < 2 &&
                            !isWeightLossPresent
                          ) {
                            updatedPriorities = [
                              ...updatedPriorities,
                              "Improving Metabolic Health",
                            ];
                          }

                          // Update the state with the modified priorities array
                          return {
                            ...prevAnswers,
                            priorities: updatedPriorities,
                          };
                        });
                      }}
                    >
                      Improving Metabolic Health
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "6%",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.priorities.includes("Building Muscle Mass")
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() => {
                        setAnswers((prevAnswers) => {
                          // Check if 'Weight Loss' is already in the array
                          const isWeightLossPresent =
                            prevAnswers.priorities.includes(
                              "Building Muscle Mass"
                            );

                          // Create a new array without 'Weight Loss' if already present
                          let updatedPriorities = isWeightLossPresent
                            ? prevAnswers.priorities.filter(
                                (priority) =>
                                  priority !== "Building Muscle Mass"
                              )
                            : [...prevAnswers.priorities];

                          // Add 'Weight Loss' only if there are less than 2 elements in the array
                          if (
                            updatedPriorities.length < 2 &&
                            !isWeightLossPresent
                          ) {
                            updatedPriorities = [
                              ...updatedPriorities,
                              "Building Muscle Mass",
                            ];
                          }

                          // Update the state with the modified priorities array
                          return {
                            ...prevAnswers,
                            priorities: updatedPriorities,
                          };
                        });
                      }}
                    >
                      Building Muscle Mass
                    </button>
                    <button
                      className={`font-1 ${
                        answers.priorities.includes("Increasing Energy Levels")
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() => {
                        setAnswers((prevAnswers) => {
                          // Check if 'Weight Loss' is already in the array
                          const isWeightLossPresent =
                            prevAnswers.priorities.includes(
                              "Increasing Energy Levels"
                            );

                          // Create a new array without 'Weight Loss' if already present
                          let updatedPriorities = isWeightLossPresent
                            ? prevAnswers.priorities.filter(
                                (priority) =>
                                  priority !== "Increasing Energy Levels"
                              )
                            : [...prevAnswers.priorities];

                          // Add 'Weight Loss' only if there are less than 2 elements in the array
                          if (
                            updatedPriorities.length < 2 &&
                            !isWeightLossPresent
                          ) {
                            updatedPriorities = [
                              ...updatedPriorities,
                              "Increasing Energy Levels",
                            ];
                          }

                          // Update the state with the modified priorities array
                          return {
                            ...prevAnswers,
                            priorities: updatedPriorities,
                          };
                        });
                      }}
                    >
                      Increasing Energy Levels
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "3%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "6%",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.priorities.includes("Increasing Strength")
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() => {
                        setAnswers((prevAnswers) => {
                          // Check if 'Weight Loss' is already in the array
                          const isWeightLossPresent =
                            prevAnswers.priorities.includes(
                              "Increasing Strength"
                            );

                          // Create a new array without 'Weight Loss' if already present
                          let updatedPriorities = isWeightLossPresent
                            ? prevAnswers.priorities.filter(
                                (priority) => priority !== "Increasing Strength"
                              )
                            : [...prevAnswers.priorities];

                          // Add 'Weight Loss' only if there are less than 2 elements in the array
                          if (
                            updatedPriorities.length < 2 &&
                            !isWeightLossPresent
                          ) {
                            updatedPriorities = [
                              ...updatedPriorities,
                              "Increasing Strength",
                            ];
                          }

                          // Update the state with the modified priorities array
                          return {
                            ...prevAnswers,
                            priorities: updatedPriorities,
                          };
                        });
                      }}
                    >
                      Increasing Strength
                    </button>
                    <button
                      className={`font-1 ${
                        answers.priorities.includes(
                          "Improving Physical Endurance"
                        )
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() => {
                        setAnswers((prevAnswers) => {
                          // Check if 'Weight Loss' is already in the array
                          const isWeightLossPresent =
                            prevAnswers.priorities.includes(
                              "Improving Physical Endurance"
                            );

                          // Create a new array without 'Weight Loss' if already present
                          let updatedPriorities = isWeightLossPresent
                            ? prevAnswers.priorities.filter(
                                (priority) =>
                                  priority !== "Improving Physical Endurance"
                              )
                            : [...prevAnswers.priorities];

                          // Add 'Weight Loss' only if there are less than 2 elements in the array
                          if (
                            updatedPriorities.length < 2 &&
                            !isWeightLossPresent
                          ) {
                            updatedPriorities = [
                              ...updatedPriorities,
                              "Improving Physical Endurance",
                            ];
                          }

                          // Update the state with the modified priorities array
                          return {
                            ...prevAnswers,
                            priorities: updatedPriorities,
                          };
                        });
                      }}
                    >
                      Improving Physical Endurance
                    </button>
                  </div>
                </div>
              </>
            )}
            {currentQuestion === 15 && (
              <>
                <div
                  className="quesAnim"
                  style={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    paddingTop: "10%",
                  }}
                >
                  <p className="font-1" style={{ fontSize: "1.3rem" }}>
                    {questions[currentQuestion].qText}
                  </p>
                  <div
                    style={{
                      marginTop: "2%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "6%",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.lifestylePriorities ===
                        "Reducing daily diet intake"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          lifestylePriorities: "Reducing daily diet intake",
                        }))
                      }
                    >
                      Reducing daily diet intake
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "2%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "6%",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.lifestylePriorities ===
                        "Increasing physical activity"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          lifestylePriorities: "Increasing physical activity",
                        }))
                      }
                    >
                      Increasing physical activity
                    </button>
                  </div>
                  <div
                    style={{
                      marginTop: "2%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "6%",
                    }}
                  >
                    <button
                      className={`font-1 ${
                        answers.lifestylePriorities === "Forming healthy habits"
                          ? "selectedButton"
                          : ""
                      }`}
                      id="reportButton2"
                      onClick={() =>
                        setAnswers((prevAnswers) => ({
                          ...prevAnswers,
                          lifestylePriorities: "Forming healthy habits",
                        }))
                      }
                    >
                      Forming healthy habits
                    </button>
                  </div>
                </div>
              </>
            )}
            <Modal
              isOpen={showEmailModal}
              // onRequestClose={closeModal}
            >
              <EmailModal
                onCancel={CancelEmailModal}
                onSubmit={SubmitEmailModal}
                reportAnswer={answers}
                entryoption={type}
              />
            </Modal>
            <Modal
              isOpen={showWaistModal}
              // onRequestClose={closeModal}
            >
              <WaistModal onClose={closeWaistModal} />
            </Modal>

            <Modal
              isOpen={showThankModal}
              // onRequestClose={closeModal}
              className="modal"
              overlayClassName="modal-overlay"
            >
              <ThankModal onClose={closeThankModal} />
            </Modal>
          </div>
          <div
            style={{
              height: "13%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="eqc-bottom-skip svg-wrapper-HRC"
              onClick={skipQuiz}
              style={{
                display: "flex",
                height: "90%",
                alignItems: "center",
                marginLeft: "2%",
                cursor: "pointer",
              }}
            >
              <div style={{ alignItems: "center", display: "flex" }}>
                <p
                  className="font-1"
                  style={{
                    color: "rgb(111 111 114)",
                    fontSize: "1.6rem",
                    fontStyle: "italic",
                    marginBottom: "0rem",
                    fontWeight: "200",
                    marginRight: "5px",
                  }}
                >
                  Skip
                </p>
              </div>
              <img
                src={skipArrow}
                alt=""
                style={{
                  height: "25%",
                  marginLeft: "2%",
                  marginBottom: "1%",
                }}
              />
            </div>
            <div
              className="svg-wrapper"
              style={{
                width: "5%",
                marginRight: "3rem",
                display: "flex",
                gap: "1rem",
                justifyContent: "end",
              }}
            >
              {currentQuestion != 0 && (
                <img
                  onClick={prevQuestion}
                  className="eqc-bottom-prev"
                  src={prevArrow}
                  alt=""
                  style={{
                    height: "1.3rem",
                    margin: "1%",
                    paddingRight: "0.2rem",
                    cursor: "pointer",
                  }}
                />
              )}
              <img
                onClick={nextQuestion}
                className= {hoverCheck(currentQuestion)? "": "eqc-bottom-next" }
                src={nextArrow}
                alt=""
                style={{
                  height: "1.3rem",
                  margin: "1%",
                  paddingRight: "0.2rem",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <HealthReportMobile />
      )}
    </>
  );
};

export default HealthReportComponent;
