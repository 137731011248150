// MetabolicHealthFormModal.js
import React, { useState } from 'react';
import './MetabolicHealthFormModal.css';
import axios from 'axios';

const MetabolicHealthFormModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    contactNumber: '',
    bloodTestDone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://prod-api.fitnastichealth.in/api/v1/leads/metabolicReport', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        console.log('Form Data:', response.data);
        // Clear the form data
        setFormData({
          fullName: '',
          contactNumber: '',
          bloodTestDone: '',
        });
        // Close the modal
        onClose();
      }
    } catch (error) {
      console.error('There was a problem with the axios operation:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>Check Your Metabolic Health Score</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Phone No.</label>
            <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label>Have you gotten your blood tests done in the last 3 months?</label>
            <input type="text" name="bloodTestDone" value={formData.bloodTestDone} onChange={handleChange} required />
          </div>
          <button type="submit">Enquire Now</button>
        </form>
      </div>
    </div>
  );
};

export default MetabolicHealthFormModal;
