import React from 'react';
// import journeyImage from '../../images/journeyImg.png'
// import journeyImage3 from '../../images/fitrishi.svg'
import journeyImage3 from '../../images/JourneyNew/fitRISHI.png'
import { useState, useEffect } from "react";

// import journeyImage2 from '../../images/Journey/journeyImg.png'
import '../../App.css'
import './journey.css'
 
const Journey = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (

    (isMobile) 
    ?  <div className='myContainer' style={{ height: '100vh', position: 'relative', marginTop:'0%' }}>
      <div style={{ height: '50%', backgroundColor: 'white', position: 'absolute', top: 0, left: 0, width: '100%' }}>
      <div style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
            <div className='texty' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' ,justifyContent:'center' }}>
                <h1 className='font-1 jfont4' style={{ color:'#CC203B', fontSize:'3.5vw', fontWeight:350}}>Why <span className='font-2 jfont4' style={{fontSize:'4vw'}}>We Exist</span> </h1>
                <div className='font-1 jfont2' style={{fontWeight:'700',fontSize:'2vw'}}>The Man You See, The Journey You Don't</div>
               
            </div>
        </div>
        <div style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
          <div>
           
          </div>
        </div>
      </div>
      <div  style={{ height: '50%', backgroundColor: '#F3F3F4', position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
      <div style={{ position: 'absolute', top: '79%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
            <div className='container-2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className='font-2 jfont1-8' style={{ color: 'white', backgroundColor:'#CC203B', padding: "0px", fontSize:'2vw', marginBottom:'4%' }}>This Is Not a Fat To Fit Story...</div>
                <p className='font-1 jfont1-2' style={{margin: 2, fontSize:'1.2vw'}}>What you see here is a man who lost 18 Kgs</p>
                <p className='font-1 jfont1-2' style={{margin: 2,fontSize:'1.2vw'}}>
                    <span style={{fontWeight:'700'}} className='font-1'>What you don't know is WHY - The Reason Will Surprise You</span>
                </p>
                <p className='font-1 jfont1-2' style={{fontSize:'1.2vw'}}>
                    Rishi got FIT not just to <span style={{fontWeight:'700'}}>loose weight</span> - but, <span className='font-1' style={{ color: 'white', backgroundColor:'#CC203B', padding: "4px" , fontWeight:'bold'}}>to SURVIVE</span>
                </p>
            </div>
        </div>
      </div>
      <div className='myimg' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1,display:'flex',justifyContent:'center' }}>
        <img className='myimg2' src={journeyImage3} alt="Center Image" style={{ maxWidth: '95%', maxHeight: '95%' ,width:'72vh'}} />
      </div>
     
    </div>
    : <div className='jn-container'>
    <div className='jn-color-div'>
      <div className='jn-bg-color-white'></div>
      <div className='jn-bg-color-grey'></div>
    </div>
    <div className='jn-content-div'>
      <div className='jn-content-top'>
        <h1 className='font-1 jfont4' style={{ color:'#CC203B', fontSize:'3.5vw', fontWeight:350}}>Why <span className='font-2 jfont4' style={{fontSize:'4vw'}}>We Exist</span> </h1>
        <div className='font-1 jfont2' style={{fontWeight:'700',fontSize:'2vw'}}>The Man You See, The Journey You Don't</div>

      </div>

      <div className='jn-content-middle'>
        <img className='myimg2' src={journeyImage3} alt="Center Image" style={{ maxWidth: '95%', maxHeight: '95%' ,width:'69vh'}} />
      </div>

      <div className='jn-content-bottom'>
        <div className='font-2 jfont1-8' style={{ color: 'white', backgroundColor:'#CC203B', padding: "0px", fontSize:'2vw', marginTop:'0.6vw',marginBottom:'0.7vw' }}>This Is Not a Fat To Fit Story...</div>
        <p className='font-1 jfont1-2' style={{margin: 2, fontSize:'1.2vw'}}>What you see here is a man who lost 18 Kgs</p>
        <p className='font-1 jfont1-2' style={{margin: 2,fontSize:'1.2vw'}}>
            <span style={{fontWeight:'700'}} className='font-1'>What you don't know is WHY - The Reason Will Surprise You</span>
        </p>
        <p className='font-1 jfont1-2' style={{fontSize:'1.2vw'}}>Rishi got FIT not just to <span style={{fontWeight:'700'}}>loose weight</span> - but, <span className='font-1' style={{ color: 'white', backgroundColor:'#CC203B', padding: "4px" , fontWeight:'bold'}}>to SURVIVE</span>
        </p>
      </div>
    </div>
  </div>
  );
}
 
export default Journey;