import React from "react";

import "./ContactIntroBox.css";
import { useState, useEffect } from "react";
import bg from '../../images/ContactPage/contact1.svg'

import mobileBg from '../../images/ContactPage/contact2.svg'

import { Link } from "react-router-dom";

function ContactIntroBox() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const btnStyle = {
    border: "1.5px solid rgb(255, 255, 255)",
    borderRadius: "50px",
    paddingTop: "1rem",
    paddingBottom: "0.8rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginTop: "3vw",
    marginBottom: "3vw",
    color: "rgb(255, 255, 255)",
    fontSize: "1rem",
    fontWeight: "600",
  };
  return (
    <>
      {!isMobile ? <div className="cib-container" style={{backgroundImage:`url(${bg})`}}>
        <div>
            <p className="cib-heading-a font-2">Have questions, feedback, or <br /> just want to say hello?</p>
            <p className="cib-heading-b font-1">We'd love to hear from you! Your thoughts and <br /> inquiries are important to us.</p>
        </div>

      </div> : (
        <div
        style={{
          paddingTop:'35%',
          paddingBottom:'30%',
          height:'80vh',
          backgroundImage: `url(${mobileBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft:'3%',
          paddingRight:'3%',
          gap:'2%'
        }}
      >
        <p className="m-cib-heading-a font-2">Have questions,<br /> feedback, or just want <br /> to say hello?</p>
        <p className="m-cib-heading-b font-1">We'd love to hear from you! <br /> Your thoughts and inquiries <br /> are important to us</p>

      </div>
      )}
    </>
  );
}

export default ContactIntroBox;
