import React from "react";
import "./CorporateAiIntro.css";
import { useState, useEffect } from "react";
const CorporateAiIntro = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile ? (
        <div
          className="aiBox"
          style={{
            backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporatePage/wellnessProgram.jpg)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            alignContent: "center",
            justifyContent: "center",
            marginTop: "5vw",
            backgroundPositionY: "-130px",
          }}
        >
          <div
            className="contentBox"
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginRight: "5vw",
              marginLeft: "5vw",
              paddingTop: "15vh",
              paddingBottom: "15vh",
              height: "100%",
              color: "white",
            }}
          >
            <h1 className="headingg font-1">Introducing The Future Of </h1>
            <h1
              className="headingg font-1"
              style={{ lineHeight: "0.50", marginBottom: "2vh" }}
            >
              Employee Wellness
              <span style={{ fontFamily: "ariel", fontSize: "3.5rem" }}>
                ...
              </span>
            </h1>
            <p>
              <span
                className="font-2 "
                style={{
                  color: "white",
                  backgroundColor: "#CC203B",
                  padding: "0px",
                  paddingLeft: "17px",
                  paddingRight: "15px",
                  fontSize: "3vw",
                  marginLeft: "15vw",
                  marginRight: "15vw",
                  marginTop: "3vw",
                }}
              >
                SMART Bio AI Wellness Program
              </span>
            </p>
            <div className="desc font-1" style={{ fontSize: "2vw" }}>
              NOW, Don't Just Do Blood Tests & Workouts
              <span
                style={{
                  fontFamily: "ariel",
                  fontSize: "1.5rem",
                  letterSpacing: "2.5px",
                }}
              >
                ...
              </span>
            </div>
            <div className="desc font-1" style={{ fontSize: "2vw" }}>
              Increase Empolee Satisfaction & Performance
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporatePageMobile/BioAi.svg)`,
            width: "100%",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <div  style={{
              height: "100%",
              width: "100%",
              paddingTop:'15%',
              paddingBottom:'10%',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
             <div>
              <h1 className="font-1 m-bio-heading2">Introducing The Future</h1>
              <h1 className="font-1 m-bio-heading2">Of Employee Wellness…</h1>
            </div>
            <div style={{ marginTop: "5%" }}>
              <h1 className="font-2 m-bio-heading1">
                <span style={{ backgroundColor: "#CC203B" ,paddingLeft:'0.5rem',paddingRight:'0.5rem'}}>
                  SMART Bio AI Wellness
                </span>
              </h1>
              <h1 className="font-2 m-bio-heading1" style={{ margin: "6%" }}>
                {" "}
                <span style={{ backgroundColor: "#CC203B",paddingLeft:'0.5rem',paddingRight:'0.5rem' }}>Program</span>
              </h1>
            </div>
            <div className="mainText">
              <p className="font-1" style={{lineHeight:'0'}}>NOW, Don’t Just Do Blood Tests &</p>
              <p className="font-1">
                Workouts… Increase Employee Satisfaction & Performance
              </p>

            </div>
            </div>
           
          </div>
        </div>
      )}
    </>
  );
};

export default CorporateAiIntro;
