import React from 'react'
import './TechMetSights.css'
import tmsBg from '../../../images/TechPageImages/TechMetSights/tmsBgPngNew.png'
import techmet1 from '../../../images/TechPageImages/TechMetSights/techmet1.svg'
import techmet2 from '../../../images/TechPageImages/TechMetSights/techmet2.svg'
import techmet3 from '../../../images/TechPageImages/TechMetSights/techmet3.svg'
import techmet4 from '../../../images/TechPageImages/TechMetSights/techmet4.svg'
import techmet5 from '../../../images/TechPageImages/TechMetSights/techmet5.svg'
import techmet6 from '../../../images/TechPageImages/TechMetSights/techmet6.svg'
import techmet7 from '../../../images/TechPageImages/TechMetSights/techmet7.svg'
import humandnabodyimg from '../../../images/TechPageImages/TechMetSights/humandnabodyimg.svg'
import downredarrow from '../../../images/TechPageImages/TechMetSights/downredarrow.svg'
import tmsMain from '../../../images/TechPageImages/TechMetSights/tmsMain2.svg'
import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

const TechMetSights = () => {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    AOS.init({ duration: 600});
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    {!isMobile? <div className="tms-container" 
    style={{backgroundImage:`url(${tmsBg})`}}
    >
        <div className="tms-content">
        <div className="tms-heading font-2">Introducing...</div>
        <div className="tms-heading2 font-1">The Best-In-Class Tech For Your Health
        Systems Biology Driven AI</div>
        <div className="tms-subHeading font-2">This Is How We Do Your Complete Health Analysis</div>
        <div className="tms-subHeadingBox font-2">Systems Biology Approach in MetSights</div>
        <div className="tms-svg" 
            // style={{backgroundImage:`url(${tmsMain})`}}
        >
        <img src={tmsMain} alt="Header Image" className='tms-headCompImg' />
        </div>
        <div className="tms-subHeading2 font-2">Our systems biology driven approach doesn’t just consider your diet or <br /> your anthropometric data. It also takes into account your blood markers, <br /> lifestyle information and most importantly family history.</div>
        <div className="tms-btn">
        <Link to="/freeHealthReport">
        <button className='font-1 cib-btn'style={{
          border: '2px', 
          borderColor:'rgb(204, 32, 59)', 
          borderStyle: 'solid', 
          borderRadius: '50px', 
          paddingTop:'12px', 
          paddingBottom:'10px', 
          paddingLeft:'20px', 
          paddingRight:'20px',
          marginTop: '4rem',
          color:'rgb(204, 32, 59)',
          fontWeight: 500
          }}>
            GET FREE HEALTH REPORT            
        </button>
        </Link>
        </div>
        </div>
    </div> :
    <div class = 'm-tms-main'>
      <div className='font-2 m-tms-heading2'>Introducing…</div>
      <div className='font-1 m-tms-heading1'>The Best-In-Class Tech For Your Health Systems Biology Driven AI</div>
      <div className='font-2 m-tms-how'>This Is How We Do Your Complete Health Analysis</div>
      <div className="m-tms-box-containers">
        
        <div data-aos = "zoom-in-up" data-aos-easing="linear" className='m-tms-box'>
            <div className="m-tms-box-img"><img src={techmet1} alt="" /></div>
            <div className='font-2 m-tms-box-txt'><span>Anthropometric Data</span></div>
        </div>
        
        <div data-aos = "zoom-in-up" data-aos-easing="linear" className="m-tms-box-topline"/>
        
        <div data-aos = "zoom-in-up" data-aos-easing="linear" className='m-tms-box'>
            <div className="m-tms-box-img"><img src={techmet2} alt="" /></div>
            <div className='font-2 m-tms-box-txt'><span>Blood Profiles</span></div>
        </div>
        
        <div data-aos = "zoom-in-up" data-aos-easing="linear" className="m-tms-box-topline"/>
        
        <div data-aos = "zoom-in-up" data-aos-easing="linear" className='m-tms-box'>
            <div className="m-tms-box-img"><img src={techmet3} alt="" /></div>
            <div className='font-2 m-tms-box-txt'><span>Diet Information</span></div>
        </div>
        
        <div data-aos = "zoom-in-up" data-aos-easing="linear" className="m-tms-box-topline"/>
        
        <div data-aos = "zoom-in-up" data-aos-easing="linear" className='m-tms-box'>
            <div className="m-tms-box-img"><img src={techmet4} alt="" /></div>
            <div className='font-2 m-tms-box-txt'><span>Lifestyle Information</span></div>
        </div>
        
        <div>
          <img data-aos = "zoom-in-up" data-aos-easing="linear" src={downredarrow} alt="" />
        </div>
        
        <div>
          <img data-aos = "zoom-in-up" data-aos-easing="linear" src={humandnabodyimg} alt="" />
        </div>
        
        <div data-aos = "fade-up" data-aos-easing="linear" className="m-tms-redbox">
          <p className='font-2' style={{color:'white', fontSize:'1.2rem', margin:'0px', textAlign:"center"}}>Systems Biology Approach in</p>
          <p className='font-2' style={{color:'white', fontSize:'1.2rem', margin:'0px', textAlign:'center'}}>MetSights</p>
        </div>
        
        <div data-aos = "fade-up" data-aos-easing="linear" >
          <img  src={downredarrow} alt="" />
        </div>

        <div data-aos = "fade-up" data-aos-easing="linear" className='m-tms-box'>
            <div className="m-tms-box-img"><img src={techmet5} alt="" /></div>
            <div className='font-2 m-tms-box-txt-2'>
              <span>Disease Risk Scores</span>
              <div className="font-1 m-tms-box-desc" style={{lineHeight:'1.3'}}>
                Obesity, hypertension, Diabetes, NAFLD, CVD, Dyslipidemia, Hypothyroidism, PCOS, Stroke, Metabolic Syndrome
              </div>
            </div>
        </div>

        <div data-aos = "fade-up" data-aos-easing="linear" className="m-tms-box-topline"/>

        <div data-aos = "fade-up" data-aos-easing="linear" className='m-tms-box'>
            <div className="m-tms-box-img"><img src={techmet6} alt="" /></div>
            <div className='font-2 m-tms-box-txt'><span>Place Among Peers</span></div>
        </div>

        <div data-aos = "fade-up" data-aos-easing="linear" className="m-tms-box-topline"/>

        <div data-aos = "fade-up" data-aos-easing="linear" className='m-tms-box'>
          <div className="m-tms-box-img"><img src={techmet7} alt="" /></div>
          <div className='font-2 m-tms-box-txt-2'>
            <span style={{paddingRight:'1rem', textAlign:'start'}}>Contributors for Disease</span>
            <div className="font-1 m-tms-box-desc">
                  Diet Type and Lifestyle              
            </div>
          </div>
        </div>

          <div data-aos = "zoom-in" data-aos-easing="linear" className='font-1' style={{textAlign:'center', marginTop:'2rem', marginInline:'1.5rem'}}>
            Our systems biology driven approach doesn’t just consider your diet or your anthropometric data. It also takes into account your blood markers, lifestyle information and most importantly family history.
          </div>
          <Link to="/freeHealthReport">
          <button
             style={{
               border: "1.5px",
               borderColor:"#CC203B",
               borderStyle: "solid",
               borderRadius: "50px",
               paddingTop: "0.8rem",
               paddingBottom: "0.5rem",
               paddingLeft: "1rem",
               paddingRight: "1rem",
               marginTop: "2rem",
               color:'#CC203B',
               fontSize: "1rem",
               fontWeight: 400,
               textAlign:'center'
             }}
             className="font-1"
           >
            GET FREE HEALTH REPORT
           </button>
           </Link>
     </div>
    </div>
    }
    </>
  )
}

export default TechMetSights    