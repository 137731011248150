import React from "react";
import "./TechDetails.css";
import TechDetailsImg from "../../../images/TechPageImages/TechDetailsImg.svg";
import mbg from "../../../images/TechPageImages/TechBannerImages/mid-layer.svg";
import first from "../../../images/TechPageImages/TechBannerImages/firsty.svg";
import second from "../../../images/TechPageImages/TechBannerImages/secondy.svg";
import third from "../../../images/TechPageImages/TechBannerImages/thirdy.svg";
import fourth from "../../../images/TechPageImages/TechBannerImages/fourdy.svg";
import arrow from "../../../images/TechPageImages/TechBannerImages/arrowdy.svg";
import Collapsible from "./Collapsible";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { useState, useEffect } from "react";

const TechDetails = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 600});
  }, []);


  return (
    <>
      {!isMobile ? (
        <div className="td-main">
          <div className="td-top-txt">
            <p className="td-tt-1 font-1">
              Here’s Why Our Tech Is So Accurate &
            </p>
            <p className="td-tt-2 font-2">
              Easily Beats Every Other AI In The Market…
            </p>
            <p className="font-1 td-tt-3 font-1">
              Here’s how we simplify this - every other piece of technology on
              the market today, be it{" "}
              <span className="font-1 td-tt-3-span">
                AI, a simple questionnaire based report
              </span>{" "}
              or literally anything else gives you inaccurate results. The major
              reason being{" "}
              <span className="font-1 td-tt-3-span">
                AI is not personalized.
              </span>
            </p>
          </div>
          <div className="wtc-line"></div>
          <div className="td-mid-text">
            <p className="font-1 td-mt-1">
              We’re The Complete Opposite With technology, our primary focus has
              been
            </p>
            <p className="font-1 td-mt-2">
              "PERSONALIZING HEALTHCARE AT SCALE"
            </p>
          </div>
          <div className="td-details-img">
            <img src={TechDetailsImg} alt="" />
          </div>
          <div className="td-bot-txt">
            <p>
              <span className="font-1 td-bt-1">2 Big Problems</span>
              <span className="font-2 td-bt-2">Even If You Do This…</span>
            </p>
          </div>
          <div className="td-bot-div">
            <div className="td-bd-1">
              <div className="font-1 td-bd-a">01</div>
              <div className="font-2 td-bd-b">Personalization</div>
              <div className="td-bd-c font-1">
                There’s still no personalization & we dare you to test this -
                will be fun. If you give AI a certain input - like your physical
                measurements & your goals, and if there’s someone very similar
                to you who gives almost the same inputs, the output of that AI
                will be very very similar.
              </div>
            </div>
            <div className="td-bd-2">
              <div className="font-1 td-bd-a">02</div>
              <div className="font-2 td-bd-b">Superficial</div>
              <div className="font-1 td-bd-c font-1">
                The BIGGEST problem with AI - the parameters it considers to
                give you reports are for lack of a better word - STUPID. Asking
                you a few questions about your diet, lifestyle, workouts, your
                eating preferences, it cannot give you an accurate output.
                Everybody is different, and the parameters it considers to give
                someone so called personalized outputs is complete BS.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="m-td-container">
          <p
            className="m-td-heading2 font-1"
            style={{ marginBottom: "0rem", lineHeight: "1" }}
          >
            Here’s Why Our Tech Is So
          </p>
          <p
            className="m-td-heading2 font-1"
            style={{ marginBottom: "0.7rem" }}
          >
            Accurate & Easily Beats
          </p>
          <p
            className="m-td-heading1 font-2"
            style={{ marginBottom: "0.2rem" }}
          >
            Every Other AI In The
          </p>
          <p className="m-td-heading1 font-2">Market…</p>
          <div
            className="font-1 m-tba-midPhrase"
            style={{
              width: "94%",
              position: "relative",
              left: "3%",
              paddingBottom: "1rem",
            }}
          >
            <p>
              Here’s how we simplify this - every other piece of technology on
              the market today, be it{" "}
              <strong>AI, a simple questionnaire based report</strong> or
              literally anything else gives you inaccurate results. The major
              reason being <strong>AI is not personalized.</strong>
            </p>
          </div>
          <div className="m-td-main">
            <div className="font-1 m-tba-midPhrase" style={{ width: "100%" }}>
              <p>
                We’re The Complete Opposite With technology, our primary focus
                has been <strong>“PERSONALIZING HEALTHCARE AT SCALE”</strong>
              </p>
            </div>
            <div
              className="m-td-main-2"
              style={{ backgroundImage: `url(${mbg})` }}
            >
              <div className="m-element-div" data-aos = "zoom-in-up" data-aos-easing="linear" style={{ paddingTop: "0rem" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    width: "13rem",
                    textAlign: "center",
                    paddingTop: "0.6rem",
                    paddingBottom: "0.6rem",
                  }}
                >
                  <p
                    className="font-2"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                    }}
                  >
                    Inputs
                  </p>
                </div>
                <img src={first} alt="" />
                <img src={arrow} alt="" />
              </div>
              <div className="m-element-div" data-aos = "zoom-in-up" data-aos-easing="linear">
                <div
                  style={{
                    backgroundColor: "white",
                    width: "13rem",
                    textAlign: "center",
                    paddingTop: "0.6rem",
                    paddingBottom: "0.6rem",
                  }}
                >
                  <p
                    className="font-2"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                    }}
                  >
                    Learn Patterns
                  </p>
                </div>
                <img src={second} alt="" />
                <div>
                  <p
                    className="font-1"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      marginBottom: "0rem",
                      color: "white",
                    }}
                  >
                    Machine Learning
                  </p>
                  <p
                    className="font-1"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Deep Learning <br />
                    Predictive Analytics <br /> Cognitive Computing
                  </p>
                </div>

                <img src={arrow} alt="" />
              </div>
              <div className="m-element-div" data-aos = "zoom-in-up" data-aos-easing="linear">
                <div
                  style={{
                    backgroundColor: "white",
                    width: "13rem",
                    textAlign: "center",
                    paddingTop: "0.6rem",
                    paddingBottom: "0.6rem",
                  }}
                >
                  <p
                    className="font-2"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                    }}
                  >
                    Make Predictions
                  </p>
                </div>
                <img src={third} alt="" />
                <div>
                  <p
                    className="font-1"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      marginBottom: "0rem",
                      color: "white",
                    }}
                  >
                    Neutral Network
                  </p>
                  <p
                    className="font-1"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Computer Vision <br />
                    Speech Recognition <br />
                    Language Processing
                  </p>
                </div>

                <img src={arrow} alt="" />
              </div>
              <div className="m-element-div" data-aos = "zoom-in-up" data-aos-easing="linear">
                <div
                  style={{
                    backgroundColor: "white",
                    width: "13rem",
                    textAlign: "center",
                    paddingTop: "0.6rem",
                    paddingBottom: "0.6rem",
                  }}
                >
                  <p
                    className="font-2"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                    }}
                  >
                    Perform Tasks
                  </p>
                </div>
                <img src={fourth} alt="" />
                <p
                  className="font-1"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "700",
                    marginBottom: "0rem",
                    color: "white",
                  }}
                >
                  Expert Systems
                </p>
                <p
                  className="font-1"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "400",
                    marginBottom: "0rem",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Data Processing <br />
                  Data Generation <br />
                  Data Distribution
                </p>
              </div>
             <div className="m-td-bottom-div">
             <p
                    className="font-2"
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                   In simple terms, AI needs a huge dataset to learn patterns, then and only then it can make predictions & perform tasks. The more you use that AI tech, the more data you give to the device - the more accurate AI will become.
                  </p>
               
             </div>

            </div>

          </div>
          <div className="m-big-problem-div">
          <p
                    className="font-1"
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      marginBottom: "0rem",
                      textAlign:'center'
                    }}
                  >
                   2 Big Problems
                  </p>
                  <p
            className="m-td-heading1 font-2"
            style={{ color:'black' }}
          >
            Even If You Do This…
          </p>
          <div style={{borderBottomWidth:'1.5px',borderBottomColor:"#B7B7BD",paddingBottom:'1.5rem',paddingTop:'1.5rem',width:"90%",position:"relative",left:'5%'}}>
          <Collapsible 
        number="01" 
        titleText="Personalization" 
        content="There’s still no personalization & we dare you to test this - will be fun. If you give AI a certain input - like your physical measurements & your goals, and if there’s someone very similar to you who gives almost the same inputs, the output of that AI will be very very similar."
      />
          </div>
          <div style={{paddingBottom:'1.5rem',paddingTop:'1.5rem',width:"90%",position:"relative",left:'5%'}}>
          <Collapsible 
        number="02" 
        titleText="Superficial" 
        content="The BIGGEST problem with AI - the parameters it considers to give you reports are for lack of a better word - STUPID. Asking you a few questions about your diet, lifestyle, workouts, your eating preferences, it cannot give you an accurate output. Everybody is different, and the parameters it considers to give someone so called personalized outputs is complete BS."
      />
          </div>
      </div>

          </div>
       
      )}
    </>
  );
};

export default TechDetails;
