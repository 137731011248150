import React, { useState } from 'react'
import UserSideProfile from '../../Components/UserSideProfile/UserSideProfile'
import StaffTable from '../StaffPage/StaffTable'


const Participants = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        // Add any additional search handling logic here
      };

  return (
    <div className='dhr-main-container'>
        <div className='dhr-main-container-left'>
         <UserSideProfile/>
        </div>
        <div className='dhr-main-container-right'>
        <div className="stp-top-div-container">
          <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',gap:'1rem'}}>
            
            <input 
          type="text" 
          style={{paddingTop:'0.5rem',paddingBottom:'0.5rem',paddingLeft:"1.7rem",paddingRight:'1.7rem',borderRadius:'5px'}}
          value={searchTerm} 
          onChange={handleSearchChange} 
          className="dhr-usp-search font-1" 
          placeholder="Search..." 
        />
            
            
          </div>
        </div>
        <StaffTable />
        </div>
    </div>
  )
}

export default Participants