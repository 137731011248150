import React, { useState } from "react";
import "./WaistModal.css";
import { useNavigate } from "react-router";
import axios from "axios";
import bg from '../../../images/waistImg.svg'
import { color } from "framer-motion";

const WaistModal = ({ onClose }) => {

//   const isValidEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//   const isValidMobileNumber = (mobileNumber) => {
//     const mobileRegex = /^[6-9]\d{9}$/;
//     return mobileRegex.test(mobileNumber);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!formData.email || !formData.mobileNumber) {
//       alert("All fields are required.");
//       return;
//     }

//     if (!isValidEmail(formData.email)) {
//       alert("Please enter a valid email address.");
//       return;
//     }

//     if (!isValidMobileNumber(formData.mobileNumber)) {
//       alert("Please enter a valid mobile number.");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         "https://dev-api.fitnastichealth.in/api/v1/info/formInfo",
//         formData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200) {
//         console.log("Form Data:", response.data);
//         setFormData({
//           email: "",
//           mobileNumber: "",
//         });
//         onClose();
//         navigate("/home", { replace: true });
//       }
//     } catch (error) {
//       console.error("There was a problem with the axios operation:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const navigate = useNavigate();

//   const closeModal = () => {
//     navigate("/home", { replace: true });
//   };

  return (
    <>
      <div
        className="modal"
        // onRequestClose={onClose}
        contentLabel="Example Modal"
      >
        <div
          style={{
            width: "22%",
            height: "55%",
            background: "white",
            borderRadius: "15px",
            boxShadow: "0px 0px 8px 0px #00000040",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            animationName: "fadeIn",
            animationDuration: "0.5s",
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
          }}
        >
           <div style={{height:'80%',width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <img src={bg} alt="" style={{height:'80%',width:'100%'}}/>

           </div>
           <div className='okaybtn' onClick={onClose} style={{height:'20%',width:'100%',display:'flex',alignItems:'center',justifyContent:'center',borderTopWidth:'1.5px',borderTopColor:'#cc203b',color:'#cc203b',borderBottomLeftRadius:'15px',borderBottomRightRadius:'15px',cursor:'pointer'}}>
           <p className="font-1" style={{marginBottom:'0rem',cursor:'pointer',fontSize:'1.2rem'}}>Okay</p>
           </div>
          {/* <button onClick={onClose}>Close</button> */}
        </div>
      </div>
    </>
  );
};

export default WaistModal;
