import React from 'react';
import DifferenceComponent from './DifferenceComponent'
import backgroundAsset2 from '../../images/differencesImages/asset22.svg'
import { Button } from "react-bootstrap";

import asset5 from '../../images/differencesImages/Asset5.svg'
import asset6 from '../../images/differencesImages/Asset 6.svg'
import asset7 from '../../images/differencesImages/Asset 7.svg'
import asset8 from '../../images/differencesImages/Asset 8.svg'
import asset10 from '../../images/differencesImages/Asset 10.svg'
import asset11 from '../../images/differencesImages/Asset 11.svg'
import asset12 from '../../images/differencesImages/Asset 12.svg'
import asset13 from '../../images/differencesImages/Asset 13.svg'
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import './OurDifference.css'
import NewDiff from '../NewDiff/NewDiff';

function OurDifferenceComponent() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 200});
  }, []);

  const gymDiff = [
    {
      img: asset8,
      "name": "Pre assessment form",
      "description": "To understand your fitness background, your current lifestyle and any past or current injuries."
    },
    {
      img: asset6,
      "name": "Demo Workout Session",
      "description": "Assess various aspects of your fitness. Strength, Flexibility, Mobility & the seriousness of your injuries (if any)."
    },
    {
      img: asset5,
      "name": "Movement Analysis",
      "description": "What exercises are you capable of doing."
    },
    {
      img: asset7,
      "name": "Exercise Programming",
      "description": "Roadmap on how you can achieve your fitness goals."
    }
  ]

  const ourDiff = [

    {
      img: asset13,
      "name": "Fitnastic Metabolic Health Assessment",
      "description": "Calculates disease risk scores for lifestyle diseases like diabetes, hypertension, Stroke, Obesity, NAFLD etc along with your current fitness levels."
    },
    {
      img: asset12,
      "name": "Diet | Lifestyle Analysis",
      "description": "Based on your disease risk scores & current lifestyle, our clinical dieticians give you a customized diet plan & tips to improve your overall health."
    },
    {
      img: asset11,
      "name": "Demo Workout Sessions",
      "description": "Our ACSM certified coaches assess various aspects of your fitness. Strength, Flexibility, Mobility & the seriousness of your injuries (if any)."
    },
    {
      img: asset10,
      "name": "Movement Analysis",
      "description": "What exercises are you capable of doing."
    },
    {
      img: asset7,
      "name": "Exercise Programming",
      "description": "Roadmap on how you can achieve your fitness goals."
    }
  ]
  return (

    <div style={{
      marginBottom: '50px', paddingTop: '80px',
      backgroundImage: `url(${backgroundAsset2})`,
      backgroundSize: '77.438 rem',
      backgroundRepeat: 'no-repeat'
    }}>

      <div className='odc-top' style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '40px'
      }}>
        <h3 className='font-2 odc-font-heading' style={{ fontSize:'2.5vw',color: '#CC203B', fontWeight: 550 ,textAlign:'center'}}>Here’s How We’re Different Than All Other Fitness & </h3>
        <h3 className='font-2 odc-font-heading2' style={{fontSize:'2.5vw', color: '#CC203B', fontWeight: 550,textAlign:'center' }}>Health Tech Companies…</h3>
        <br />
        <p className='font-1 odc-subText' style={{  fontSize: '1.3vw',marginBottom:'0.5rem',textAlign:'center' }}>We keep health at the center of everything - workouts and diets are backed by science <br />
        & technology. Here's what I mean by this... </p>
        <p className='font-1' style={{ fontSize: '1.3vw' ,textAlign:'center'}}></p>
      </div>
      <>
      {
      (!isMobile) ? <NewDiff details={ourDiff}/>:
      <div className='difference-main-div'>

      <div className='odc-midtxt odc-turn'>
        <h2 className='font-1 aligntext' style={{ color: '#CC203B', fontWeight: 500 }}>When you go to the Gym</h2>
        <p id='best-coach' className='font-1 aligntext' style={{ marginTop: '20px', fontWeight: 'bold', height: '135px', fontSize: '1rem', borderBottom: '1px solid #CC203B' }}>(We're talking about some of the best coaches in India, these are not your nearby gym trainers - their assessments won't even include all this)</p>
        <DifferenceComponent details={gymDiff} />
      </div>

      <div className='odc-midtxt odc-turn'>
        <h2 id = 'us-title' className='font-1 aligntext' style={{fontWeight:500}}>When you train with Us</h2>
        <DifferenceComponent details={ourDiff} />
      </div>

    </div>
       }
      </>

     

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '80px'
      }}>
         <Link to="/freeHealthReport">
         <button className='odc-btn font-1' style={{
          border: '2px',
          borderColor: '#CC203B',
          borderStyle: 'solid',
          borderRadius: '50px',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginTop: '20px',
          color: '#CC203B',
          fontWeight: 'bold',
          fontSize: '18px'
        }}>
          GET YOUR FREE HEALTH REPORT
        </button>
         </Link>
        
      </div>

    </div>

  );
}

export default OurDifferenceComponent;