import React from 'react'
import followUps from '../../images/CorporateWorking/followUps.jpg'
import { useEffect, useState } from 'react'
import './CorporateWorking.css'
import '../../App.css'
import { Link } from "react-router-dom";
import updateClickCount from "../../network/ClickCounterApis/UpdateClickCounter";

import AOS from 'aos';
import 'aos/dist/aos.css';


const CorporateWorking = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 500});
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);  
  
  const updateClick = async () => {
    await updateClickCount("HealthReportButton");
  };

  return (
    (!isMobile) 
    ? <div className="workingContainer" style={{background:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporatePage/CorporateWorkingAsset.svg)`,backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
        <div className="titleWorking font-2" style={{textAlign:'center',marginTop:'5vh',color: '#CC203B', fontWeight: 500, fontSize:'2rem' }}>Here's How It Works...</div>
        <div className="workingBoxes">
            <div data-aos = "fade-up" data-aos-easing="linear" className="innerLeftBox" style={{height:'20%',margin:'2vw',display:'flex',marginLeft:'15vw', marginRight:'15vw',justifyContent:'space-evenly',marginBottom:'10vh'}}>
                <div className="workingImg" style={{background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateWorking/bloodTest.jpeg)`,height:'261px',
                  width:'47%',
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center',backgroundPositionY:'40%'}}></div>
                <div className="workingDesc font-1" style={{width:'40%',alignContent:'center',textAlign:'justify'}}>
                    <div className="descTitle font-1" style={{fontWeight:'bold',fontSize:'1.8vw',marginBottom:'1vw'}}>Blood Tests</div>
                    <div className="descText">Our state of the art diagnostic tests encompass 88+ parameters including B12, D3, Lipid Profile, Diabetes, Insulin, Liver Profile and a lot more, providing you with a comprehensive overview of your current health status.</div>
                </div>
            </div>
            <div data-aos = "fade-up" data-aos-easing="linear" className="innerRightBox" style={{height:'20%',margin:'2vw',display:'flex',marginLeft:'15vw', marginRight:'15vw',justifyContent:'space-evenly',marginTop:'5vh',marginBottom:'10vh'}}>
            <div className="workingDesc font-1" style={{width:'40%',alignContent:'center',textAlign:'justify'}}>
                    <div className="descTitle" style={{fontWeight:'bold',fontSize:'1.8vw',marginBottom:'1vw'}}>Smart Health Reports</div>
                    <div className="descText">We don't stop at just gathering data. Our Bio Al platform processes your test results, offering a deep dive into the meaning behind the numbers. These reports suggest based on today's lifestyle, what are the chances of an individual having lifestyle based diseases in the future. This will not just help an individual with disease prevention, but also the organization in drafting their insurance policies.</div>
                </div>
                <div className="workingImg" style={{background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateWorking/smartHealthReport.jpeg)`,height:'261px',
                  width:'47%',
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center'}}></div>
            </div>
            <div data-aos = "fade-up" data-aos-easing="linear" className="innerLeftBox" style={{height:'20%',margin:'2vw',display:'flex',marginLeft:'15vw', marginRight:'15vw',justifyContent:'space-evenly',marginTop:'5vh',marginBottom:'10vh'}}>
                <div className="workingImg" style={{background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateWorking/liveInteraction.jpeg)`,height:'261px',
                  width:'47%',
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center'}}></div>
                <div className="workingDesc font-1" style={{width:'40%',alignContent:'center',textAlign:'justify'}}>
                    <div className="descTitle" style={{fontWeight:'bold',fontSize:'1.8vw',marginBottom:'1vw'}}>Nutrition Consultation & LIVE Interactive Workouts</div>
                    <div className="descText">Our nutrition consulting and workouts are designed keeping Smart Health Reports at the center of decision making. They'll be designed to keep the employees healthy inside out in turn enhancing an employee's performance + health.</div>
                </div>
            </div>
            <div data-aos = "fade-up" data-aos-easing="linear" className="innerRightBox" style={{height:'20%',margin:'2vw',display:'flex',marginLeft:'15vw', marginRight:'15vw',justifyContent:'space-evenly', marginTop:'5vh'}}>
            <div className="workingDesc font-1" style={{width:'40%',alignContent:'center',textAlign:'justify'}}>
            <div className="descTitle font-1" style={{fontWeight:'bold',fontSize:'1.8vw',marginBottom:'1vw'}}>Monthly Follow-ups</div>
                    <div className="descText font-1">Our team will follow up with you monthly to keep you accountable for your personal fitness goals + make any necessary changes required for your Nutrition or Workout needs.</div>
                </div>
                <div className="workingImg" style={{background: `url(${followUps})`,height:'261px',
                  width:'47%',
                  backgroundSize: 'cover',
                  backgroundRepeat:'no-repeat', 
                  backgroundPosition: '-25px -175px'}} ></div>
            </div>
        </div>
        <div className='workingBtn' style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '40px',
        paddingBottom: '5rem'
      }}>
         <Link to="/freeHealthReport">
         <button className='font-1 cib-btn' style={{
          border: '1.75px',
          borderColor: '#CC203B',
          borderStyle: 'solid',
          borderRadius: '50px',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginTop: '20px',
          color: '#CC203B',
          fontWeight: '600',
          fontSize: '18px'
        }}>
          GET YOUR FREE HEALTH REPORT
        </button>
         </Link>
        
      </div>
    </div>
    : 
    <div >
      <div>
        <div className="titleWorking font-2" style={{textAlign:'center',marginTop:'4rem',color: '#CC203B', fontWeight: 500, fontSize:'1.8rem' }}>Here's How It Works...</div>
      
        <div className='m-cw-main'>
          
          <div data-aos = "fade-up" data-aos-easing="linear" className="m-cw-tile">
            <div className="m-cw-img" style={{
              background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateWorking/bloodTest.jpeg)`,height:'12rem',
              width:'77%',
              backgroundSize: 'cover', 
              backgroundPositionY:'35%',
              backgroundRepeat:'no-repeat'}}>
            </div>
            <p className='m-cw-tile-title font-1'>Blood Tests</p>
            <p className='m-cw-tile-desc font-1'>Our state-of-the-art diagnostic tests encompass 88+ parameters including B12, D3, Lipid Profile, Diabetes, Insulin, Liver Profile and a lot more, providing you with a comprehensive overview of your current health status. 
            </p>
            <div className='m-cw-tile-line'/>
          </div>

          <div data-aos = "fade-up" data-aos-easing="linear" className="m-cw-tile">
            <div className="m-cw-img" style={{
              background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateWorking/smartHealthReport.jpeg)`,height:'12rem',
              width:'77%',
              backgroundSize: 'contain', 
              backgroundPosition: 'center',
              backgroundRepeat:'no-repeat'}}>
            </div>
            <p className='m-cw-tile-title font-1'>Smart Health Reports</p>
            <p className='m-cw-tile-desc font-1'>
            We don't stop at just gathering data. Our Bio AI platform processes your test results, offering a deep dive into the meaning behind the numbers. These reports suggest based on today’s lifestyle, what are the chances of an individual having lifestyle based diseases in the future. This will not just help an individual with disease prevention, but also the organization in drafting their insurance policies.             </p>
            <div className='m-cw-tile-line'/>
          </div>

          <div data-aos = "fade-up" data-aos-easing="linear" className="m-cw-tile">
            <div className="m-cw-img" style={{
              background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateWorking/liveInteraction.jpeg)`,height:'12rem',
              width:'77%',
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat:'no-repeat'}}>
            </div>
            <p className='m-cw-tile-title font-1'>Nutrition Consultation & LIVE Interactive Workouts</p>
            <p className='m-cw-tile-desc font-1'>
            Our nutrition consulting & workouts are designed keeping Smart Health Reports at the center of decision making. They’ll be designed to keep the employees healthy inside out in turn enhancing an employee's performance + health.            </p>
            <div className='m-cw-tile-line'/>
          </div>

          <div data-aos = "fade-up" data-aos-easing="linear" className="m-cw-tile">
            <div className="m-cw-img" style={{
              background: `url(${followUps})`,height:'12rem',
              width:'77%',
              backgroundSize: 'cover', 
              backgroundPositionY:'84%',
              backgroundRepeat:'no-repeat'}}>
            </div>
            <p className='m-cw-tile-title font-1'>Monthly Follow-ups</p>
            <p className='m-cw-tile-desc font-1'>
            Our team will follow up with you monthly to keep you accountable for your personal fitness goals + make any necessary changes required for your Nutrition or Workout needs.            </p>
          </div>
          
          <Link data-aos="flip-down" to="/freeHealthReport">
          <button
            onClick={updateClick}
            style={{
              border: "2px",
              borderColor: "#CC203B",
              borderStyle: "solid",
              borderRadius: "50px",
              paddingTop: "0.8rem",
              paddingBottom:'0.6rem',
              paddingLeft: "1rem",
              paddingRight: "1rem",
              marginTop: "1.5rem",
              marginBottom:'3.5rem',
              color: "#CC203B",
              fontSize: "1rem",
              fontWeight: 550,
            }}
            className="font-1 cib-btn" >
            GET FREE HEALTH REPORT
          </button>
        </Link>

        </div>
      </div>
    </div>  
  )
}

export default CorporateWorking