import React from "react";
import { useState, useEffect } from "react";
import './AboutChart.css'
import chartBg from '../../../images/AboutUs/Web/AboutChart.svg'
import chart from '../../../images/AboutUs/Mobile/chartMobile2.svg'

const AboutChart = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <>
    {!isMobile ? <div>
      <div className="ac-container">
        <div className="ac-chart">
        <img src={chartBg} alt="Description of image" />
        </div>
        <div className="ac-text">
          <div className="ac-text-headline font-1">Transforming health <br /> for <span className="font-2">Millions</span> </div>
          <div className="ac-text-desc">Our goal over the next 5 years is to make 1 Crore people in India healthier by reducing their disease risk scores using our Biological AI technology and lifestyle changes easily adaptable by a common man at a cost affordable by most people.</div>
        </div>
      </div>

    </div>:(
        <div style={{height:'auto'}}>
          <div style={{height:'auto',width:'100%', display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'start',paddingTop:'4rem'}}>
          <h1 className="font-1 m-ac-banner-heading2" >Transforming health for</h1>
          <h1 className="font-2 m-ac-banner-heading1" >Millions</h1>
          <div className='font-1 midPhrase' style={{ width: "90%",fontSize:'1.2rem' }}>
          <p style={{paddingTop:'4%',paddingBottom:'7%'}}>
          Our goal over the next 5 years is to make 1 Crore people in India healthier by reducing their disease risk scores using our Biological AI technology and lifestyle changes easily adaptable by a common man at a cost affordable by most people.
              </p>
          </div>

          </div>
          <img src={chart} alt="" style={{width:'100%'}} />


        </div>
    )}
    </>
  );
};

export default AboutChart;
