import React from 'react'
import MapComponent from '../components/MapComponent/MapComponent';
import ContactIntroBox from '../components/ContactIntroBox/ContactIntroBox';
import Footer from '../components/footer/Footer';
import ContactInputBox from '../components/ContactInputBox/ContactInputBox';

const ContactPage = () => {
  return (
    <div>
        <ContactIntroBox/>
        <ContactInputBox/>
        <MapComponent/>
        <Footer/>
    </div>
  )
}

export default ContactPage;