import React from 'react'
import TechBanner from '../components/TechComponents/TechBanner/TechBanner.jsx'
import TechJoinProgram from '../components/TechComponents/TechJoinProgram/TechJoinProgram.jsx'
import TechTable from '../components/TechComponents/TechComparisonTable/TechTable.jsx'
import TechMetSights from '../components/TechComponents/TechMetSights/TechMetSights.jsx'
import Footer from '../components/footer/Footer.jsx'
import TechBheindAI from '../components/TechComponents/TechBehindAI/TechBheindAI'
import WhyTestedComponent from '../components/TechComponents/WhyTestedComponent/WhyTestedComponent'
import TechMetabolically from '../components/TechComponents/TechMetabolically/TechMetabolically.jsx'
import TechDetails from '../components/TechComponents/TechDetails/TechDetails.jsx'

const TechPage = () => {
  return (
    <>
      <TechBanner />
      <TechBheindAI />
      {/* <TechMetabolically /> */}
       <WhyTestedComponent />
       <TechDetails />
      <TechMetSights /> 
      <TechTable />
      <TechJoinProgram />
      <Footer />
    </>
  )
}

export default TechPage