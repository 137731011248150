import React from "react";
import CorporateComparison from "../CorporateComparison/CorporateComparison";


function BrandsTableSideComponent(){
    return (
        <div style={{width:'100%' ,display:'flex',alignItems:'start',
        justifyContent:'center',marginTop:'5%',marginBottom:'5%'}}>
           <div style={{width:'40%'  ,display:'flex',flexDirection:'column',alignItems:'start',
        justifyContent:'center'}}>
            <p style={{fontSize:'2.3rem',lineHeight:'1.2' ,color:'rgb(204, 32, 59)',marginLeft:'30%',marginRight:'10%',fontWeight:'495'}}>Try Fitnastic Today & Witness These<span className="font-2"> Awesome Results For Your Organisation</span></p>
            <button 
                    style={{
                    border: '1.5px', 
                    borderColor:'rgb(204, 32, 59)', 
                    borderStyle: 'solid', 
                    borderRadius: '50px', 
                    paddingTop:'0.8rem', 
                    paddingBottom:'0.7rem', 
                    paddingLeft:'1.6rem', 
                    paddingRight:'1.6rem',
                    color:'rgb(204, 32, 59)',
                    fontSize:'1.2rem',
                    marginTop:'3vw',
                    fontWeight: 600,
                    marginLeft:'30%',
                    marginRight:'10%'
                    }}
                    className='font-1'
                    >
                    GET IN TOUCH           
                </button>

         
        </div> 
        <div style={{width:'55%', height:'auto',marginRight:'10%'}}>
                    <CorporateComparison/>
        </div>
        </div>
        
        );}
        export default BrandsTableSideComponent; 