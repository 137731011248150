import React from 'react'
import { useState, useEffect } from 'react';
import './AboutVision.css'
import ourVision from '../../../images/AboutUs/Web/OurVision.svg'
import ourProcess from '../../../images/AboutUs/Web/OurProcess.svg'
import ourAchievement from '../../../images/AboutUs/Web/OurAchievement.svg'


const AboutVision = () => {
  const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };
  
      window.addEventListener("resize", handleResize);
      handleResize();
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
  
    return (
      <>
      {!isMobile ? <div>
        <div className="av-container">
          <div className="av-box">
            <div className="av-box-logo">
            <img src={ourVision} alt="Description of image" />
            </div>
            <div className="av-box-headline font-2">Our Vision</div>
            <div className="av-box-desc font-1">Beyond promoting personal wellbeing, our goal is to be a catalyst for positive change rather than just a leader in health technology, and this dedication to enhancing lives is consistent with that goal.</div>
          </div>
          <div className="av-box">
            <div className="av-box-logo">
            <img src={ourProcess} alt="Description of image" />
            </div>
            <div className="av-box-headline font-2">Our Process</div>
            <div className="av-box-desc font-1">We go beyond conventional weight loss programs, focusing on body transformation and lifestyle management. Whether you're aiming for fat loss, overcoming obesity, increasing longevity or managing diseases like hypothyroidism, diabetes -
            our comprehensive solutions cater to every aspect of your health.</div>
          </div>
          <div className="av-box">
            <div className="av-box-logo">
            <img src={ourAchievement} alt="Description of image" />
            </div>
            <div className="av-box-headline font-2">Our Achievements</div>
            <div className="av-box-desc font-1">Our Biological AI technology is backed by
            IIT Bombay Research. We can proudly say that we’ve been acknowledged by the Indian Government under the Startup India program as well.
            <br />
            <p style={{marginTop:"0.6rem"}}>
            We’ve also been featured in Daily Hunt, Inc 91, Hindustan Times & Entrepreneur Hunt as one of the best up & coming start-ups in 2023.
            </p>
            </div>
          </div>
        </div>
      </div>:(
          <div style={{ height: "auto" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              paddingTop: "3.5rem",
              gap: "0.6rem",
            }}
          >
            <img src={ourVision} alt="" />
            <h1 className="font-2 m-av-banner-heading1">Our Vision</h1>
            <div
              className="font-1 midPhrase"
              style={{
                width: "90%",
                fontSize: "1.2rem",
                borderBottomWidth: "1px",
                borderBottomColor: "#CC203B",
                paddingBottom: "1.5rem",
                marginTop: "-0.6rem",
              }}
            >
              <p>
                Beyond promoting personal wellbeing, our goal is to be a
                catalyst for positive change rather than just a leader in health
                technology, and this dedication to enhancing lives is consistent
                with that goal.
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              paddingTop: "4rem",
              gap: "0.6rem",
            }}
          >
            <img src={ourProcess} alt="" />
            <h1 className="font-2 m-av-banner-heading1">Our Process</h1>
            <div
              className="font-1 midPhrase"
              style={{
                width: "90%",
                fontSize: "1.2rem",
                borderBottomWidth: "1px",
                borderBottomColor: "#CC203B",
                paddingBottom: "1.5rem",
                marginTop: "-0.6rem",
              }}
            >
              <p>
                We go beyond conventional weight loss programs, focusing on body
                transformation and lifestyle management. Whether you're aiming
                for fat loss, overcoming obesity, increasing longevity or
                managing diseases like hypothyroidism, diabetes - our
                comprehensive solutions cater to every aspect of your health.
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              paddingTop: "4rem",
              gap: "0.6rem",
            }}
          >
            <img src={ourAchievement} alt="" />
            <h1 className="font-2 m-av-banner-heading1">Our Achievements</h1>
            <div
              className="font-1 midPhrase"
              style={{
                width: "90%",
                fontSize: "1.2rem",
                paddingBottom: "1.5rem",
                marginTop: "-0.6rem",
              }}
            >
              <p>
                Our Biological AI technology is backed by IIT Bombay Research.
                We can proudly say that we’ve been acknowledged by the Indian
                Government under the Startup India program as well.
                <br />
                <br />
                We’ve also been featured in Daily Hunt, Inc 91, Hindustan Times
                & Entrepreneur Hunt as one of the best up & coming start-ups in
                2023.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AboutVision;
