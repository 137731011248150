import React from "react";
import { useState,useEffect } from "react";



function MapComponent() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
 

  return (
    <>
    {!isMobile ?   <div style={{ height: 'auto', width: '100%' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.0991972348284!2d72.83659879999999!3d19.103303699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9b8676487ef%3A0x2c4fac1c801d5128!2sNMIMS%20Deemed-to-be-University!5e0!3m2!1sen!2sin!4v1720529842188!5m2!1sen!2sin" width="600" height="450" style={{width:'100%',height:'60vh'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div> :(
         <div style={{ height: 'auto', width: '100%' }}>
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.0991972348284!2d72.83659879999999!3d19.103303699999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9b8676487ef%3A0x2c4fac1c801d5128!2sNMIMS%20Deemed-to-be-University!5e0!3m2!1sen!2sin!4v1720529842188!5m2!1sen!2sin" width="600" height="450" style={{width:'100%',height:'45vh'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
 </div>
    ) }
         
    </>
  );
}

export default MapComponent;