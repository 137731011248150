import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './GridCarousel.css'; // Import your custom CSS for styling if needed
// import asianWomen from '../../images/programCrousel/asianwomen.jpg'

import { useState,useEffect } from 'react';

const GridCarousel = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2300,
    arrows:false,
    pauseOnHover: true,
    appendDots: dots => {
      return <ul style={{ margin: '0px', height:'2%' }}>{dots}</ul>;
    }
  };
  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1900,
    arrows:false,
    pauseOnHover: true,
    appendDots: dots => {
      return <ul style={{ margin: '0px', height:'2%' }}>{dots}</ul>;
    }
  };

  return (
    <>
    {!isMobile ? <div >
   <h3 className="crouselHeading font-2" style={{textAlign:'center', marginBottom:'1.5vw', fontWeight:'550', color:'#CC203B', marginTop:'6vw',fontSize:'2.2rem'}}>Our Bio AI Health Camp</h3>
    <Slider {...settings}>
          <div className="gc-carousel-slide">
           <div className="grid-container">
            <div className="big-rectangular">
            <div className="big-rectangular-first">
            <div className="small-square" style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img1.jpeg)`,backgroundSize:'cover'}}></div>
            <div className="small-rectangular" style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img2.jpeg)`,backgroundSize:'cover',backgroundPositionY:'100%'}}></div>
            </div>
            <div className="big-rectangular-second">
            <div className="small-rectangular"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img3.jpeg)`,backgroundSize:'cover',backgroundPositionY:'15%'}}></div>
            <div  className="small-square"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img4.jpeg)`,backgroundSize:'contain'}}></div>
        </div>
        </div>
        </div>
        </div>
        <div className="gc-carousel-slide">
        <div className="grid-container">
            <div className="middle-rectanguler">
            <div className="mid-big-rectangular-first">
            <div className="mid-small-rectangular"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img5.png)`,backgroundSize:'cover'}}></div>
            <div className='mid-end-div'>
            <div className="mid-small-square-one"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img7.jpeg)`,backgroundSize:'cover'}}></div>
            <div className="mid-small-square"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img8.jpeg)`,backgroundSize:'cover'}}></div>
            </div>
            </div>
            <div className="mid-big-rectangular-second">
            <div className="mid-small-rectangular-two"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img6.jpeg)`,backgroundSize:'contain'}}></div>
        </div>
        </div>
        </div>
        </div>
        <div className="gc-carousel-slide">
        <div className="grid-container">
            <div className="big-rectangular">
            <div className="big-rectangular-first">
            <div className="small-rectangular"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img9.jpeg)`,backgroundSize:'cover',backgroundPositionY:'40%'}}></div>
            <div className="small-square"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img10.jpeg)`,backgroundSize:'cover'}}></div>
            </div>
            <div className="big-rectangular-second">
            <div className="small-rectangular"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img11.jpeg)`,backgroundSize:'100%',backgroundPositionY:'100%'}}></div>
            <div  className="small-square"style={{ background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img12.jpeg)`,backgroundSize:'cover',
          backgroundPositionX:'25%'}}></div>
            
        </div>
        </div>
        </div>
        </div>
    </Slider>
         
    </div>:(
      <div style={{width:'100%',paddingTop:'12%',paddingBottom:'10%'}}>
        <h1 className="font-2 m-grid-heading1">Our Bio AI Health Camp</h1>
        <Slider {...settingsMobile}>
          <div className="m-carousel-slide">
           <div className='m-grid-container'>
            <div className='m-top-layer' style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img2.jpeg)`,backgroundSize:'cover',backgroundPositionY:'100%'}}>
               
            </div>
            <div className='m-mid-layer'>
              <div className='m-mid-layer-small'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img1.jpeg)`,backgroundSize:'cover',backgroundPositionY:'30%'}}>

              </div>
              <div className='m-mid-layer-small'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img4.jpeg)`,backgroundSize:'200%',backgroundPositionY:'75%',backgroundPositionX:'50%'}}>

              </div>

            </div>
            <div className='m-top-layer' style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img3.jpeg)`,backgroundSize:'cover',backgroundPositionY:'18%'}}>

            </div>
            

           </div>

          </div>
          <div className="m-carousel-slide">
          <div className='m-grid-container'>
            <div className='m-top-layer' style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img5.png)`,backgroundSize:'cover',backgroundPositionY:'20%'}}>

            </div>
            <div className='m-mid-layer'>
              <div className='m-mid-layer-small'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img7.jpeg)`,backgroundSize:'cover',backgroundPositionY:'40%'}}>

              </div>
              <div className='m-mid-layer-small'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img6.jpeg)`,backgroundSize:'cover',backgroundPositionY:'50%'}}>

              </div>

            </div>
            <div className='m-top-layer'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img8.jpeg)`,backgroundSize:'cover',backgroundPositionY:'85%'}}>

            </div>
            

           </div>
          </div>
          <div className="m-carousel-slide">
          <div className='m-grid-container'>
            <div className='m-top-layer'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img9.jpeg)`,backgroundSize:'cover',backgroundPositionY:'50%'}}>

            </div>
            <div className='m-mid-layer'>
              <div className='m-mid-layer-small'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img10.jpeg)`,backgroundSize:'cover',backgroundPositionY:'45%'}}>

              </div>
              <div className='m-mid-layer-small'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img12.jpeg)`,backgroundSize:'cover',backgroundPositionY:'45%'}}>

              </div>

            </div>
            <div className='m-top-layer'style={{backgroundImage:`url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/CorporateGrid/img11.jpeg)`,backgroundSize:'cover',backgroundPositionY:'100%'}}>

            </div>
            

           </div>
          </div>
          
          </Slider>

      </div>
    )}
    </>
    
  );
};

export default GridCarousel;
