import React from 'react'
import EntryQuizAnim from '../components/EntryQuizAnimation/EntryQuizAnim'
import EntryQuizComponent from '../components/EntryQuizComponent/EntryQuizComponent'
import EntryQuizComponentMobile from '../components/MobileQuiz/TakeQuiz/TakeQuiz'
import { useState, useEffect } from "react";

const EntryQuizPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1030);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    {!isMobile ?(
     <div style={{height:'100vh', width:'100%'}}>
     <EntryQuizComponent style={{height:'100%' ,width:'100%'}}/>
     </div>
    ):
    <EntryQuizComponentMobile/>
    }
    </>
    
  )
}

export default EntryQuizPage