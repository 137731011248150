import axios from "axios";

async function updateClickCount(featureName) {
  try {
    const response = await axios.post(
      "http://192.168.1.22:8080/api/v2/feature/update-count",
      null, // No request body
      { params: { featureName: "HealthReportButton" } }
    );

    // Check if response status is 200 (OK)
    if (response.status === 200) {
      // Log the message received from the response
      console.log(response.data.result);
      // Return the result object from the response
      return response.data.result;
    } else {
      // Log unexpected status code
      console.error("Unexpected status code:", response.status);
      // Return null in case of unexpected status code
      return null;
    }
  } catch (error) {
    // Handle axios errors
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error("Server responded with error status:", error.response.status);
      console.error("Error message:", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received from the server:", error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Error setting up the request:", error.message);
    }
    // Return null or handle the error in some way
    return null;
  }
}

export default updateClickCount;
