import React from 'react';
import logo from '../../images/logo.svg'
import './footer.css'
import { useState, useEffect } from "react";
import NewFooter from '../NewFooter/NewFooter';
import insta from '../../images/footer-insta.png'
import linkdin from '../../images/footer-linkdin.png'
import { Link } from 'react-router-dom';



function Footer() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); 

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      {!isMobile ? (

        <NewFooter/>

      ) : (
        <div>
         <div
          style={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            padding: "4%",
          }}
        >
          <img
            src={logo}
            alt=""
            style={{ height: "2.3rem", alignSelf: "start" }}
          />
          <div
            style={{
              display:'flex',
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "12%",
              paddingLeft: "6%",
              paddingRight: "5%",
            }}
          >
            <Link
              className="font-1"
              to="/about"
              onClick={scrollToTop}
              style={{
                fontSize: "4vw",
                textDecoration: "none",
                fontWeight: "600",
                color:'#063533'
              }}
            >
              ABOUT US
            </Link>
            <Link
              className="font-1"
              // href=""
              to="/programs"
              onClick={scrollToTop}
              style={{
                // visibility:'hidden',
                fontSize: "4vw",
                textDecoration: "none",
                fontWeight: "600",
                color:'#063533'
              }}
            >
              PROGRAMS
            </Link>
            <Link
              className="font-1"
              to="/ourTech"
              onClick={scrollToTop}
              style={{
                // visibility:'hidden',
                fontSize: "4vw",
                textDecoration: "none",
                fontWeight: "600",
                color:'#063533'
              }}
            >
              OUR TECH
            </Link>
          </div>
          <Link
            className="font-1"
            to="/contact"
            onClick={scrollToTop}
            style={{
              // display:'none',
              fontSize: "4vw",
              textDecoration: "none",
              fontWeight: "600",
              paddingLeft: "6%",
              paddingRight: "5%",
              marginTop: "5%",
              color:'#063533'
            }}
          >
            GET IN TOUCH
          </Link>
          <div
            style={{ paddingLeft: "6%", paddingRight: "5%", marginTop: "7%",display:'flex',justifyContent:'start',alignItems:'center' }}
          >
            {}
            <p className='font-1' style={{fontSize:'4vw',fontWeight:'500',marginBottom:'0rem',color:'#063533',fontWeight:'600'}}>
               FOLLOW US
            </p>
           <a href="https://www.instagram.com/fitnastic.health/" target="_blank" rel="noreferrer" style={{marginLeft:'4%'}}>
           <img src={insta} alt="" style={{width:'1.7rem'}} />
           </a> 
           <a href="https://in.linkedin.com/company/fitnasticindia" target="_blank" rel="noreferrer" style={{marginLeft:'4%'}}>
           <img src={linkdin} alt="" style={{width:'1.7rem'}}/>
           </a>
          </div>
        </div>
        <div style={{width:'100%',borderWidth:'2px',borderColor:'#E6E6E6',marginTop:'1rem',display:'flex',justifyContent:'center',alignItems:'center'}}>
          <p className="font-1" style={{fontSize:'3vw',marginBottom:'0rem',marginTop:'2%',marginBottom:'2.5%',color:'#063533',fontWeight:'700'}}>Copyright © Fitnastic Health PVT. LTD.</p>


          </div>
        </div>
       
      )}
    </>
  );
}

export default Footer;