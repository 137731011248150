import React from 'react'
import NavBar from '../components/NavbarBox/NavBar'
import TestimonialTest from '../components/Testimonial/Testimonial'
import Footer from '../components/footer/Footer'
import CorporateIntroBox from '../components/CorporateIntroBox/CorporateIntroBox'
import CorporateAiIntro from '../components/CorporateAiIntro/CorporateAiIntro'
import CorporateWorking from '../components/CorporateWorking/CorporateWorking'
import CorporateCrousel from '../components/CorporateCrousel/CorporateCrousel'
import FitToday from '../components/FitToday/FitToday'
import GridCarousel from '../components/GridCarousel/GridCarousel'
import BrandsTable from '../components/BrandsTable/BrandsTable'
import CorporateWellnessProgram from '../components/CorporateWellnessProgram/CorporateWellnessProgram'
import CorporateComparison from '../components/CorporateComparison/CorporateComparison'
import TechTable from '../components/TechComponents/TechComparisonTable/TechTable'
import BrandsTableSideComponent from '../components/BrandTableSideComponent/BrandTableSideComponent'

const CorporatePage = () => {
  return (
    <div>
        
        <CorporateIntroBox/>
        <GridCarousel/>
        <CorporateWellnessProgram/> 
        {/* <BrandsTable/> */}
        
         <CorporateAiIntro/>
        <CorporateWorking/>
        <CorporateCrousel/> 
        {/* <CorporateComparison/> */}
        <TechTable/>
        <TestimonialTest/>
        <FitToday/> 
        <Footer/>
    </div>
  )
}
 
export default CorporatePage