import React from "react";
import "./CorporateWellnessProgram.css";
import { useState, useEffect } from "react";

const CorporateWellnessProgram = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile ? (
        <div className="cwp-main">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              className="font-1"
              style={{
                textAlign: "center",
                fontSize: "3vw",
                color: "rgb(204, 32, 59)",
                marginBottom: 0,
                marginTop: "4vw",
                lineHeight: "90%",
              }}
            >
              Why Do Companies Need
            </p>

            <p
              style={{
                textAlign: "center",
                fontSize: "3.2vw",
                fontWeight: 580,
                color: "rgb(204, 32, 59)",
                marginBottom: 0,
              }}
              className="font-2"
            >
              Employee Wellness Programs?
            </p>

            <p
              className="font-1"
              style={{
                textAlign: "center",
                marginLeft: "17vw",
                marginRight: "17vw",
                marginTop: "5vh",
                fontWeight: 400,
                fontSize: "1.3vw",
              }}
            >
              Let's face it - you offer your employees tons of different
              benefits to keep them happy, and rightly so. After all, employees
              are the ones who play an integral part with each role to grow the
              business.
            </p>

            <p
              className="font-1"
              style={{
                textAlign: "center",
                marginLeft: "17.5vw",
                marginRight: "17.5vw",
                marginTop: "1.25vh",
                fontWeight: 400,
                fontSize: "1.3vw",
              }}
            >
              But, you know as well as anyone that when you give employees
              benefits like{" "}
              <span
                className="font-1"
                style={{ fontWeight: "775", fontSize: "1.3vw" }}
              >
                bonuses, blood tests/health check-ups, free food, discounts or
                even salary hikes
              </span>{" "}
              - you only offer them short term incentives.
            </p>

            <p
              className="font-1"
              style={{
                textAlign: "center",
                marginLeft: "16vw",
                marginRight: "16vw",
                marginTop: "3vh",
                fontSize: "1.3vw",
                fontWeight: 400,
              }}
            >
              {" "}
              <span>
                These benefits are very important for companies for sure, but
                these benefit the employees more than the companies. That's one
              </span>
              <span className="p-2">
                of the{" "}
                <span
                  className="font-1"
                  style={{ fontWeight: "775", fontSize: "1.3vw" }}
                >
                  BIGGEST
                </span>{" "}
                reasons why as a company, it's important to invest in{" "}
                <span
                  className="font-1"
                  style={{ fontWeight: "775", fontSize: "1.3vw" }}
                >
                  Employee Wellness Programs
                </span>{" "}
                - where an
              </span>
              <span>
                employee's wellbeing not just helps the employees, but also the
                company.
              </span>{" "}
            </p>
          </div>

          <div
            className="cwp-red-line"
            style={{
              marginLeft: "10vw",
              marginRight: "10vw",
              marginTop: "8vh",
            }}
          ></div>

          <div className="cwp-lower">
            <div className="cwp-lower-left">
              <p
                style={{
                  fontSize: "3vw",
                  color: "rgb(204, 32, 59)",
                  marginBottom: "0rem",
                  lineHeight: "120%",
                  marginTop:'1.1rem'
                }}
              >
                <span>Benefits Of</span>
              </p>
              <p
                className="font-2"
                style={{
                  fontSize: "3vw",
                  fontWeight: "400",
                  color: "rgb(204, 32, 59)",
                  marginBottom: "0rem",
                  lineHeight: "100%",
                }}
              >
                <span>Employee Wellness</span>
              </p>
              <p
                className="font-2"
                style={{
                  fontSize: "3vw",
                  fontWeight: "400",
                  color: "rgb(204, 32, 59)",
                  marginBottom: "0rem",
                  lineHeight: "140%",
                }}
              >
                <span>Programs</span>
              </p>
            </div>

            <div className="cwp-lower-right">
              {/* <div
            style={{
              display: "flex",
              borderBottom: "1px solid rgb(204, 32, 59)",
              paddingBottom: "2vh",
              marginBottom: "3vh",
              alignItems: "end",
            }}
          >
            <p
              className="font-1"
              style={{
                textAlign:'start',
                width: "26vw",
                marginRight: "-0.5vw",
                fontSize: "1.3vw",
                fontWeight: 400,
              }}
            >
              Studies have shown that Wellness Programs can reduce absenteeism
              by an average{" "}
            </p>
              <div className="cwp-outline font-1 cwp-outline-fix">
                25.3%
              </div>
          </div> */}

              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid rgb(204, 32, 59)",
                  alignItems: "center",
                  justifyContent: "start",
                  flexDirection: "row-reverse",
                  paddingBottom:'1vw'
                }}
              >
                <div
                  className="cwp-outline"
                >
                  <span className="font-1" style={{padding:'0', margin:'0'}}>25.3%</span>
                  </div>

                <p
                  style={{
                    width: "28vw",
                    fontSize: "1.3vw",
                    fontWeight: 400,
                    marginRight:'0.6vw',
                    marginBottom:'0px'
                  }}
                >
                  Studies have shown that Wellness Programs can reduce
                  absenteeism by an average
                </p>
              </div>

{/* start card */}

              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid rgb(204, 32, 59)",
                  alignItems: "center",
                  justifyContent: "start",
                  marginTop:'1.3vw',
                  paddingBottom:'1vw'
                }}
              >
                <div
                  className="cwp-outline"
                  style={{ marginRight: "1vw", marginTop:'-0.4vw'}}>
                  <span className="font-1" style={{padding:'0', margin:'0'}}>66%</span>
                  </div>

                <p
                  className="font-1"
                  style={{
                    width: "28vw",
                    fontSize: "1.3vw",
                    fontWeight: 400,
                    marginBottom:'0px'
                  }}>
                  of employees report increased productivity after participating
                  in Employee Wellness Programs
                </p>
              </div>

{/* end card */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop:'1.3vw'
                }}
              >
                <div
                  className="cwp-outline"
                  style={{ }}
                >
                  <span className="font-1" style={{padding:'0', margin:'0'}}>74%</span>
                  </div>

                {/* <img
              src={perc74}
              style={{
                height: "10vh",
                width: "8.5vw",
                marginRight: "1vw",
                marginLeft:'0vw',
                padding:'0.4vw',
                paddingTop:'0vw',
                marginBottom:'1vw',
              }}
            /> */}

                <p
                  style={{
                    width: "28vw",
                    fontSize: "1.3vw",
                    fontWeight: 400,
                    marginLeft:'1.5vw',
                    marginBottom:'0px'
                  }}
                >
                  of businesses with Holistic Wellness Programs report higher
                  employee satisfaction
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: "100%", height: "auto" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-evenly",
              marginTop:'10%'
            }}
          >
            <div style={{padding:'3%'}}>
              <h1 className="font-1 m-wellness-heading2">
                Why Do Companies Need
              </h1>
              <h1 className="font-2 m-wellness-heading1">Employee Wellness</h1>
              <h1 className="font-2 m-wellness-heading1">Programs?</h1>
            </div>
            <div className='font-1 midPhrase' style={{ width: "95%" }}>
              <p>
                Let’s face it - you offer your employees tons of different
                benefits to keep them happy, and rightly so. After all,
                employees are the ones who play an integral part with each role
                to grow the business.
              </p>
              <p>
                But, you know as well as anyone that when you give employees
                benefits like <b>bonuses, blood tests/health check-ups, free food,
                discounts or even salary hikes</b> - you only offer them short term
                incentives.
              </p>

              <p>
                These benefits are very important for companies for sure, but
                these benefit the employees more than the companies. That’s one
                of the <b>BIGGEST</b> reasons why as a company, it’s important to
                invest in <b>Employee Wellness Programs</b> - where an employee's
                wellbeing not just helps the employees, but also the company.
              </p>
            </div>
            <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:'#F3F3F4',paddingTop:'17%',paddingBottom:'5%'}}>
              <div>
              <h1 className="font-1 m-wellness-heading2" style={{marginBottom:'0rem'}}>
              Benefits Of
              </h1>
              <h1 className="font-2 m-wellness-heading1">Employee Wellness</h1>
              <h1 className="font-2 m-wellness-heading1">Programs</h1>
              </div>
              <div className='font-1 midPhrase' style={{ width: "82%",paddingTop:'4%' }}>
              <p>
              Studies have shown that Wellness Programs can reduce absenteeism by an average of <span style={{color:'#CC203B',fontWeight:'700',fontSize:'1.6rem',lineHeight:'1'}}> 25.3%</span> 
              </p>
            </div>
            <div className='font-1 midPhrase' style={{ width: "82%",paddingTop:'4%',borderTopWidth:'1.4px',borderTopColor:'#CC203B' }}>
              <p>
              <span style={{color:'#CC203B',fontWeight:'700',fontSize:'1.6rem',lineHeight:'1'}}> 66%</span> of employees report increased productivity after participating in Employee Wellness Programs
              </p>
            </div>
            <div className='font-1 midPhrase' style={{ width: "82%",paddingTop:'4%',borderTopWidth:'1.4px',borderTopColor:'#CC203B' }}>
              <p>
              <span style={{color:'#CC203B',fontWeight:'700',fontSize:'1.6rem',lineHeight:'1'}}> 74%</span> of businesses with Holistic Wellness Programs report higher employee satisfaction
              </p>
            </div>     
            </div>
          </div>
         
        </div>
      )}
    </>
  );
};

export default CorporateWellnessProgram;
