import React from 'react'
import { useState, useEffect } from 'react';
import './AboutHome.css'
import hopeBg from '../../../images/AboutUs/Web/AboutHope.svg'
import mobileBg from '../../../images/AboutUs/Mobile/AboutHopeMobile.svg'

const AboutHome = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 600);
      };
  
      window.addEventListener("resize", handleResize);
      handleResize();
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
  
    return (
      <>
      {!isMobile ? <div>
        <div className="ah-container" style={{background:`url(${hopeBg}) center center`}}>
          <p className="ah-tagline font-2">“We hope you join us on our mission and together we can <br />make a healthier world”.
          </p>
        </div>
      </div>:(
          <div style={{backgroundImage:`url(${mobileBg})`,width:'100%',height:'auto',backgroundSize:'cover',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',paddingTop:'5rem',paddingBottom:'5rem'}}>
            <h1 className="font-2 m-ab-banner-heading1" style={{lineHeight:'1.2'}}>“We hope you join us <br /> on our mission and <br /> together we can make <br /> a healthier world”.
            </h1>
           
            
          </div>
      )}
      </>
    );
}

export default AboutHome