import React from 'react';
import './TakeQuiz.css';
import bg from '../../../images/quizMobile/bg.svg'
import { BiGame } from 'react-icons/bi';
import MobileQuiz from '../MobileQuizComponent/MobileQuizComponent';
import skipArrow from '../../../images/skipArrow.svg'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
const TakeQuiz = () => {
  const [quizStarted, setQuizStarted] = useState(false);
  const navigate = useNavigate()
  const skipQuiz = () => {
    navigate("/home", {replace: true})
  }

  const startQuiz = () => {
    setTimeout(() => {
      setQuizStarted(true);
    }, 300);
  };

  // Render different components based on state
  if (quizStarted) {
    return <MobileQuiz />;
  } else {
    return (
      <div style={{backgroundImage:`url(${bg})`}} className="tq-take-quiz">
        <div className="tq-content-top">
          <div className='tq-content-heading font-2'>Unlock Your FREE Surprise Gift</div>
          <p className='font-1'>Answer These 3 Simple Questions</p>
        </div>
        <button
          className="tq-take-quiz-btn font-1"
          onClick={startQuiz}
          style={{
            border: "2px",
            borderColor: "#FFFFFF",
            borderStyle: "solid",
            borderRadius: "50px",
            paddingTop: "1rem",
            paddingBottom: "0.7rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginTop: "1.5vw",
            marginBottom:'1vw',
            color: "#FFFFFF",
            fontSize: "2vh",
            fontWeight: 550,
          }}
          
        >
          TAKE THE QUIZ
        </button>
        <div onClick={skipQuiz} style={{ alignItems: 'center', display: 'flex',justifyContent:'end',marginRight:'3%',position:'absolute',bottom:'2%',right:'2%' }}>
         <p className="font-1" style={{ color: 'white', fontSize: '5.5vw', fontStyle: 'italic', marginBottom: '0rem', fontWeight: '200',marginRight:'2%' }}>Skip</p>
         <img src={skipArrow} alt="" style={{ width:'12%' }} />
       </div>
      </div>
    );
  }
}

export default TakeQuiz;
