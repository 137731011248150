import React from 'react'
import AboutIntro from '../components/AboutUs/AboutIntro/AboutIntro'
import AboutChart from '../components/AboutUs/AboutChart/AboutChart'
import AboutVision from '../components/AboutUs/AboutVision/AboutVision'
import AboutHome from '../components/AboutUs/AboutHope/AboutHome'
import Footer from '../components/footer/Footer'

const AboutPage = () => {
  return (
    <>
    <AboutIntro/>
    <AboutChart/>
    <AboutVision/>
    <AboutHome/>
    <Footer/>
    </>
  )
}

export default AboutPage
