import React, { useEffect, useState } from "react";
import quizLogo from "../../images/quizLogo.svg";
import backgroundSvg from "../../images/QuizComponent/quizCompBg.svg";
import nextArrow from "../../images/nextArrow.svg";
import prevArrow from "../../images/previousArrow.svg";
import scoreStand from "../../images/quiz/Asset 18.svg";
import AssetFitPerson from "../../images/quiz/AssetFitPerson.svg";
import AssetMuscular from "../../images/quiz/AssetMuscular.svg";
import "./EntryQuizComponent.css";
import DialogComponent from "./PrizeModal"; 
import PrizeModal2 from "./PrizeModal2/PrizeModal2";
import ThankModal from "./ThankModal/ThankModal";
import Modal from "react-modal";
import skipArrow from "../../images/skipArrow.svg";
import { useNavigate } from "react-router-dom";

const questions = [
  {
    qId: 1,
    qText: "Who's Fitter?",
  },
  {
    qId: 2,
    qText: "Who's Healthier?",
  },
  {
    qId: 3,
    qText: "What Do You Prefer?",
  },
];

const EntryQuizComponent = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [optionSelected, setOptionSelected] = useState([null, null, null]);
  const [showModal, setShowModal] = useState(false);
  const [showThankModal, setShowThankModal] = useState(false);
  const [showModalMuscular, setShowModalMuscular] = useState(false);

  const nextQuestion = () => {
    if (optionSelected[currentQuestion] !== null) {
      if (currentQuestion === 2) {
        const option1Count = optionSelected.filter((opt) => opt === 1).length;
        const option2Count = optionSelected.filter((opt) => opt === 2).length;
        if (option1Count > 2 || option1Count == 2) {
          setShowModal(true);
        }
        if (option2Count > 2 || option2Count == 2) {
          setShowModalMuscular(true);
        }
      } else {
        setCurrentQuestion(currentQuestion + 1);
      }
    }
  };

  const navigate = useNavigate();

  const skipQuiz = () => {
    navigate("/home", { replace: true });
  };
  const closeThankModal=()=>{
    navigate("/home", { replace: true });
  }

  const prevQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const optionSelect = (opId) => {
    const updatedOptions = [...optionSelected];
    updatedOptions[currentQuestion] =
      updatedOptions[currentQuestion] === opId ? null : opId;
    setOptionSelected(updatedOptions);
  };

  useEffect(() => {
    const option1Count = optionSelected.filter((opt) => opt === 1).length;
    const option2Count = optionSelected.filter((opt) => opt === 2).length;
    if (option1Count > 2) {
      setShowModal(true);
    } else if (option2Count > 2) {
      setShowModalMuscular(true);
    } else {
      nextQuestion();
    }
  }, [optionSelected]);

  const closeModal = () => {
    setShowModal(false);
    setShowModalMuscular(false);
    setShowThankModal(true)
  };

  return (
    <div
      className="eqc mat-unselectable"
      style={{
        height: "100%",
        width: "100%",
        backgroundImage: `url(${backgroundSvg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="eqc-top"
        style={{
          height: "15%",
          width: "100%",
          paddingLeft: "2%",
          paddingTop: "2%",
        }}
      >
        <div style={{ height: "100%", width: "30%" }}>
          <img src={quizLogo} alt="" style={{ height: "100%" }} />
        </div>
      </div>

      <div className="eqc-middle" style={{ height: "70%", width: "100%" }}>
        {currentQuestion === 0 && (
          <div
            className="eqc-mid-left"
            style={{
              height: "100%",
              width: "47%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              paddingLeft: "12%",
            }}
          >
            <div style={{ marginTop: "3.5vh" }}>
              <p
                className="font-2"
                style={{
                  color: "white",
                  fontSize: "2rem",
                  lineHeight: "1",
                  paddingTop: "25%",
                }}
              >
                Unlock Your FREE Surprise Gift
              </p>
              <p
                className="font-1"
                style={{ color: "white", fontSize: "1.5rem" }}
              >
                Answer These 3 Simple Questions
              </p>
            </div>

            <div
              style={{
                paddingTop: "8%",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <p
                className="font-1 eqc-smooth-text"
                style={{
                  color: "white",
                  fontSize: "1.4rem",
                  borderRight: "1px solid white",
                  paddingRight: "2.5%",
                }}
              >
                {currentQuestion + 1}
              </p>
              <p
                className="font-1 eqc-smooth-text"
                style={{
                  color: "white",
                  fontSize: "1.6rem",
                  fontWeight: 300,
                  paddingLeft: "5%",
                }}
              >
                {questions[currentQuestion].qText}
              </p>
            </div>
          </div>
        )}
        {currentQuestion === 1 && (
          <div
            className="eqc-mid-left"
            style={{
              height: "100%",
              width: "47%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              paddingLeft: "12%",
            }}
          >
            <div style={{ marginTop: "3.5vh", visibility: "hidden" }}>
              <p
                className="font-2"
                style={{
                  color: "white",
                  fontSize: "2rem",
                  lineHeight: "1",
                  paddingTop: "25%",
                }}
              >
                Unlock Your FREE Surprise Gift
              </p>
              <p
                className="font-1"
                style={{ color: "white", fontSize: "1.5rem" }}
              >
                Answer These 3 Simple Questions
              </p>
            </div>

            <div
              style={{
                paddingTop: "8%",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                animationName: "fadeIn",
                animationDuration: "1s",
              }}
            >
              <p
                className="font-1 eqc-smooth-text"
                style={{
                  color: "white",
                  fontSize: "1.4rem",
                  borderRight: "1px solid white",
                  paddingRight: "2.5%",
                }}
              >
                {currentQuestion + 1}
              </p>
              <p
                className="font-1 eqc-smooth-text"
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: 300,
                  paddingLeft: "5%",
                }}
              >
                {questions[currentQuestion].qText}
              </p>
            </div>
          </div>
        )}
        {currentQuestion === 2 && (
          <div
            className="eqc-mid-left"
            style={{
              height: "100%",
              width: "47%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              paddingLeft: "12%",
            }}
          >
            <div style={{ marginTop: "3.5vh", visibility: "hidden" }}>
              <p
                className="font-2"
                style={{
                  color: "white",
                  fontSize: "2rem",
                  lineHeight: "1",
                  paddingTop: "25%",
                }}
              >
                Unlock Your FREE Surprise Gift
              </p>
              <p
                className="font-1"
                style={{ color: "white", fontSize: "1.5rem" }}
              >
                Answer These 3 Simple Questions
              </p>
            </div>

            <div
              style={{
                paddingTop: "8%",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                animationName: "fadeIn",
                animationDuration: "1s",
              }}
            >
              <p
                className="font-1 eqc-smooth-text"
                style={{
                  color: "white",
                  fontSize: "1.4rem",
                  borderRight: "1px solid white",
                  paddingRight: "2.5%",
                }}
              >
                {currentQuestion + 1}
              </p>
              <p
                className="font-1 eqc-smooth-text"
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  fontWeight: 300,
                  paddingLeft: "5%",
                }}
              >
                {questions[currentQuestion].qText}
              </p>
            </div>
          </div>
        )}

        {currentQuestion === 0 && (
          <div className="eqc-mid-right">
            <div
              className="eqc-comp-options-main"
              style={{ height: "100%", width: "100%", visibility: "hidden" }}
            >
              <div className="eqc-hs-text">
                <p
                  className="font-1"
                  style={{
                    color: "white",
                    fontWeight: 550,
                    fontSize: "1.3rem",
                    marginBottom: "-0.1rem",
                    paddingLeft: "6rem",
                  }}
                >
                  Health Score
                </p>
              </div>
              <div className="eqc-comp-svg">
                <img src={scoreStand} alt="" />
              </div>
              <div className="eqc-comp-hs">
                <p style={{ color: "white", fontSize: "1.6rem" }}>
                  <span style={{ color: "#76e365", fontSize: "1.6rem" }}>
                    93
                  </span>
                  / 100
                </p>
                <p style={{ color: "white", fontSize: "1.6rem" }}>
                  <span style={{ color: "red", fontSize: "1.6rem" }}>57</span>/
                  100
                </p>
              </div>
            </div>

            <div className="eqc-comp-options">
              <button
                onClick={() => optionSelect(1)}
                className={`eqc-option-${
                  optionSelected[currentQuestion] === 1 ? "11" : "1"
                }`}
              >
                <img style={{ height: "78%" }} src={AssetFitPerson} alt="" />
              </button>

              <div
                onClick={() => optionSelect(2)}
                className={`eqc-option-${
                  optionSelected[currentQuestion] === 2 ? "22" : "2"
                }`}
              >
                <img style={{ height: "75%" }} src={AssetMuscular} alt="" />
              </div>
            </div>
          </div>
        )}
        {currentQuestion >= 1 && (
          <div className="eqc-mid-right">
            <div
              className="eqc-comp-options-main"
              style={{
                height: "100%",
                width: "100%",
                animationName: "fadeIn",
                animationDuration: "2s",
              }}
            >
              <div className="eqc-hs-text">
                <p
                  className="font-1"
                  style={{
                    color: "white",
                    fontWeight: 550,
                    fontSize: "1.3rem",
                    marginBottom: "-0.1rem",
                    paddingLeft: "6rem",
                  }}
                >
                  Health Score
                </p>
              </div>
              <div className="eqc-comp-svg">
                <img src={scoreStand} alt="" />
              </div>
              <div className="eqc-comp-hs">
                <p style={{ color: "white", fontSize: "1.6rem" }}>
                  <span style={{ color: "#76e365", fontSize: "1.6rem" }}>
                    93
                  </span>
                  / 100
                </p>
                <p style={{ color: "white", fontSize: "1.6rem" }}>
                  <span style={{ color: "red", fontSize: "1.6rem" }}>57</span>/
                  100
                </p>
              </div>
            </div>
            <div className="eqc-comp-options">
              <div
                onClick={() => optionSelect(1)}
                className={`eqc-option-${
                  optionSelected[currentQuestion] === 1 ? "11" : "1"
                }`}
              >
                <img style={{ height: "78%" }} src={AssetFitPerson} alt="" />
              </div>

              <div
                onClick={() => optionSelect(2)}
                className={`eqc-option-${
                  optionSelected[currentQuestion] === 2 ? "22" : "2"
                }`}
              >
                <img style={{ height: "75%" }} src={AssetMuscular} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="eqc-bottom"
        style={{
          height: "15%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="eqc-bottom-next"
          onClick={skipQuiz}
          style={{
            display: "flex",
            height: "70%",
            alignItems: "center",
            marginLeft: "2rem",
            cursor: "pointer",
          }}
        >
          <div style={{ alignItems: "center", display: "flex" }}>
            <p
              className="font-1"
              style={{
                color: "white",
                fontSize: "1.4rem",
                fontStyle: "italic",
                marginBottom: "0rem",
                fontWeight: "200",
              }}
            >
              Skip
            </p>
          </div>
          <img
            src={skipArrow}
            alt=""
            style={{
              height: "32%",
              marginLeft: "0.7rem",
              marginBottom: "0.5rem",
            }}
          />
        </div>
        <div
          style={{
            width: "5%",
            marginRight: "3rem",
            display: "flex",
            gap: "1rem",
            justifyContent: "end",
          }}
        >
          {currentQuestion > 0 && (
            <img
              onClick={prevQuestion}
              className="eqc-bottom-prev"
              src={prevArrow}
              alt=""
              style={{
                height: "1.3rem",
                margin: "1%",
                paddingRight: "0.2rem",
                cursor: "pointer",
              }}
            />
          )}
          <img
            onClick={nextQuestion}
            className="eqc-bottom-next"
            src={nextArrow}
            alt=""
            style={{
              height: "1.3rem",
              margin: "1%",
              paddingRight: "0.2rem",
              cursor: "pointer",
            }}
          />
        </div>
      </div>

      <Modal isOpen={showModal} onRequestClose={closeModal}>
        <DialogComponent onClose={closeModal} />
      </Modal>
      <Modal isOpen={showThankModal} >
        <ThankModal onClose={closeThankModal}/>
      </Modal>

      <Modal
        isOpen={showModalMuscular}
        // onRequestClose={closeModal}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <PrizeModal2 onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default EntryQuizComponent;
