import React, { useEffect, useState } from 'react';
// import redBackground from '../../images/asset3.png'
// import mobileBg from '../../images/Mobile/Banner/absolutelyNotBgMobile.svg'
import './MottoBox.css'

function MottoBox() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      {(!isMobile) ? <div
        className='mb-imageBg'
        style={{ backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/asset3.png)` }}
      >
        <br></br>
        <h1 className='font-1 mb-heading' >Does This Mean We're Telling You Not To</h1>
        <h1 className='font-1 textColor' >Be Fit Physically?</h1>
        {/* <br/> */}
        <h1 className='font-2' style={{ color: 'white' }}>Absolutely Not…</h1>
        {/* <br> */}
        {/* </br> */}
        <br />
        <p className='textColor' style={{ lineHeight: '0.5', fontSize: '19px' }}  >In Fact We Promote Online Workouts & Diet Plans As Part Of Our Programs.</p>
        <p className='font-1 textColor' style={{ lineHeight: '1', fontSize: '19px' }} >If you read my complete story, you'd know Working out was a BIG part of my recovery from NAFLD.</p>
        <p className='textColor' style={{ lineHeight: '0', fontSize: '19px' }} >Now, I know what you're thinking...</p>
        <br></br>
        <h4 className='font-1 textColor mb-italicSubHeading' >
          If you're doing the same thing (Online Workouts & Diets) - how are you <br /> different from others?
        </h4>
        <br></br>
        <br></br>
      </div>

        : <div className="m-mb-imageBg" style={{ backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/Mobile/Banner/absolutelyNotBgMobile.svg)` }}>
          <br></br>
          <div className='font-1 m-mb-heading' >Does This Mean We're Telling You Not To Be Fit Physically?</div>
          {/* <h1 className= 'font-1 textColor' ></h1> */}
          <br />
          <div className='font-2 m-mb-italicHeading' >Absolutely Not…</div>
          <br></br>
          <br />
          <p className='font-1 m-mb-desc'  >In Fact We Promote Online Workouts & Diet Plans As Part Of Our Programs. If you read my complete story, you'd know Working out was a BIG part of my recovery from NAFLD. Now, I know what you're thinking...</p>
          <br />
          <div className='font-1 m-mb-subHeading' >
            If you're doing the same thing (Online Workouts & Diets) - how are you <br /> different from others?
          </div>
          <br></br>
        </div>
      }
    </>
  );
}

export default MottoBox;
