import React, { useState } from "react";
import "./EmailModal.css";
import { useNavigate } from "react-router";
import axios from "axios";

const EmailModal = ({ onCancel ,onSubmit ,reportAnswer,entryoption}) => {
  const [formData, setFormData] = useState({
    email: "",
    mobileNumber: "",
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidMobileNumber = (mobileNumber) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobileNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.mobileNumber) {
      alert("All fields are required.");
      return;
    }

    if (!isValidEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!isValidMobileNumber(formData.mobileNumber)) {
      alert("Please enter a valid mobile number.");
      return;
    }
    const mapGender = (gender) => {
      // Map gender to API format
      return gender === "Male" ? "1" : "2";
    };
  
    const mapHeight = (height, unit) => {
      // Map height to API format based on unit
      if (unit === "cm") {
        return parseFloat(height.cm);
      } else {
        // Convert feet and inches to cm
        return parseFloat(height.ft) * 30.48 + parseFloat(height.in) * 2.54;
      }
    };
  
    const mapHeightUnit = (unit) => {
      // Map height unit to API format
      return unit === "cm" ? "0" : "2";
    };

    const mapDiagnosedDiseases = (diseases) => {
      let mappedDiseases = [];

// Iterate through each disease using a for loop
for (let i = 0; i < diseases.length; i++) {
  let disease = diseases[i];

  // Use switch case to map each disease to its corresponding code
  switch (disease) {
    case "Type 2 diabetes":
      mappedDiseases.push("0");
      break;
    case "Hypertension":
      mappedDiseases.push("1");
      break;
    case "Fatty liver":
      mappedDiseases.push("2");
      break;
    case "Lipid disorders":
      mappedDiseases.push("3");
      break;
    case "Heart ailments":
      mappedDiseases.push("4");
      break;
    case "Thyroid disorders":
      mappedDiseases.push("5");
      break;
    case "PCOS":
      mappedDiseases.push("8");
      break;
    case "Stroke":
      mappedDiseases.push("6");
      break;
    case "Mental Health":
      mappedDiseases.push("7");
      break;
    case "Other":
      mappedDiseases.push("O");
      break;
    default:
       // or handle unknown cases accordingly
      break;
  }
}

 return mappedDiseases;
    };
  
  
    const mapWeightUnit = (unit) => {
      // Map weight unit to API format
      return unit === "kg" ? "0" : "1";
    };
  
    const mapWaistUnit = (unit) => {
      // Map waist circumference unit to API format
      return unit === "cm" ? "0" : "1";
    };
  
    const mapHipUnit = (unit) => {
      // Map hip circumference unit to API format
      return unit === "cm" ? "0" : "1";
    };
  
    const mapBodyFatUnit = (unit) => {
      // Map body fat unit to API format
      return unit === "%" ? "0" : "0"; // Assuming no alternative unit provided
    };
  
    const mapActiveDurationUnit = (unit) => {
      // Map daily active duration unit to API format
      return unit === "minutes daily" ? "0" : "1";
    };
  
    const mapSittingHours = (value) => {
      // Map sitting hours to API format
      switch (value) {
        case "Less than 1 hour":
          return "0";
        case "1-4 hours":
          return "1";
        case "More than 4 hours":
          return "2";
        default:
          return "";
      }
    };
  
    const mapExerciseFrequency = (value) => {
      // Map exercise frequency to API format
      switch (value) {
        case "Rarely or Never":
          return "0";
        case "Less than 1 hour":
          return "1";
        case "1 to 3 hours":
          return "2";
        case "4 to 8 hours":
          return "3";
        case "More than 8 hours":
          return "4";
        default:
          return "";
      }
    };
    const stringToFloat = (str) => {
      return parseFloat(str);
    };
  
    const mapExerciseLevel = (value) => {
      // Map exercise level to API format
      // Assuming exercise level field maps to exercise intensity choices
      switch (value) {
        case "Low intensity":
          return "0";
        case "Moderate intensity":
          return "1";
        case "High intensity":
          return "2";
        default:
          return "0";
      }
    };
  
    const mapSicknessFrequency = (value) => {
      // Map sickness frequency to API format
      switch (value) {
        case "Rarely or Never":
          return "0";
        case "1 to 2 times":
          return "1";
        case "2 to 3 times":
          return "2";
        case "4 to 5 times":
          return "3";
        case "More than 6 times":
          return "4";
        default:
          return "";
      }
    };
  
    const mapGoalPreference = (value) => {
      // Map goal preference to API format
      switch (value) {
        case "Reducing daily diet intake":
          return "0";
        case "Increasing physical activity":
          return "1";
        case "Forming healthy habits":
          return "2";
        default:
          return "";
      }
    };
    const checkHip=(value)=>{
      if(value=="")return null;
      else return value;
    }
  
    const mapHealthPriorities = (values) => {
      // Map health priorities to API format
      // Assuming health priorities field maps to health priorities choices
      return values.map((value) => {
        switch (value) {
          case "Weight Loss":
            return "0";
          case "Building Muscle Mass":
            return "1";
          case "Improving Metabolic Health":
            return "2";
          case "Increasing Energy Levels":
            return "3";
          case "Increasing Strength":
            return "4";
          case "Improving Physical Endurance":
            return "5";
          default:
            return "";
        }
      });
    };

    const calculateAge = (dob) => {
      // Implement your logic to calculate age from date of birth
      // Example implementation:
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };


    const ReportData = {
      first_name: reportAnswer.name,
      last_name: reportAnswer.surname,
      gender: mapGender(reportAnswer.gender), // Function to map gender
      date_of_birth: reportAnswer.dob,
      age : calculateAge(reportAnswer.dob), // Function to calculate age
      height: mapHeight(reportAnswer.height, reportAnswer.heightUnit), // Function to map height
      height_unit: "0", // Function to map height unit
      weight: reportAnswer.weight,
      weight_unit: mapWeightUnit(reportAnswer.weightUnit), // Function to map weight unit
      waist_circumference: reportAnswer.waist,
      waist_circumference_unit: mapWaistUnit(reportAnswer.waistUnit), // Function to map waist unit
      hip_circumference: checkHip(reportAnswer.hip),
      hip_circumference_unit: mapHipUnit(reportAnswer.hipUnit), // Function to map hip unit
      body_fat: reportAnswer.bodyFat,
      body_fat_unit: mapBodyFatUnit(reportAnswer.bodyFatUnit), // Function to map body fat unit
      daily_active_duration: stringToFloat(reportAnswer.walkingTime),
      daily_active_duration_unit: "0", // Function to map active duration unit
      sitting_hours: mapSittingHours(reportAnswer.sitTime), // Function to map sitting hours
      exercise_frequency_week: mapExerciseFrequency(reportAnswer.leisureTime), // Function to map exercise frequency
      exercise_level: mapExerciseLevel(reportAnswer.activityIntensity), // Function to map exercise level
      sickness_frequency: mapSicknessFrequency(reportAnswer.sicknessFrequency), // Function to map sickness frequency
      diagnosed_diseases: mapDiagnosedDiseases(reportAnswer.diseases),
      goal_preference: mapGoalPreference(reportAnswer.lifestylePriorities), // Function to map goal preference
      health_priorities: mapHealthPriorities(reportAnswer.priorities), // Function to map health priorities
      email: formData.email,
      mobile: formData.mobileNumber,
      entryType: entryoption
    };



   
    
    try {
      // Make API call using axios
      onSubmit();
      const response = await axios.post('https://prod-api.fitnastichealth.in/api/v1/fitnessRecord/report', ReportData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // Call onSubmit function after API call
      // Navigate to '/home'
      // Example: navigate("/home", { replace: true });
    } catch (error) {
      // Handle API call error
      // console.error('API Error:', error);
      // alert('Failed to submit data. Please try again.');
    }
    
    
  };
  

  const calculateHeightInCm = (height) => {
    // Implement your logic to convert height to cm
    // Example implementation:
    return parseFloat(height.ft) * 30.48 + parseFloat(height.in) * 2.54;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const navigate = useNavigate();

  const closeModal = () => {
    navigate("/home", { replace: true });
  };

  return (
    <>
      <div
        className="modal"
        // onRequestClose={onClose}
        contentLabel="Example Modal"
      >
        <div
          style={{
            width: "52%",
            height: "60%",
            background: "white",
            borderRadius: "30px",
            boxShadow: '0px 0px 8px 0px #00000040',
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            animationName: "fadeIn",
            animationDuration: "0.5s"
          }}
        >
         <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'start'}}>
            <div style={{width:'100%',height:'23%',display:'flex',alignItems:'end',justifyContent:'center'}}>
             <p className="font-1" style={{color:'#CC203B',fontSize:'1.8vw',marginBottom:'9px',fontWeight:'700'}}>One Last Step</p>
            </div>
            <div style={{width:'100%',height:'77%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',borderTopWidth:'1.5px',borderColor:'#CC203B'}}>
             <p className="font-1" style={{fontSize:'1.4vw',marginTop:'7px',marginBottom:'0rem'}}>Please fill in the below details to get your </p>
             <p className="font-1" style={{fontSize:'1.4vw'}}>FREE HEALTH REPORT</p>
             <div className="">
            <form action="" className="dFlex-pm2">
              <div className="form-group" style={{ marginLeft: "2.5rem" }}>
                <input
                  className="form-group-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter email address"
                  required
                />
              </div>
              <div className="form-group" style={{ marginLeft: "3rem" }}>
                <div className="dFlex-pm2 customInput-pm2">
                  <div className="custom91">+91</div>
                  <input
                    className="form-group-inputNone"
                    type="text"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    placeholder="Enter mobile number"
                    required
                  />
                </div>
              </div>
            </form>
          </div>
          <div style={{width:'100%',marginTop:'15px', display:'flex',alignItems:'center',justifyContent:'center',gap:'15px'}}>
          <button
            onClick={onCancel}
            className="font-1 pm2-get-start"
            style={{
              border: "1.75px",
              borderColor: "#CC203B",
              borderStyle: "solid",
              borderRadius: "50px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "10px",
              marginBottom: "30px",
              color: "#CC203B",
              fontWeight: "400",
              fontSize: "1.3vw",
              width:'120px'
            }}
          >
            CANCEL
          </button>
          <button
            onClick={handleSubmit}
            className="font-1 pm2-get-start"
            style={{
              border: "1.75px",
              borderColor: "#CC203B",
              borderStyle: "solid",
              borderRadius: "50px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              marginTop: "10px",
              marginBottom: "30px",
              color: "#CC203B",
              fontWeight: "400",
              fontSize: "1.3vw",
              width:'120px'
            }}
          >
            SUBMIT
          </button>
          </div>
         

            </div>
         
         
         </div>
          {/* <button onClick={onClose}>Close</button> */}
        </div>
      </div>
    </>
  );
};

export default EmailModal;
