import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserSideProfile.css';
import logo from '../../../images/logo.svg';

const UserSideProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { name: 'Dashboard', path: '/hr/dashboard' },
    { name: 'All Staff', path: '/hr/all-staff' },
    { name: 'Participants', path: '/hr/participants' },
    { name: 'Profile Settings', path: '/hr/profile-settings' }
  ];

  const handleItemClick = (path) => {
    navigate(path);
  };

  return (
    <div className='dhr-usp-container'>
       <div style={{width:'100%'}}>
       <img className="dhr-usp-logo" src={logo} alt="logo" />
      <div style={{marginTop:'2rem'}} className="dhr-usp-content font-1">
        {menuItems.map((item) => (
          <div
            key={item.name}
            className={`dhr-usp-content-item ${location.pathname === item.path ? 'active-item-dhr-usp' : ''}`}
            onClick={() => handleItemClick(item.path)}
          >
            {item.name}
          </div>
        ))}
      </div>
        </div> 
     
      <div className="dhr-usp-logout font-1">Logout</div>
    </div>
  );
};

export default UserSideProfile;
