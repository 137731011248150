import React from "react";
import newLogo from '../../images/Logo/simpleLogo.svg'
import tick from '../../images/icons8-tick.svg'
import './BrandsTable.css';

function BrandsTable(){
    return (
        <div style={{marginTop:'30px',width :'90%' ,marginLeft:'20px'}}>
            <table>
                <tr>
                    <th>
                        Market Players
                    </th>
                    <th style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                        <div style={{height:'5vw', width:'9vw',background:`url(${newLogo})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                       </div>
                    </th>
                    <th style={{alignItems:'center', justifyContent:'center'}}>
                        <div style={{height:'5vw', width:'9vw',background:`url(${newLogo})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                       </div>
                    </th>
                    <th >
                        <div style={{height:'5vw', width:'9vw',background:`url(${newLogo})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                       </div>
                    </th>
                    <th >
                        <div style={{height:'5vw', width:'9vw',background:`url(${newLogo})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                       </div>
                    </th>
                </tr>
                <tr className="td-logo">
                <td>
                    Market Players
                </td>
                    <td className="td-logo">
                        <div style={{height:'5vw', width:'5vw',background:`url(${tick})`,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:''}}>
                       </div>
                    </td>
                    <td className="td-logo">
                        <div style={{height:'5vw', width:'9vw',background:`url(${tick})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                       </div>
                    </td>
                    <td className="td-logo">
                        <div style={{height:'5vw', width:'9vw',background:`url(${tick})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                       </div>
                    </td>
                    <td className="td-logo">
                        <div style={{height:'5vw', width:'9vw',background:`url(${tick})`,backgroundSize:'contain',backgroundRepeat:'no-repeat'}}>
                       </div>
                    </td>

                </tr>
            </table>

        </div>
    );
};
export default BrandsTable;