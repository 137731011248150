import React, { useRef, useState, useEffect } from 'react';
import 'swiper/css/bundle';
import bgQuizAnim from '../../images/quiz/bgQuizAnim.svg'
import bgQuizMobile from "../../images/quizMobile/bg.svg";

import fitnasticWhiteLogo from '../../images/quiz/fitnasticWhiteLogo.svg'
import './EntryQuizAnim.css'
import { useNavigate } from "react-router";

const EntryQuizAnim = () => {
      
    const navigate = useNavigate()

    const skip = () => { // call this function to skip to home screen on skip button.
        navigate("/home", {replace: true})
    }

    const toQuiz = () => {
        navigate("/quiz", {replace: true})
    }
    
    /**
     * Automatically redirect to Quiz page after 4 seconds.
     */
    setTimeout(()=>{
        toQuiz()
    }, 3500)

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 700);
      };
  
      window.addEventListener("resize", handleResize);
      handleResize(); // Call handler right away so state gets updated with initial window size
  
      return () => window.removeEventListener("resize", handleResize);
    }, []);  

    return (

        (!isMobile) ?  <div style={{
            backgroundImage: `url(${bgQuizAnim})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div 
            className ='eqa-loader-div' 
            style={{
                width:'auto'
            }}>
                <img src={fitnasticWhiteLogo} alt=""/>
            </div>
        </div> :
        <div style={{
            backgroundImage: `url(${bgQuizMobile})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <div 
            className ='eqa-loader-div' 
            style={{
                width:'37vw',
                zIndex:'10rem'
            }}>
                <img src={fitnasticWhiteLogo} alt=""/>
            </div>
        </div>
    )
}

export default EntryQuizAnim