import React from 'react';
import './NewFooter.css'; // Import the CSS file for styling
import logo from '../../images/logo.svg'
import { Link, NavLink } from 'react-router-dom';
import linkdinlogo from '../../images/footer-linkdin.png'
import instalogo from '../../images/footer-insta.png'

const NewFooter = () => {
    const scrollToTop = () => {

        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
  return (

    <footer className="f-footer">
      <div className="f-footer-content">
        <div className="f-footer-logo" style={{paddingBottom:'0.5%'}}>
        <NavLink exact to="/home" onClick={scrollToTop} style={{ textDecoration: 'none' }}>
        <img src={logo} alt="Fitnastic Logo" className="f-logo" />
        </NavLink>
        
        </div>
        
        <nav className="f-footer-nav font-1">
          <div style={{ textAlign: 'start' }}>
            <Link
              to="/about"
              style={{ textDecoration: 'none' ,lineHeight:'1.8'}}
              onClick={scrollToTop}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span>ABOUT US</span>
            </Link>
            <br />
            <Link
              to="/ourTech"
              style={{ textDecoration: 'none' }}
              onClick={scrollToTop}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span>OUR TECH</span>
            </Link>
          </div>
          <div style={{ textAlign: 'start', marginLeft: '1vw' }}>
            <Link
              to="/programs"
              style={{ textDecoration: 'none' ,lineHeight:'1.8'}}
              onClick={scrollToTop}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span>PROGRAMS</span>
            </Link>
            <br />           
            <Link
              exact
              to="/contact"
              style={{ textDecoration: 'none' }}
              onClick={scrollToTop}
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              <span>GET IN TOUCH</span>
            </Link>
          </div>
        </nav>
        <div className="f-footer-social font-1">
          <span className='f-footer-social-text font-1' style={{cursor:'default'}}>Follow Us</span>
          <a href="https://www.instagram.com/fitnastic.health/" target="_blank" rel="noopener noreferrer" style={{width:'auto'}}>
            <img className='logosize'  src={instalogo} alt="Description of the image" style={{width:'2vw'}}/>
            {/* <i className="ri-instagram-fill"></i> */}
          </a>
          <a href="https://in.linkedin.com/company/fitnasticindia" target="_blank" rel="noopener noreferrer" style={{width:'auto'}}>
          <img className='logosize' src={linkdinlogo} alt="Description of the image" style={{width:'2vw'}}/>
            {/* <i className="ri-linkedin-box-fill"></i> */}
          </a>
        </div>
      </div>
      <div className="f-footer-bottom font-1">
        <p>Copyright &copy; Fitnastic Health PVT. LTD.</p>
      </div>
    </footer>
  );
}

export default NewFooter;
