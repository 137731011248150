import React from 'react'
import { Link } from 'react-router-dom';
// import plansBanner from '../../images/Banner/bannerPlans.svg'
// import plansBannerMobile from '../../images/Banner/Temp2.svg'
import { useState, useEffect } from "react";
const containerStyle = {
    height: '50vw',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center', // Align items to the start
    background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/Banner/bannerPlans.svg)`,
    backgroundSize: 'contain',
    backgroundPosition: '0px -5px',
    margin: 0,
    padding: 0,
    // position: 'relative', // Position relative to contain absolute positioned elements
  };

const BannerPlans = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {!isMobile ? (<div>
    <div  style={containerStyle} >
        <div className='mainStyles' style={{width:'50%'}}>
          <p className='font-2' style={{color: '#063533', fontSize:'3vw', fontWeight:400,paddingRight:'3rem', lineHeight:'1',paddingLeft:'1.5rem'}}>Get Tailored Nutrition Plans</p>
          <h1 className='font-1' style={{color: '#063533', fontSize:'2vw',paddingLeft:'1.5rem'}}>From Our Clinical Nutritionists</h1>
          <a href="https://rzp.io/l/bLXxFmr">
          <button 
            style={{
              border: '2px', 
              borderColor:'#CC203B', 
              borderStyle: 'solid', 
              borderRadius: '50px', 
              paddingTop:'0.7rem', 
              paddingBottom:'0.7rem', 
              paddingLeft:'1rem', 
              paddingRight:'1rem',
              marginTop: '5%',
              marginLeft:'1.5rem',
              color:'#CC203B',
              fontSize:'1.5vw',
              fontWeight: 550
              }}
              className='font-1'
              >
                Claim Your Discounted Price Today!          
            </button>
          </a>
            
        
        </div>
        <div className="blankDiv" style={{width:'50%'}}></div>
      </div>
    </div>):
    (<div style={{background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/New+Mobile+Banner+SVGs/Mobile+Banner+2-01.svg)`,height:'80vh',width:'100%',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPositionY:'100%',display:'flex',alignItems:'start',justifyContent:'center'}}>
    <div style={{width:'100%',textAlign:'center',paddingTop:'15%'}}>
      <h1
        className="font-2"
        style={{
          color: '#063533',
          fontSize: "9.3vw",
          lineHeight:'1',
          fontWeight:'100'
        }}
      >
        Get Tailored Nutrition Plans From Our
      </h1>
      <p
        className="font-1"
        style={{ color: '#063533', fontSize: "9.3vw", fontWeight: 400 ,marginBottom:'0%'}}
      >
        Clinical Nutritionists
      </p>
      <a href="https://rzp.io/l/bLXxFmr">
      <button
          style={{
            border: "2px",
            borderColor:'#CC203B',
            borderStyle: "solid",
            borderRadius: "50px",
            paddingTop: "0.7rem",
            paddingBottom: "0.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginTop: "5%",
            color:'#CC203B',
            fontSize: "5vw",
            fontWeight: 550,
            textAlign:'center'
          }}
          className="font-1"
        >
         Claim Your Discounted Price Today!
        </button>
      </a>
        
    </div>


  </div>)
    }
    </>
  )
}

export default BannerPlans