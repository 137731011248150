import React from "react";
import "./WhyTestedComponent.css";
import trishul from "../../../images/TechPageImages/trishul.svg";
import mTrishul from "../../../images/TechPageImages/TechBannerImages/m-stickBg.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const WhyTestedComponent = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {!isMobile ? (
        <div className="wtc-main">
          <div className="wtc-top-txt">
            <p className="wtc-tt-1 font-1">Here’s Why You Need To Get</p>
            <p className="wtc-tt-2 font-2">Tested NOW!</p>
          </div>
          <div className="wtc-trident-comp">
            <div className="wtc-tc-1">
              <p
                className="wtc-tt-2 font-2"
                style={{
                  marginBottom: "0rem",
                  fontSize: "2vw",
                  textAlign: "start",
                  paddingLeft: "6vw",
                  fontWeight: "400",
                }}
              >
                Some Stats That’ll Shock You…
              </p>
            </div>
            <div className="wtc-tc-2">
              <img src={trishul} style={{ height: "100%" }} alt="" />
            </div>
            <div className="wtc-tc-3 font-1" style={{ fontWeight: "400" }}>
              <div className="wtc-tc-3-1">
                India has been termed the{" "}
                <span style={{ fontWeight: "bold" }}>
                  “Diabetic Capital Of The World”
                </span>{" "}
                with over{" "}
                <span style={{ fontWeight: "bold" }}>
                  77 million people with diabetes.
                </span>
              </div>
              <div className="wtc-tc-3-2">
                It is estimated that around 1 in 6 adults in India have
                hypertension.
              </div>
              <div className="wtc-tc-3-3">
                CVDs accounted for 28.1% of all deaths in India in 2018, making
                them the leading cause of death in the country.
              </div>
            </div>
          </div>
          <div className="wtc-line"></div>
          <div className="wtc-bottom-txt">
            <p
              className="font-2"
              style={{
                textAlign: "center",
                fontSize: "2vw",
                fontWeight: "400",
              }}
            >
              In-Short, Prevention Is Better Than Cure… <br />
              If You Know Your Metabolic Health, It’ll Help You Take Smart{" "}
              <br /> Health Choices TODAY & We Help You Do That
            </p>
          </div>
          <div className="wtc-bottom-button">
            <Link to="/freeHealthReport">
              <button
                className="font-1 cib-btn"
                style={{
                  border: "2px",
                  borderColor: "#CC203B",
                  borderStyle: "solid",
                  borderRadius: "50px",
                  paddingTop: "10px",
                  paddingBottom: "12px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "20px",
                  color: "#CC203B",
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                GET YOUR FREE HEALTH REPORT
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="m-wtc-container">
          <p className="m-wtc-heading2 font-1" style={{ marginBottom: "0rem" }}>
            Here’s Why You Need To Get{" "}
          </p>
          <p className="m-wtc-heading1 font-2">Tested NOW!</p>
          <p
            className="m-wtc-heading1 font-2"
            style={{ fontSize: "1.2rem", marginTop: "2rem" }}
          >
            Some Stats That’ll Shock You…
          </p>
          <div
            style={{
              display: "flex",
              width: "85%",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              gap:'1rem',
              left: "7.5%",
              marginTop: "3rem",
            }}
          >
            <img
              src={mTrishul}
              alt=""
              style={{ position: "relative", top: "-1.5rem", height: "16rem" }}
            />
            <div>
              <div className="font-1 m-tba-midPhrase" >
                <p style={{textAlign:'start'}}>
                  India has been termed the{" "}
                  <strong>“Diabetic Capital Of The World”</strong> with over{" "}
                  <strong>77 million people with diabetes.</strong>
                </p>
              </div>
              <div className="font-1 m-tba-midPhrase">
                <p style={{textAlign:'start'}}>
                  It is estimated that around 1 in 6 adults in India have
                  hypertension.
                </p>
              </div>
              <div className="font-1 m-tba-midPhrase">
                <p style={{textAlign:'start'}}>
                  CVDs accounted for 28.1% of all deaths in India in 2018,
                  making them the leading cause of death in the country.
                </p>
              </div>
            </div>
          </div>
          <div
            className="font-1 m-tba-midPhrase"
            style={{ width: "85%", position: "relative", left: "7.5%" }}
          >
            <p className="font-2">
              In-Short, Prevention Is Better Than Cure… If You Know Your
              Metabolic Health, It’ll Help You Take Smart Health Choices TODAY &
              We Help You Do That
            </p>
          </div>
          <Link to="/freeHealthReport">
            <button
              className="font-1"
              style={{
                border: "2px",
                borderColor: "#CC203B",
                borderStyle: "solid",
                borderRadius: "50px",
                paddingTop: "13px",
                paddingBottom: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                marginTop: "2rem",
                color: "#CC203B",
                fontWeight: "600",
                fontSize: "1rem",
                width: "80%",
                position: "relative",
                marginBottom:'0rem',
                left: "10%",
              }}
            ><p style={{marginBottom:'0rem'}}> GET YOUR FREE HEALTH REPORT</p>
             
            </button>
          </Link>
         
        </div>
      )}
    </>
  );
};

export default WhyTestedComponent;
