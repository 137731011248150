import React, { useState } from 'react';
import axios from 'axios';
import './PersonalizedNutritionFormModal.css';

const PersonalizedNutritionFormModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    age: '',
    height: '',
    weight: '',
    contactNumber: '',
    goal: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://prod-api.fitnastichealth.in/api/v1/leads/nutritionPlan', formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        console.log('Form Data:', response.data);
        // Clear the form data
        setFormData({
          fullName: '',
          age: '',
          height: '',
          weight: '',
          contactNumber: '',
          goal: ''
        });
        // Close the modal
        onClose();
      }
    } catch (error) {
      console.error('There was a problem with the axios operation:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="pnfm-modal-overlay">
      <div className="pnfm-modal-content">
        <button className="pnfm-close-button" onClick={onClose}>&times;</button>
        <h2>Book a Personalized Nutrition Call</h2>
        <form onSubmit={handleSubmit}>
          <div className="pnfm-form-group">
            <label>Full Name</label>
            <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
          </div>
          <div className="pnfm-form-group">
            <label>Age</label>
            <input type="number" name="age" value={formData.age} onChange={handleChange} required />
          </div>
          <div className="pnfm-form-group">
            <label>Height</label>
            <input type="text" name="height" value={formData.height} onChange={handleChange} required />
          </div>
          <div className="pnfm-form-group">
            <label>Weight</label>
            <input type="text" name="weight" value={formData.weight} onChange={handleChange} required />
          </div>
          <div className="pnfm-form-group">
            <label>Contact Number</label>
            <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} required />
          </div>
          <div className="pnfm-form-group">
            <label>Goal</label>
            <div className="pnfm-radio-group">
              <label>
                <input type="radio" name="goal" value="weight gain" onChange={handleChange} required />
                Weight Gain
              </label>
              <label>
                <input type="radio" name="goal" value="fat loss" onChange={handleChange} required />
                Fat Loss
              </label>
              <label>
                <input type="radio" name="goal" value="healthier lifestyle" onChange={handleChange} required />
                To Make a Healthier Lifestyle
              </label>
            </div>
          </div>
          <button type="submit">Enquire Now</button>
        </form>
      </div>
    </div>
  );
};

export default PersonalizedNutritionFormModal;
