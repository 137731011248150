import React from "react";
// import img from "../../images/Banner/liveClassBg.jpg";
import updateClickCount from "../../network/ClickCounterApis/UpdateClickCounter";
import { Link } from "react-router-dom";
// import img2 from '../../images/Banner/Temp3.svg'
import { useState, useEffect } from "react";
import livebg from '../../images/live.png'

function LiveClass() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const containerStyle = {
    height: "50vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center", // Align items to the start
    background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/Banner/liveClassBg.jpg)`,
    backgroundSize: "contain",
    backgroundPosition: "0px 0px",
    backgroundRepeat:'no-repeat'
  };

  const updateClick = async () => {
    await updateClickCount("HealthReportButton");
  };
  return (
    <>
      {!isMobile ? (<div style={containerStyle }>
        <div
          style={{
            height: 'max-content',
            width: "53%",
            backgroundColor: "rgb(0,0,0, 80%)",
            justifyItems: "center",
            marginBottom:'2.8vw',
            marginTop:'2vw'
          }}
        >
          <p
            className="font-2"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "2vw",
              marginTop: "3vw",
            }}
          >
            <span
              style={{
                background: "#CC203B",
                paddingRight: "3%",
                paddingLeft: "2%",
              }}
            >
              Grand Sale!
            </span>
          </p>
          <p
            className="font-2"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "3.5vw",
              marginTop: "-0.7vw",
            }}
          >
            Get 50% Off On Our
          </p>
          <p
            className="font-1"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "3.5vw",
              marginTop: "-2vw",
            }}
          >
            LIVE Group Workouts
          </p>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center", marginTop:"0%"}}
          >
           <a href="https://rzp.io/l/fitfest-discount">
           <button
                onClick={updateClick}
                style={{
                  border: "2px",
                  borderColor: "#FFFFFF",
                  borderStyle: "solid",
                  borderRadius: "50px",
                  paddingTop: "0.7rem",
                  paddingBottom:'0.7rem',
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginTop: "1.5vw",
                  marginBottom:'1vw',
                  color: "#FFFFFF",
                  fontSize: "1.5vw",
                  fontWeight: 550,
                }}
                className="font-1"
              >
                Join Now {">>"}
              </button>
           </a>
             
            
          </div>
        </div>
      </div>):
       (<div style={{background: `url(${livebg})`,height:'80vh',width:'100vw',backgroundSize:'cover',backgroundRepeat:'no-repeat',display:'flex',alignItems:'start',justifyContent:'center'}}>
       <div style={{width:'100%',textAlign:'center',paddingTop:'15%'}}>
       <p
            className="font-2"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "1.8rem",
              
            }}
          >
            <span
              style={{
                background: "#CC203B",
                paddingRight: "3%",
                paddingLeft: "2%",
              }}
            >
              Grand Sale!
            </span>
          </p>
         <h1
           className="font-2"
           style={{
             color: '#FFFFFF',
             fontSize: "8vw",
             lineHeight:'1',
             fontWeight:'100'
           }}
         >
           Get 50% Off On Our
         </h1>
         <p
           className="font-1"
           style={{ color: '#FFFFFF', fontSize: "8vw", fontWeight: 400 ,marginBottom:'0%'}}
         >
           LIVE Group Workouts
         </p>
         <a href="https://rzp.io/l/fitfest-discount">
         <button
             style={{
               border: "2px",
               borderColor:"#FFFFFF",
               borderStyle: "solid",
               borderRadius: "50px",
               paddingTop: "0.7rem",
               paddingBottom: "0.5rem",
               paddingLeft: "1rem",
               paddingRight: "1rem",
               marginTop: "5%",
               color:'#FFFFFF',
               fontSize: "5vw",
               fontWeight: 550,
               textAlign:'center'
             }}
             className="font-1"
           >
           Join Now {">>"}
           </button>
         </a>
          
        
       </div>
   
   
     </div>)
      }
    </>
  );
}
export default LiveClass;