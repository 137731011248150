import React from 'react'
import './Recommendations.css'
import EmpSideProfile from '../../Components/EmpSideProfile/EmpSideProfile'

const Recommendations = () => {
  return (
    <div className='dhr-main-container-dash'>
    <div className='dhr-main-container-left'>
     <EmpSideProfile/>
    </div>
    {/* <div className='dhr-main-container-right'>
    <div className="dhr-main-page">
  <div className="header">
    <h1>Welcome!</h1>
    <p>A preview of your company health report</p>
    <input
      type="text"
      value={searchTerm}
      onChange={handleSearchChange}
      className="search-input"
      placeholder="Search"
    />
  </div>
  <div className="grid-container">
    <div className="grid-item item-1">Participants</div>
    <div className="grid-item item-2">Reports</div>
    <div className="grid-item item-3">Assessments</div>
    <div style={{width:'66%',display:'flex',flexDirection:'column',gap:'15px'}}>
    <div style={{display:'flex',flexWrap:'wrap',gap:'15px',width:'100%'}}>
    <div className="grid-item item-4">Key Scores</div>
    <div className="grid-item item-5">Bio Age</div>
    </div>
    <div style={{display:'flex',flexWrap:'wrap',gap:'15px',width:'100%'}}>
    <div className="grid-item item-7">Disease Risk Score</div>
    <div className="grid-item item-8">Activity Analysis</div>
    </div>
    </div>
    <div className="grid-item item-6">Staff</div>
   </div>
</div>
    </div> */}
</div>
  )
}

export default Recommendations