import React from "react";

import "./ContactInputBox.css";
import { useState,useEffect } from "react";
import img1 from '../../images/ContactPage/img1.svg'
import img2 from '../../images/ContactPage/img2.svg'
import img3 from '../../images/ContactPage/img3.svg'
import img4 from '../../images/ContactPage/img4.svg'
import insta from '../../images/ContactPage/insta.svg'
import linkdin from '../../images/ContactPage/linkdin.svg'
import ThankModal from "./ThankModal/ThankModal";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Minsta from '../../images/footer-insta.png'
import Mlinkdin from '../../images/footer-linkdin.png'
import MobileThankModal from "./MobileThankModal/MobileThankModal";
import axios from 'axios';


import { Link } from "react-router-dom";
import BASE_URL from "../../Utils/Constants";

function ContactInputBox() {
  const [isMobile, setIsMobile] = useState(false);
  const [showThankModal, setShowThankModal] = useState(false);
  const [showMobileThankModal, setShowMobileThankModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({
    name: 'Name is required',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: '', email: '', message: '' };

    // Validate Name
    if (!formData.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    // Validate Email
    const emailPattern = /^(?=.{1,256}$)(?=\S)(?=\S+@\S+)(?!@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      isValid = false;
    }

    // Validate Message
    if (!formData.message) {
      newErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const openThankModal = () => {
    setShowThankModal(true)
  };

  const closeThankModal = () => {
    // navigate("/home", { replace: true });
    setShowThankModal(false)
  };
  const openMobileThankModal = () => {
    // navigate("/home", { replace: true });
    setShowMobileThankModal(true)
  };

  const closeMobileThankModal = () => {
    // navigate("/home", { replace: true });
    setShowMobileThankModal(false)
  };

  const handleSubmit = async (e) => {
    

    if (validateForm()) {
      if (isMobile) {
        openMobileThankModal();
      } else {
        openThankModal();
      }
      try {
        const response = await axios.post(`${BASE_URL}/v1/contact/query`, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        // Reset form data
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      // Handle specific errors
      if (errors.name) {
        alert(errors.name);
      } else if (errors.email) {
        alert(errors.email);
      } else if (errors.message) {
        alert(errors.message);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  
  return (
    <>
      {!isMobile ? <div className="contact-container" >
        <div className="ci-left">
            <div className="ci-left-top">
             <div style={{height:'9rem'}}>
             <p className="font-2" style={{fontSize:'2.3rem',color:'#CC203B'}}> 
             Get In Touch
             </p>
             <p className="font-1" style={{fontSize:'0.9rem'}}>Feel free to reach out using the contact form below, and our team will get back to you as soon as possible.
             </p>
            </div>   
           
             <div className="ci-input-div">
                <input type="text" placeholder="Name*" name="name" className="font-1" style={{paddingLeft:'1.3rem',padding:'1rem',backgroundColor:'white',height:"3rem",outline:'none'}} value={formData.name}
          onChange={handleChange}/>
                <input type="email" placeholder="Email*" name="email" className="font-1"style={{paddingLeft:'1.3rem',padding:'1rem',backgroundColor:'white',height:"3rem",outline:'none'}} value={formData.email}
          onChange={handleChange}/>
                <textarea type="text" placeholder="Message*" name="message" className="font-1"style={{paddingLeft:'1.3rem',padding:'1rem',backgroundColor:'white',height:'-webkit-fill-available',outline:'none',resize:'none'}} value={formData.message}
          onChange={handleChange}/>

             </div>
            </div>
            <button
            className="cib-btn font-1"
            onClick={handleSubmit}
              style={{
                border: "1.5px",
                borderColor: "#CC203B",
                borderStyle: "solid",
                borderRadius: "50px",
                paddingTop: "0.7rem",
                paddingBottom: "0.8rem",
                paddingLeft: "1.6rem",
                paddingRight: "1.6rem",
                color: "#CC203B",
                fontSize: "1.1rem",
                marginTop: "2vw",
                fontWeight: 600,
                width:'max-content'
              }}
            >
              SEND MESSAGE
            </button>
            
        </div>
        <div className="ci-right">
          <div className="ci-right-inner">
            <p className="font-1" style={{fontSize:'1.15rem',fontWeight:'700',color:'#CC203B'}}>
            Contact Information
            </p>
           <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',paddingTop:'0.5rem',paddingBottom:'0.9rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
            <img src={img1} alt="" />
            <p className="font-1" style={{fontSize:'0.9rem',marginBottom:'0rem',marginLeft:'1rem'}}>www.fitnastichealth.com</p>
           </div>
           <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'0.9rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
            <img src={img2} alt="" />
            <p className="font-1" style={{fontSize:'0.9rem',marginBottom:'0rem',marginLeft:'1rem'}}>+91 8209512559</p>
           </div>
           <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'0.9rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
            <div style={{display:'flex',justifyContent:'start',alignItems:'start',borderRightWidth:'1.5px',borderRightColor:'#DBDCDC'}}>
            <img src={img3} alt="" />
            <p className="font-1" style={{fontSize:'0.9rem',marginBottom:'0rem',marginLeft:'1rem',paddingRight:'1.2rem'}}>NMIMS Mumbai, 2nd Floor, V. L, Pherozeshah Mehta Rd, Vile Parle West, Mumbai, Maharashtra 400056</p>

            </div>
            <div style={{display:'flex',justifyContent:'start'}}>
            <p className="font-1" style={{fontSize:'0.9rem',marginBottom:'0rem',marginLeft:'1rem'}}>1001, 10th Floor Lodha Aurum CHS Ltd, Kanjurmarg East, Mumbai, Maharashtra, 400042</p>
            </div>
           
            
           </div>
           <div style={{width:'100%',display:'flex',alignItems:'start',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'0.9rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
            
            <img src={img4} alt="" style={{marginTop:'1.3%'}} />
            <p className="font-1" style={{fontSize:'0.9rem',marginBottom:'0rem',marginLeft:'1rem',fontWeight:'700'}}>For Business Inquiries <br />
           <span style={{fontWeight:'400'}}> queries@fitnasticindia.com</span></p>
            
           </div>
           <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'1.5rem'}}>
            <img src={img2} alt=""  style={{visibility:'hidden'}}/>
            <p className="font-1" style={{fontSize:'0.9rem',marginBottom:'0rem',marginLeft:'1rem',fontWeight:'700'}}>Follow Us On</p>
             <a href="https://www.instagram.com/fitnastic.health/" target="_blank">
             <img src={insta} alt="" style={{marginLeft:'0.7rem',marginRight:'0.7rem',cursor:'pointer'}}/>
             </a>
           <a href="https://in.linkedin.com/company/fitnasticindia" target="_blank">
           <img src={linkdin} alt="" style={{cursor:'pointer'}} />
           </a>
           
           </div>

          </div>
          <Modal
              isOpen={showThankModal}
              // onRequestClose={closeModal}
              className="modal"
              overlayClassName="modal-overlay"
            >
              <ThankModal onClose={closeThankModal} />
            </Modal>

        </div>

      </div> : (
       <div>
        <div className="m-upper-container">
          
          <div style={{width:'85%',height:'90vh',position:'relative',left:'7.5%',display:'flex',flexDirection:'column',gap:'1rem',alignItems:'center'}}>
          <p className="font-2 " style={{textAlign:'center',fontSize:'1.9rem',paddingTop:'3rem',color:'#CC203B',marginBottom:'0rem'}}>Get In Touch</p>
          <p className="font-1 " style={{textAlign:'center',fontSize:'1.3rem',fontWeight:'400'}}>Feel free to reach out using the contact form below, and our team will get back to you as soon as possible.
          </p>
          <input type="text" placeholder="Name*" name="name" className="font-1" style={{paddingLeft:'1.3rem',padding:'1rem',backgroundColor:'white',height:"3rem",outline:'none',width:'100%'}} value={formData.name}
          onChange={handleChange}/>
                <input type="email" placeholder="Email*" name="email" className="font-1"style={{paddingLeft:'1.3rem',padding:'1rem',backgroundColor:'white',height:"3rem",outline:'none',width:'100%'}} value={formData.email}
          onChange={handleChange}/>
                <textarea type="text" placeholder="Message*" name="message" className="font-1"style={{paddingLeft:'1.3rem',padding:'1rem',backgroundColor:'white',height:'-webkit-fill-available',outline:'none',resize:'none',width:'100%'}} value={formData.message}
          onChange={handleChange}/>
           <button
            className="cib-btn font-1"
            onClick={handleSubmit}
              style={{
                border: "1.5px",
                borderColor: "#CC203B",
                borderStyle: "solid",
                borderRadius: "50px",
                paddingTop: "0.8rem",
                paddingBottom: "0.6rem",
                paddingLeft: "1.6rem",
                paddingRight: "1.6rem",
                color: "#CC203B",
                fontSize: "1.1rem",
                marginTop: "1rem",
                fontWeight: 600,
                width:'max-content'
              }}
            >
              SEND MESSAGE
            </button>
          </div>
          <Modal
              isOpen={showMobileThankModal}
              // onRequestClose={closeModal}
              className="modal"
              overlayClassName="modal-overlay"
            >
              <MobileThankModal onClose={closeMobileThankModal} />
            </Modal>
        
        </div>
        <div className="m-lower-container">
        <div style={{width:'85%',height:'auto',position:'relative',left:'7.5%',display:'flex',flexDirection:'column',gap:'0.5rem',alignItems:'center'}}>
        <p className="font-2 " style={{textAlign:'center',fontSize:'1.9rem',paddingTop:'3rem',color:'#CC203B',marginBottom:'0rem'}}>Contact Information</p>
        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'0.9rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
           <div style={{width:'2rem'}}><img src={img1} alt="" /></div> 
            <p className="font-1" style={{fontSize:'1.1rem',marginBottom:'0rem',marginLeft:'0.5rem'}}>www.fitnastichealth.com</p>
           </div>

        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'1.4rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
        <div style={{width:'2rem'}}><img src={img2} alt="" /></div> 
            <p className="font-1" style={{fontSize:'1.1rem',marginBottom:'0rem',marginLeft:'0.5rem'}}>+91 8209512559</p>
           </div>
           <div style={{width:'100%',display:'flex',alignItems:'start',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'1.4rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
        <div style={{width:'5.7rem'}}><img src={img3} alt="" /></div> 
            <p className="font-1" style={{fontSize:'1.1rem',marginBottom:'0rem',marginLeft:'0.5rem'}}>NMIMS Mumbai, 2nd Floor, V. L, Pherozeshah Mehta Rd, Vile Parle West, Mumbai, Maharashtra 400056. <br /> <br />
            1001, 10th Floor Lodha Aurum CHS Ltd, Kanjurmarg East, Mumbai, Maharashtra, 400042.
            </p>
           </div>  
           <div style={{width:'100%',display:'flex',alignItems:'start',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'1.4rem',borderBottomWidth:'1.5px',borderBottomColor:'#DBDCDC'}}>
            
           <div style={{width:'2rem'}}><img src={img4} alt="" style={{marginTop:'15%'}} /></div> 
            <p className="font-1" style={{fontSize:'1.1rem',marginBottom:'0rem',marginLeft:'0.7rem',fontWeight:'700'}}>For Business Inquiries <br />
           <span style={{fontWeight:'400'}}> queries@fitnasticindia.com</span></p>
            
           </div>
           <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'start',paddingTop:'0.9rem',paddingBottom:'0rem'}}>
            <img src={img2} alt=""  style={{visibility:'hidden'}}/>
            <p className="font-1" style={{fontSize:'1.1rem',marginBottom:'0rem',marginLeft:'2rem',fontWeight:'700'}}>Follow Us On</p>
             <a href="https://www.instagram.com/fitnastic.health/" target="_blank">
             <img src={Minsta} alt="" style={{marginLeft:'0.7rem',marginRight:'0.7rem',cursor:'pointer',width:'1.8rem'}}/>
             </a>
           <a href="https://in.linkedin.com/company/fitnasticindia" target="_blank">
           <img src={Mlinkdin} alt="" style={{cursor:'pointer',width:'1.8rem'}} />
           </a>
           
           </div>  

        </div>
        </div>
       </div>
       
      )}
    </>
  );
}

export default ContactInputBox;
