import React, { useState } from "react";
import "./Dashboard.css";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);

  const fetchData = async (url) => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
};
const postData = async (url, data) => {
  try {
      const response = await axios.post(url, data, {
          headers: {
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error posting data:', error);
      return null;
  }
};
// const fetchData = async (url) => {
//   try {
//       // Fetch the token from local storage
//       const token = localStorage.getItem('token');

//       // Make the API call with the token in the headers
//       const response = await axios.get(url, {
//           headers: {
//               'Authorization': `Bearer ${token}`
//           }
//       });
//       return response.data;
//   } catch (error) {
//       console.error('Error fetching data:', error);
//       return null;
//   }
// };

  const handleDownload = async () => {
    setLoading(true);

    const personalTrainingData = await fetchData(
      "https://prod-api.fitnastichealth.in/api/v1/leads/getTrainingDetails"
    );
    const nutritionData = await fetchData(
      "https://prod-api.fitnastichealth.in/api/v1/leads/getNutritionDetails"
    );
    const metabolicData = await fetchData(
      "https://prod-api.fitnastichealth.in/api/v1/leads/getMetabolicDetails"
    );
    const liveWorkoutData = await fetchData(
      "https://prod-api.fitnastichealth.in/api/v1/leads/getLiveWorkoutDetails"
    );
    const muscularBodyData = await fetchData(
      "https://prod-api.fitnastichealth.in/api/v1/info/getFormInfo"
    );
    const leadsByQuizData = await postData(
      'https://prod-api.fitnastichealth.in/api/v1/info/getLeadsByEntryType',
      { entry_type: 'quiz' }
  );
  
  const leadsByDirectData = await postData(
      'https://prod-api.fitnastichealth.in/api/v1/info/getLeadsByEntryType',
      { entry_type: 'direct' }
  );

    if (
      personalTrainingData 
      &&
      nutritionData &&
      metabolicData &&
      liveWorkoutData &&
      muscularBodyData &&
      leadsByQuizData &&
      leadsByDirectData
      
    ) {
      const personalTrainingSheet =
        XLSX.utils.json_to_sheet(personalTrainingData);
      const nutritionSheet = XLSX.utils.json_to_sheet(nutritionData);
      const metabolicSheet = XLSX.utils.json_to_sheet(metabolicData);
      const liveWorkoutSheet = XLSX.utils.json_to_sheet(liveWorkoutData);
      const muscularBodySheet = XLSX.utils.json_to_sheet(muscularBodyData);
      const leadsByQuizDataSheet = XLSX.utils.json_to_sheet(leadsByQuizData);
      const leadsByDirectDataSheet = XLSX.utils.json_to_sheet(leadsByDirectData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        personalTrainingSheet,
        "Personal Training"
      );
      XLSX.utils.book_append_sheet(wb, nutritionSheet, "Nutrition Plan");
      XLSX.utils.book_append_sheet(wb, metabolicSheet, "Metabolic Report");
      XLSX.utils.book_append_sheet(wb, liveWorkoutSheet, "Live Workout");
      XLSX.utils.book_append_sheet(wb, muscularBodySheet, "7-Days Live Workout");
      XLSX.utils.book_append_sheet(wb, leadsByQuizDataSheet, "Health Report with Quiz");
      XLSX.utils.book_append_sheet(wb, leadsByDirectDataSheet, "Health Report Direct");


      XLSX.writeFile(wb, "Programs_Leads.xlsx");
    }

    setLoading(false);
  };

  return (
    <div className="dashboard-container">
      <h1>Welcome to Fitnastic Admin Dashboard</h1>
      <button
        onClick={handleDownload}
        className="download-button"
        disabled={loading}
      >
        {loading ? "Generating..." : "Download Excel File"}
      </button>
    </div>
  );
};

export default Dashboard;
