import React from "react";
import { Image, Container } from "react-bootstrap";
import progressBannerBox from "../../images/progressBanner.png";
import Timeline from "../Timeline/Timeline";
import { useState, useEffect, useRef } from 'react';
import './ProgressBox.css'
import timelineprogress from '../../images/timelineprogress.svg'
import progprop1 from "../../images/timelineimages/progprop1.svg";
import progprop2 from "../../images/timelineimages/progprop2.svg";
import progprop3 from "../../images/timelineimages/progprop3.svg";
import progprop4 from "../../images/timelineimages/progprop4.svg";
import progprop5 from "../../images/timelineimages/progprop5.svg";
import progprop6 from "../../images/Cipla_logo.png";
import progprop7 from "../../images/timelineimages/progprop7.svg";

import line1 from "../../images/line1.svg";
import line2 from "../../images/line2.svg";
import line3 from "../../images/line3.svg";
import line4 from "../../images/line4.svg";
import line5 from "../../images/line5.svg";
import line6 from "../../images/line6.svg";
import line7 from "../../images/reddd.png";
import prop77 from "../../images/nmimslogo.png";

import AOS from 'aos';
import 'aos/dist/aos.css';

function ProgressBox() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  return (
    <>
      {
        (!isMobile) ? <>
          <Image src={progressBannerBox} fluid />
          <Timeline />
        </>
          : <>
            <div className="m-pb-main">
              <div className="m-pb-top">
                <p className="m-pb-top-text font-2" style={{paddingLeft:'4%',paddingRight:'4%'}}>Hey, it’s me - Rishi,
                  <br />
                  the founder of Fitnastic & this is my story…</p>
              </div>

              <div className="m-pb-bottom">
                {/* line 1 */}
                <div className="m-pb-box">
                  <div className="m-pbb-line"
                    style={{
                      backgroundImage: `url(${line1})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginLeft: '0.5rem'
                    }}
                  />
                  <div data-aos="fade-up" data-aos-easing="linear" className="m-pbb-side">
                    <img src={progprop1} alt="" style={{
                      height: '3.5rem',
                      width: '3rem'
                    }} />
                    <p style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>I lost my dad in my childhood (8th std.) to oral cancer</p>
                  </div>
                </div>

                {/* line 2 */}
                <div className="m-pb-box">
                  <div className="m-pbb-line"
                    style={{
                      backgroundImage: `url(${line2})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginLeft: '0.5rem'
                    }}
                  />
                  <div data-aos="fade-up" data-aos-easing="linear" className="m-pbb-side">
                    <img src={progprop2} alt="" style={{
                      height: '3.5rem',
                      width: '3rem'
                    }} />
                    <p style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>I was diagnosed with Non-Alcoholic Fatty Liver Disease (NAFLD) symptoms in 10th std</p>
                  </div>
                </div>

                {/* line 3 */}
                <div className="m-pb-box">
                  <div className="m-pbb-line"
                    style={{
                      backgroundImage: `url(${line3})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginLeft: '0.5rem'
                    }}
                  />
                  <div data-aos="fade-up" data-aos-easing="linear" className="m-pbb-side">
                    <img src={progprop3} alt="" style={{
                      height: '3.5rem',
                      width: '3rem'
                    }} />
                    <p style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>I started working out & following a diet not just to lose weight, but to battle NAFLD</p>
                  </div>
                </div>

                {/* line 4 */}
                <div className="m-pb-box">
                  <div className="m-pbb-line"
                    style={{
                      backgroundImage: `url(${line4})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginLeft: '0.5rem'
                    }}
                  />
                  <div data-aos="fade-up" data-aos-easing="linear" className="m-pbb-side">
                    <img src={progprop4} alt="" style={{
                      height: '3.5rem',
                      width: '3rem'
                    }} />
                    <p style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>In 2013 - I had another setback, a major accident left me with the implantation of a metal rod. (I couldn’t do any major physical activity for
                      18 months)</p>
                  </div>
                </div>

                {/* line 5 */}
                <div className="m-pb-box">
                  <div className="m-pbb-line"
                    style={{
                      backgroundImage: `url(${line5})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginLeft: '0.5rem'
                    }}
                  />
                  <div data-aos="fade-up" data-aos-easing="linear" className="m-pbb-side">
                    <img src={prop77} alt="" style={{
                      height: '4rem',
                      width: '4rem'
                    }} />
                    <p style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>Five years later, I graduated with B.PHARMA + MBA from NMIMS</p>
                  </div>
                </div>

                {/* line 6 */}
                <div className="m-pb-box">
                  <div className="m-pbb-line"
                    style={{
                      backgroundImage: `url(${line6})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      marginLeft: '0.5rem'
                    }}
                  />
                  <div data-aos="fade-up" data-aos-easing="linear" className="m-pbb-side">
                    <img src={progprop6} alt="" style={{
                      height: '1.5rem',
                      width: '4rem',
                      marginTop:'7.5%'
                    }} />
                    <p style={{ fontSize: '1.2rem', marginTop: '0.6rem' }}>While working at CIPLA I saw kids from 5 yrs to 60 yrs, rich to poor all suffering from cancer. That’s when it hit me - people spend lakhs on cure, but don’t focus on prevention. And looking back, it hit me - Health Over Fitness</p>
                  </div>
                </div>

                {/* line 7 */}
                <div className="m-pb-box">
                <div className="m-pbb-line"
                    style={{
                      backgroundImage: `url(${line7})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      marginLeft: '0.5rem'
                    }}
                  />
                  <div data-aos="fade-up" data-aos-easing="linear" className="m-pbb-side">
                    <img src={progprop7} alt="" style={{
                      height: '3.5rem',
                      width: '3.5rem'
                    }} />
                    <p style={{ fontSize: '1.2rem', marginTop: '0.5rem' }}>Founded Fitnastic in 2020 with the belief that Health &gt; Fitness</p>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  );
}




export default ProgressBox;
