import React, { useEffect, useState, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NewDiff.css";

import asset5 from "../../images/differencesImages/Asset5.svg";
import asset6 from "../../images/differencesImages/Asset 6.svg";
import asset7 from "../../images/differencesImages/Asset 7.svg";
import asset8 from "../../images/differencesImages/Asset 8.svg";
import asset10 from "../../images/differencesImages/Asset 10.svg";
import asset11 from "../../images/differencesImages/Asset 11.svg";
import asset12 from "../../images/differencesImages/Asset 12.svg";
import asset13 from "../../images/differencesImages/Asset 13.svg";

const NewDiff = ({ details }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 200 });
  }, []);

  return (
    <>
      {!isMobile ? (
        <div>
          {
            <div className="nd-comparison-container">
              <div className="nd-column">
                <div className="nd-firstDiv">
                <h2 className="font-1">When You Go To The Gym</h2>
                <div className="nd-assesment">
                  <p className="nd-p font-1">
                    We’re talking about some of the best coaches in India,
                    these are not your nearby gym trainers - their assessments
                    won’t even include all this
                  </p>
                </div>
                </div>
                <div className="nd-assessment font-1">
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset8}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text ">
                    <h3>Pre assessment form</h3>
                    <p>
                      To understand your fitness background, your current
                      lifestyle and any past or current injuries.
                    </p>
                  </div>
                </div>
                <div className="nd-assessment font-1">
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset6}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text">
                    <h3>Demo Workout Session</h3>
                    <p>
                      Assess various aspects of your fitness. Strength,
                      Flexibility, Mobility & the seriousness of your injuries
                      (if any).
                    </p>
                  </div>
                </div>
                <div className="nd-assessment font-1">
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset5}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text">
                    <h3>Movement Analysis</h3>
                    <p>What exercises are you capable of doing.</p>
                  </div>
                </div>
                <div className="nd-assessment font-1">
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset7}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text">
                    <h3>Exercise Programming</h3>
                    <p>Roadmap on how you can achieve your fitness goals.</p>
                  </div>
                </div>
              </div>

              <div className="nd-column">
              <div className="nd-firstDiv">
              <h2 className="font-1">When You Train With Us</h2>
                <div
                  className="nd-assessment font-1"
                  style={{ border: "none" }}
                >
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset13}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text">
                    <h3>Fitnastic Metabolic Health Assessment</h3>
                    <p>
                      Calculates disease risk scores for lifestyle diseases like
                      diabetes, hypertension, Stroke, Obesity, NAFLD etc along
                      with your current fitness levels.
                    </p>
                  </div>
                </div>
              </div>
                <div className="nd-assessment font-1">
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset12}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text">
                    <h3>Diet | Lifestyle Analysis</h3>
                    <p>
                      Based on your disease risk scores & current lifestyle, our
                      clinical dieticians give you a customized diet plan & tips
                      to improve your overall health.
                    </p>
                  </div>
                </div>
                <div className="nd-assessment font-1">
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset11}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text">
                    <h3>Demo Workout Sessions</h3>
                    <p>
                      Our ACSM certified coaches assess various aspects of your
                      fitness. Strength, Flexibility, Mobility & the seriousness
                      of your injuries (if any).
                    </p>
                  </div>
                </div>
                <div className="nd-assessment font-1">
                  <div className="nd-icon">
                    <img
                      style={{}}
                      width="50px"
                      height="50px"
                      src={asset10}
                      alt=""
                    />
                  </div>
                  <div className="nd-text">
                    <h3>Movement Analysis</h3>
                    <p>What exercises are you capable of doing.</p>
                  </div>
                </div>
                <div className="nd-assessment font-1">
                  <img
                    style={{}}
                    width="50px"
                    height="50px"
                    src={asset7}
                    alt=""
                  />
                  <div className="nd-icon"></div>
                  <div className="nd-text">
                    <h3>Exercise Programming</h3>
                    <p>Roadmap on how you can achieve your fitness goals.</p>
                  </div>
                </div>
              </div>
            </div>
            // details.map((detail, idx) => (
            //     <div key={idx} style={{
            //         display:'flex',
            //         borderBottom:'1px solid #CC203B',
            //         marginTop:'20px',
            //         paddingBottom: '15px'
            //     }}>
            //       <div style={{
            //         }}>
            //         <img style={{}} width='60px' height = '60px' src={detail.img} alt="" />
            //       </div>

            //       <div style={{
            //         marginLeft:'20px',
            //         }}>
            //         <p className='font-1'style={{fontWeight:'bold', fontSize:'20px', marginBottom:'0rem'}}>
            //             {detail.name}
            //         </p>
            //         <p className='font-1' style={{width:'350px', fontSize:'15px'}}>
            //             {detail.description}
            //         </p>
            //       </div>
            //     </div>
            // ))
          }
        </div>
      ) : (
        <>
          <div>
            {details.map((detail, idx) => (
              <div
                data-aos="fade-up"
                data-aos-easing="linear"
                key={idx}
                style={{
                  display: "flex",
                  borderBottom: "1px solid #CC203B",
                  marginTop: "20px",
                  paddingBottom: "15px",
                  marginInline: "1rem",
                }}
              >
                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <img
                    style={{
                      width: "20vw",
                    }}
                    src={detail.img}
                    alt=""
                  />
                </div>

                <div
                  style={{
                    marginLeft: "20px",
                    width: "70%",
                  }}
                >
                  <p
                    className="font-1"
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginBottom: "0rem",
                    }}
                  >
                    {detail.name}
                  </p>
                  <p className="font-1" style={{ fontSize: "15px" }}>
                    {detail.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
    // <div className="nd-comparison-container">
    //   <div className="nd-column">
    //     <h2 className="font-1">When You Go To The Gym</h2>
    //     <div className="nd-assesment">
    //       <p className="nd-p font-1">
    //         (We’re talking about some of the best coaches in India, these are
    //         not your nearby gym trainers - their assessments won’t even include
    //         all this)
    //       </p>
    //     </div>
    //     <div className="nd-assessment font-1">
    //         <img style={{}} width="60px" height="60px" src={asset8} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text ">
    //         <h3>Pre assessment form</h3>
    //         <p>
    //           To understand your fitness background, your current lifestyle and
    //           any past or current injuries.
    //         </p>
    //       </div>
    //     </div>
    //     <div className="nd-assessment font-1">
    //         <img style={{}} width="60px" height="60px" src={asset6} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text">
    //         <h3>Demo Workout Session</h3>
    //         <p>
    //           Assess various aspects of your fitness. Strength, Flexibility,
    //           Mobility & the seriousness of your injuries (if any).
    //         </p>
    //       </div>
    //     </div>
    //     <div className="nd-assessment font-1">
    //         <img style={{}} width="60px" height="60px" src={asset5} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text">
    //         <h3>Movement Analysis</h3>
    //         <p>What exercises are you capable of doing.</p>
    //       </div>
    //     </div>
    //     <div className="nd-assessment font-1">
    //         <img style={{}} width="60px" height="60px" src={asset7} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text">
    //         <h3>Exercise Programming</h3>
    //         <p>Roadmap on how you can achieve your fitness goals.</p>
    //       </div>
    //     </div>
    //   </div>

    //   <div className="nd-column">
    //     <h2 className="font-1">When You Train With Us</h2>
    //     {/* <div className="nd-assesment">
    //       <p>
    //         (We’re talking about some of the best coaches in India, these are
    //         not your nearby gym trainers - their assessments won’t even include
    //         all this)
    //       </p>
    //     </div> */}
    //     <div className="nd-assessment font-1" style={{border: 'none'}}>
    //         <img style={{}} width="60px" height="60px" src={asset13} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text">
    //         <h3>Fitnastic Metabolic Health Assessment</h3>
    //         <p>
    //           Calculates disease risk scores for lifestyle diseases like
    //           diabetes, hypertension, Stroke, Obesity, NAFLD etc along with your
    //           current fitness levels.
    //         </p>
    //       </div>
    //     </div>
    //     <div className="nd-assessment font-1">
    //         <img style={{}} width="60px" height="60px" src={asset12} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text">
    //         <h3>Diet | Lifestyle Analysis</h3>
    //         <p>
    //           Based on your disease risk scores & current lifestyle, our
    //           clinical dieticians give you a customized diet plan & tips to
    //           improve your overall health.
    //         </p>
    //       </div>
    //     </div>
    //     <div className="nd-assessment font-1">
    //         <img style={{}} width="60px" height="60px" src={asset11} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text">
    //         <h3>Demo Workout Sessions</h3>
    //         <p>
    //           Our ACSM certified coaches assess various aspects of your fitness.
    //           Strength, Flexibility, Mobility & the seriousness of your injuries
    //           (if any).
    //         </p>
    //       </div>
    //     </div>
    //     <div className="nd-assessment font-1">
    //       <div className="nd-icon">
    //         <img style={{}} width="60px" height="60px" src={asset10} alt="" />
    //       </div>
    //       <div className="nd-text">
    //         <h3>Movement Analysis</h3>
    //         <p>
    //         What exercises are you capable of doing.
    //         </p>
    //       </div>
    //     </div>
    //     <div className="nd-assessment font-1">
    //         <img style={{}} width="60px" height="60px" src={asset7} alt="" />
    //       <div className="nd-icon">
    //       </div>
    //       <div className="nd-text">
    //         <h3>Exercise Programming</h3>
    //         <p>
    //         Roadmap on how you can achieve your fitness goals.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default NewDiff;
