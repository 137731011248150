import React from "react";
import newLogo from "../../images/Logo/LabelledLogo.svg";
import logo from "../../images/Logo/simpleLogo.svg";
import { Button } from "react-bootstrap";
import "../../components/NavbarBox/NavBar.css";
import { Link, NavLink,useLocation } from "react-router-dom";
import { useState,useEffect } from "react";
import cross from '../../images/MenuBar/menu-cross.svg'
import linkdin from '../../images/footer-linkdin.png'
import insta from '../../images/footer-insta.png'

function Navbar() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation(); // Get the current location object
  const isActive = location.pathname === '/contact'; 

  const toggleDrawer = () => {
    setShowDrawer(prevState => !prevState);
  
    if (!showDrawer) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };
  
  useEffect(() => {
    // Ensure to clean up the overflow property
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="stickyNavbar">
      <div className="mainNav">
        <div className="navBar">
          <div className="brandLogo">
            <NavLink exact to="/home" style={{ textDecoration: "none" }}>
              <div className="brandImg">
                <img src={logo} className="logoImage2" alt="Logo" />
              </div>
            </NavLink>
          </div>
          <div className="navContent">
            <div className="navLinks">
              <NavLink
                to="/about"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#063533" : "rgb(165, 161, 161)",
                })}
                onClick={() => setShowDrawer(false)}
                className="navText font-1"
              >
                ABOUT
              </NavLink>
              <NavLink
                to="/ourTech"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#063533" : "rgb(165, 161, 161)",
                })}
                onClick={() => setShowDrawer(false)}
                className="navText font-1"
              >
                OUR TECH
              </NavLink>
              <NavLink
                to="/programs"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#063533" : "rgb(165, 161, 161)",
                })}
                onClick={() => setShowDrawer(false)}
                className="navText font-1"
              >
                PROGRAMS
              </NavLink>
              <Link to="/contact">
              <Button onClick={scrollToTop} variant="outline-danger" className={`navText1 outlineBtn ${isActive ? 'activeBtn' : ''}`} >
                <div className="font-1 outlineBtnText">GET IN TOUCH</div>
              </Button>
              </Link>
             
              <i className="ri-menu-3-line" onClick={toggleDrawer}></i>
            </div>
          </div>
        </div>
        {showDrawer && (
          <div className="drawerMenu">
            <div className="m-menu-div">
              <img onClick={toggleDrawer} src={cross} alt="" style={{paddingRight:'20px',marginTop:'2rem',marginBottom:"3rem"}}/>
            <NavLink to="/about" className="drawerMenuItem font-1" style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#CC203B" : "#063533",
                })} onClick={toggleDrawer} >
              ABOUT US
            </NavLink>
            <NavLink to="/ourTech" className="drawerMenuItem font-1"  style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#CC203B" : "#063533",
                })} onClick={toggleDrawer}>
              OUR TECH
            </NavLink>
            <NavLink to="/programs" className="drawerMenuItem font-1"  style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#CC203B" : "#063533",
                })} onClick={toggleDrawer}>
              PROGRAMS
            </NavLink>
            <Link to="/contact"  className={`drawerMenuItem font-1 ${isActive ? 'activeClass' : ''}`}  onClick={toggleDrawer}>
              CONTACT US
            </Link>
            <div style={{display:'flex',flexDirection:'column',alignItems:"end",marginTop:'2rem'}}> 
              <p className="font-1" style={{fontWeight:'600',color:"#063533",paddingRight:'20px',fontSize:'0.9rem'}}>FOLLOW US ON</p>
               <div style={{display:'flex',alignItems:"center",justifyContent:'end'}}>
                <a href="https://www.instagram.com/fitnastic.health/">
                <img src={insta} alt="" style={{height:'2rem',marginRight:'20px'}}/>
                </a>
               <a href="https://in.linkedin.com/company/fitnasticindia">
               <img src={linkdin} alt="" style={{height:'2rem',marginRight:'20px'}} />
               </a>
                
               </div>
            </div>
            </div>
        
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
