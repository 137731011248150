import React from 'react'
import './Dashboard.css'
import EmpSideProfile from '../../Components/EmpSideProfile/EmpSideProfile'
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className='dhr-main-container-dash'>
    <div className='dhr-main-container-left'>
     <EmpSideProfile/>
    </div>
    <div className='demp-main-container-right'>
     <div className='demp-assessment-form'>
       <h3 className='font-1' style={{color:'white',textAlign:'center',marginBottom:'0rem'}}>Fill the Free Assessment Form</h3>
       <Link to="/freeHealthReport">
              <button
                style={{
                  border: "2px",
                  borderColor: "#FFFFFF",
                  borderStyle: "solid",
                  borderRadius: "50px",
                  paddingTop: "0.7rem",
                  paddingBottom: "0.7rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  // marginTop: "5%",
                  color: "#FFFFFF",
                  fontSize: "1.5vw",
                  fontWeight: 550,
                }}
                className="font-1"
              >
               Start Now
              </button>
            </Link>
     </div>
    </div>
</div>
  )
}

export default HomePage