import React from "react";
import classroom from "../../../images/TechPageImages/tbiclassroomimage.png";
import "./TechBheindAI.css";

import ChroniceManagement from "../../../images/TechPageImages/TechBehindAi/ChronicManagement.svg";
import jawans from "../../../images/TechPageImages/TechBehindAi/jawansMobile.svg";
import Diabetes from "../../../images/TechPageImages/TechBehindAi/Diabetes.svg";
import EnergyLeves from "../../../images/TechPageImages/TechBehindAi/EnergyLeves.svg";
import Hypertension from "../../../images/TechPageImages/TechBehindAi/Hypertension.svg";
import LiverDisease from "../../../images/TechPageImages/TechBehindAi/LiverDisease.svg";
import LongetivityLife from "../../../images/TechPageImages/TechBehindAi/LongetivityLife.svg";
import MentalHealth from "../../../images/TechPageImages/TechBehindAi/MentalHealth.svg";
import Person from "../../../images/TechPageImages/TechBehindAi/Person.svg";
import Stroke from "../../../images/TechPageImages/TechBehindAi/Stroke.svg";
import WeightManagement from "../../../images/TechPageImages/TechBehindAi/WeightManagement.svg";
import { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const TechBheindAI = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [animationPlayed, setAnimationPlayed] = useState(false);

  const handleReadMore = () => {
    setShowMore(true);
    setAnimationPlayed(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 600});
  }, []);


  return (
    <>
      {!isMobile ? (
        <div className="tbi-main">
          <div
            className="tbi-top"
            style={{ backgroundImage: `url(${classroom})` }}
          >
            <div className="tbi-top-content">
              <p className="font-2 tbi-tc-txt" style={{ lineHeight: 1.2 }}>
                Over 1000+ Of Our <br />
                Fittest Jawans Successfully Tested
              </p>
            </div>
          </div>
          <div className="tbi-bottom">
            <div className="tbi-bottom-left">
              <div className="tbi-bottom-left-txt">
                <p className="tbi-blt-1">The Tech Behind</p>
                <p className="tbi-blt-2 font-2">Smart Bio AI Health Reports</p>
              </div>
            </div>
            <div
              className="tbi-bottom-right"
              style={{ fontWeight: "400", fontSize: "1.3vw" }}
            >
              <p className="font-1">
                The tech we’ve created is called “Systems Biology Driven AI”. It
                was created to not just assess your physical health, but also
                your{" "}
                <span style={{ fontWeight: "bold" }}>Metabolic Health.</span>
              </p>
              <p className="font-1" style={{ fontWeight: "bold" }}>
                In layman's terms, metabolic health refers to the proper
                functioning of cells that handle energy within your body.
              </p>
              <p className="font-1">
                Our technology not only provides you with a fitness and
                metabolic health score, but it also identifies your{" "}
                <span style={{ fontWeight: "bold" }}>
                  risk of developing diseases
                </span>{" "}
                such as{" "}
                <span style={{ fontWeight: "bold" }}>
                  PCOS, obesity, hypertension, diabetes, and 5+ other
                  lifestyle-related diseases.
                </span>
              </p>
              <p className="font-1">
                Along with this, we determine how your current lifestyle
                contributes to the development of those diseases and how we can
                prevent them with minor lifestyle changes.
              </p>
            </div>
          </div>
          <div className="tbi-meta font-1" style={{ fontWeight: "400" }}>
            <div className="tbi-meta-1">
              <div className="font-2 tbi-m-1">
                <p
                  style={{
                    textAlign: "center",
                    fontStyle: "italic",
                    width: "100%",
                  }}
                >
                  Metabolically Unhealthy
                </p>
              </div>
              <div className="tbi-m-2">
                <div className="tbi-meta-item">
                  <img className="tbi-meta-item-img" src={Person} alt="" />
                  <p className="tbi-meta-item-txt">Obesity</p>
                </div>
                <div className="tbi-meta-item">
                  <img className="tbi-meta-item-img" src={Diabetes} alt="" />
                  <p className="tbi-meta-item-txt">Diabetes</p>
                </div>
                <div className="tbi-meta-item">
                  <img
                    className="tbi-meta-item-img"
                    src={Hypertension}
                    alt=""
                  />
                  <p className="tbi-meta-item-txt">Hypertension</p>
                </div>
              </div>
              <div className="tbi-m-3">
                <div className="tbi-meta-item">
                  <img className="tbi-meta-item-img" src={Stroke} alt="" />
                  <p className="tbi-meta-item-txt">Cardiac Stroke</p>
                </div>
                <div className="tbi-meta-item">
                  <img
                    className="tbi-meta-item-img"
                    src={LiverDisease}
                    alt=""
                  />
                  <p className="tbi-meta-item-txt">
                    Liver Disease & a lot more…
                  </p>
                </div>
              </div>
            </div>
            <div className="tbi-meta-line"></div>
            <div className="tbi-meta-2">
              <div className="font-2 tbi-m-1">
                <p
                  style={{
                    fontStyle: "italic",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Metabolically Healthy
                </p>
              </div>
              <div className="tbi-m-2">
                <div className="tbi-meta-item">
                  <img
                    className="tbi-meta-item-img"
                    src={ChroniceManagement}
                    alt=""
                  />
                  <p className="tbi-meta-item-txt">
                    Reduced risk of chronic diseases
                  </p>
                </div>
                <div className="tbi-meta-item">
                  <img className="tbi-meta-item-img" src={EnergyLeves} alt="" />
                  <p className="tbi-meta-item-txt">Improved energy levels</p>
                </div>
                <div className="tbi-meta-item">
                  <img
                    className="tbi-meta-item-img"
                    src={WeightManagement}
                    alt=""
                  />
                  <p className="tbi-meta-item-txt">Better weight management</p>
                </div>
              </div>
              <div className="tbi-m-3">
                <div className="tbi-meta-item">
                  <img
                    className="tbi-meta-item-img"
                    src={LongetivityLife}
                    alt=""
                  />
                  <p className="tbi-meta-item-txt">
                    Longevity of life - lowers risk of premature death
                  </p>
                </div>
                <div className="tbi-meta-item">
                  <img
                    className="tbi-meta-item-img"
                    src={MentalHealth}
                    alt=""
                  />
                  <p className="tbi-meta-item-txt">
                    Improved Mental Health & a lot more…
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="m-tba-container">
          <div style={{ width: "100%", height: "auto" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-evenly",
                marginTop: "10%",
              }}
            >
              <div style={{ padding: "3%" }}>
                <h1 className="font-1 m-tba-heading2">The Tech Behind</h1>
                <h1 className="font-2 m-tba-heading1">Smart Bio AI Health</h1>
                <h1 className="font-2 m-tba-heading1">Reports</h1>
              </div>
              <div className="font-1 m-tba-midPhrase" style={{ width: "95%" }}>
                <p>
                  The tech we’ve created is called “Systems Biology Driven AI”.
                  It was created to not just assess your physical health, but
                  also your <strong>Metabolic Health.</strong>
                </p>
                <p style={{ marginBottom: "0rem" }}>
                  <strong>
                    In layman's terms, metabolic health refers to the proper
                    functioning of cells that handle energy within your body.
                  </strong>
                </p>
                {showMore && (
                  <p
                    // data-aos={animationPlayed ? "fade-in" : ""}
                    style={{ marginBottom: "2rem" }}
                  >
                    Our technology not only provides you with a fitness and
                    metabolic health score, but it also identifies your{" "}
                    <strong> risk of developing diseases</strong> such as{" "}
                    <strong>
                      PCOS, obesity, hypertension, diabetes, and 5+ other
                      lifestyle-related diseases. 
                    </strong>
                    <br />
                    <br />
                    Along with this, we determine how your current lifestyle
                    contributes to the development of those diseases and how we
                    can prevent them with minor lifestyle changes.
                  </p>
                )}
                {!showMore && (
                  <button
                    onClick={handleReadMore}
                    className="font-2"
                    style={{ marginBottom: "2rem" }}
                  >
                    Read more...
                  </button>
                )}
              </div>

              {/* <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:'#F3F3F4',paddingTop:'17%',paddingBottom:'5%'}}>
              <div>
              <h1 className="font-1 m-wellness-heading2" style={{marginBottom:'0rem'}}>
              Benefits Of
              </h1>
              <h1 className="font-2 m-wellness-heading1">Employee Wellness</h1>
              <h1 className="font-2 m-wellness-heading1">Programs</h1>
              </div>
              <div className='font-1 midPhrase' style={{ width: "82%",paddingTop:'4%' }}>
              <p>
              Studies have shown that Wellness Programs can reduce absenteeism by an average of <span style={{color:'#CC203B',fontWeight:'700',fontSize:'1.6rem',lineHeight:'1'}}> 25.3%</span> 
              </p>
            </div>
            <div className='font-1 midPhrase' style={{ width: "82%",paddingTop:'4%',borderTopWidth:'1.4px',borderTopColor:'#CC203B' }}>
              <p>
              <span style={{color:'#CC203B',fontWeight:'700',fontSize:'1.6rem',lineHeight:'1'}}> 66%</span> of employees report increased productivity after participating in Employee Wellness Programs
              </p>
            </div>
            <div className='font-1 midPhrase' style={{ width: "82%",paddingTop:'4%',borderTopWidth:'1.4px',borderTopColor:'#CC203B' }}>
              <p>
              <span style={{color:'#CC203B',fontWeight:'700',fontSize:'1.6rem',lineHeight:'1'}}> 74%</span> of businesses with Holistic Wellness Programs report higher employee satisfaction
              </p>
            </div>     
            </div> */}
              <div
                style={{
                  width: "100%",
                  height: "auto",
                  backgroundImage: `url(${jawans})`,
                  backgroundSize: "cover",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  paddingTop: "3rem",
                  paddingBottom: "25rem",
                  backgroundPositionX: "100%",
                }}
              >
                <div>
                  <h1
                    className="font-2 m-tba-heading1"
                    style={{ color: "white", lineHeight: "1.2" }}
                  >
                    Over 1000+ Of Our <br />
                    Fittest Jawans <br /> Successfully Tested
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#CC203B",
                width: "100%",
                height: "auto",
                marginTop: "-0.5rem",
                paddingTop: "4rem",
                paddingBottom: "2rem",
              }}
            >
              <h1 data-aos = "fade-up" data-aos-easing="linear" className="font-2 m-tba-heading1" style={{ color: "white" }}>
                Metabolically Unhealthy
              </h1>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  position: "relative",
                  left: "5%",
                  borderBottomWidth: "1.5px",
                  borderBottomColor: "white",
                  paddingTop: "1rem",
                  paddingBottom: "2rem",
                  paddingLeft: "20%",
                }}
              >
                <div data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <img className="tbi-meta-item-img" src={Person} alt="" />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Obesity
                  </p>
                </div>
                <div
                data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <img className="tbi-meta-item-img" src={Diabetes} alt="" />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Diabetes
                  </p>
                </div>
                <div
                data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <img
                    className="tbi-meta-item-img"
                    src={Hypertension}
                    alt=""
                  />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Hypertension
                  </p>
                </div>
                <div
                data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <img className="tbi-meta-item-img" src={Stroke} alt="" />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Cardiac <br />
                    Stroke
                  </p>
                </div>
                <div
                data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <img
                    className="tbi-meta-item-img"
                    src={LiverDisease}
                    alt=""
                  />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Liver <br />
                    Disease
                  </p>
                </div>
              </div>

              <h1  data-aos = "fade-up" data-aos-easing="linear"
                className="font-2 m-tba-heading1"
                style={{ color: "white", marginTop: "3rem" }}
              >
                Metabolically Healthy
              </h1>
              <div
                style={{
                  width: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  position: "relative",
                  left: "5%",
                  paddingTop: "1rem",
                  paddingBottom: "2rem",
                  paddingLeft: "18%",
                }}
              >
                <div
                 data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  <img
                    className="tbi-meta-item-img"
                    src={ChroniceManagement}
                    alt=""
                  />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Reduced risk of
                    <br /> chronic diseases
                  </p>
                </div>
                <div
                 data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  <img
                    className="tbi-meta-item-img"
                    src={WeightManagement}
                    alt=""
                  />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Better weight
                    <br /> management
                  </p>
                </div>
                <div
                 data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  <img
                    className="tbi-meta-item-img"
                    src={LongetivityLife}
                    alt=""
                  />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Lowers risk of
                    <br />
                    premature death
                  </p>
                </div>
                <div
                 data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  <img className="tbi-meta-item-img" src={EnergyLeves} alt="" />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Improved
                    <br />
                    energy levels
                  </p>
                </div>
                <div
                 data-aos = "fade-up" data-aos-easing="linear"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "2rem",
                    paddingTop: "0.7rem",
                    paddingBottom: "0.7rem",
                  }}
                >
                  <img
                    className="tbi-meta-item-img"
                    src={MentalHealth}
                    alt=""
                  />
                  <p
                    className="tbi-m-meta-item-txt"
                    style={{ marginBottom: "0rem", textAlign: "start" }}
                  >
                    Improved
                    <br />
                    Mental Health
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TechBheindAI;
