import React from 'react'
// import NavbarComponent from '../components/NavbarComponent';
import ImageComponent from '../components/IntroBox/IntroBox';
import FigureBox from '../components/FigureBox/FigureBox';
import MottoBox from '../components/MottoBox/MottoBox';
import ProgressBox from '../components/timelinecontainer/ProgressBox';
import HealthReportBox from '../components/HealthReportBox/HealthReportBox';
import Footer from '../components/footer/Footer';
import TestimonialTest from '../components/Testimonial/Testimonial';
import Journey from '../components/journey/Journey';
import ProgramsComponent from '../components/ProgramsComponent';
import OurDifferenceComponent from '../components/OurDifferences/OurDifferenceComponent';
// import GridCarousel from '../components/GridCarousel/GridCarousel';
// import BrandsTable from '../components/BrandsTable/BrandsTable';
// import NewDiff from '../components/NewDiff/NewDiff';

export const HomePage = () => {
  return (
    <div>

    <ImageComponent />
    <ProgramsComponent/>
    <FigureBox/>
    <Journey/>
    <ProgressBox/>
    <HealthReportBox/>
    <MottoBox/>
    {/* <NewDiff/> */}
    <OurDifferenceComponent/>
    <TestimonialTest/>
    <Footer/>
    
    </div>

  )
}
