import React from 'react'
import './TechJoinProgram.css'
import tjpBg from '../../../images/TechPageImages/TechJoinProgram/techJoinProgramBg.svg'
import { useState,useEffect } from 'react'
import mbg from '../../../images/TechPageImages/TechJoinProgram/tjpBg.svg'
import { Link } from 'react-router-dom'

const TechJoinProgram = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const scrollToTop = () => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    
    <>
    {!isMobile?<div className="tjp-container" style={{backgroundImage:`url(${tjpBg})`}}>
        <div className="tjp-content">
            <div className="tjp-heading font-1">Get Your Health Reports <span className='font-2 tjp-heading-span'>TODAY!</span></div>
            <div className="tjp-subHeading font-1">Join 1000+ individuals and companies who are actively using Fitnastic’s tech to get accurate health reports and disease predictions.</div>
            <div className="tjp-btn">
           <Link
           to={'/programs'}
           >
            <button 
            onClick={scrollToTop}
            style={{
                border: '2px', 
                borderColor:'#FFFFFF', 
                borderStyle: 'solid', 
                borderRadius: '50px', 
                paddingTop:'10px', 
                paddingBottom:'12px', 
                paddingLeft:'20px', 
                paddingRight:'20px',
                marginTop: '4rem',
                color:'#FFFFFF',
                fontWeight: 500
            }}>
            JOIN OUR PROGRAMS            
        </button>
        </Link>
            </div>
        </div>
    </div>:
    <div className='m-tjp-container' style={{backgroundImage:`url(${mbg})`}}>
         <p className='m-tjp-heading2 font-1' style={{marginBottom:'0rem'}}>Get Your Health Reports</p>
          <p className='m-tjp-heading1 font-2'>TODAY!</p>
          <div className="font-1 m-tba-midPhrase" style={{ width: "85%",color:'white',position:'relative',left:'7.5%' }}>
                <p>
                Join 1000+ individuals and companies who are actively using Fitnastic’s tech to get accurate health reports and disease predictions.
                </p>
                <Link to="/programs">
            <button className='font-1'
            onClick={scrollToTop}
            style={{
          border: '2px', 
          borderColor:'#FFFFFF', 
          borderStyle: 'solid', 
          borderRadius: '50px', 
          paddingTop:'13px', 
          paddingBottom:'10px', 
          paddingLeft:'20px', 
          paddingRight:'20px',
          marginTop: '2rem',
          color:'#FFFFFF',
          fontWeight: '600' ,
          fontSize:'1rem',
          width:'70%',
          position:'relative',
          left:'15%', 
          }}>
            JOIN OUR PROGRAMS        
        </button>
        </Link>
          </div>
         
    </div>
    }
    </>
    
  )
}

export default TechJoinProgram