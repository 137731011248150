import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// import editedWomen from '../../images/editedWoman.png'
import './HealthReportBox.css'

function HealthReportBox() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mainStyles = {
    width: '57%',
    float: 'right-center'
  };

  const containerStyle = {
    height: 'auto',
    backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/editedWoman.png)`,
    backgroundSize: 'cover'
  }

  const factStyle = {
    width: '30%',
    borderRight: '1px solid #FFFFFF',
    paddingRight: '10px'
  }

  const mainReportBoxDivStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: '5rem',
    paddingBottom: '5rem',
  }

  return (
    <>
      {(!isMobile) ? <div style={containerStyle}>

        <div className="gradient-background mainReportBoxDivStyle">

          <div className='mainE' >
            <h3 style={{ color: '#FFFFFF', fontFamily: 'Acumin', fontWeight: 360, fontSize: '2rem' }}>Our Philosophy</h3>
            <h1 className='healthOverFitness'>Health Over Fitness</h1>
            <p className='font-1 overLast'>Over the last few years of starting fitnastic, we've seen proof within our community & in the media too that it's not necessary if someone is physically fit or is young - they'll be healthy.</p>
            <div className='factsBox'>

              <div style={factStyle}>
                <span style={{ fontSize: '12px', backgroundColor: '#CC203B', color: '#FFFFFF', fontStyle: 'italic', paddingRight: '5px', paddingLeft: '5px', paddingTop: '1px', paddingBottom: '2px' }}>People in their</span>
                <h1 className='font-2' style={{ color: '#FFFFFF' }}>20s</h1>
                <p className='font-1' style={{ color: '#FFFFFF', fontSize: '0.9rem' }}>have a very high disease risk score because of their current lifestyle & stress levels.</p>
              </div>

              <div style={{
                width: '30%',
                borderRight: '1px solid #FFFFFF',
                paddingRight: '10px',
                paddingLeft: '23px'
              }}>

                <span style={{ fontSize: '12px', backgroundColor: '#CC203B', color: '#FFFFFF', fontStyle: 'italic', paddingRight: '5px', paddingLeft: '5px', paddingTop: '1px', paddingBottom: '2px' }}>People in their</span>

                <h1 className='font-2' style={{ color: '#FFFFFF' }}>50s</h1>
                <p className='font-1' style={{ color: '#FFFFFF', fontSize: '0.9rem' }}>are slightly obese being healthier than people in their 20s & 30s</p>
              </div>

              <div style={{ width: '25%' }}>
                <p className='font-1' style={{ color: '#FFFFFF', fontSize: '0.9rem', fontWeight: '300' }}>Some stories in recent times have shown how big actors who are extremely fit have died due to cardiac arrest at an early age.</p>
              </div>
            </div>
            <Link to="/freeHealthReport">
            <button style={{
              border: '2px',
              borderColor: '#FFFFFF',
              borderStyle: 'solid',
              borderRadius: '50px',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '20px',
              paddingRight: '20px',
              marginTop: '2rem',
              color: '#FFFFFF',
              fontWeight: 500
            }}>
              GET YOUR FREE HEALTH REPORT
            </button>
            </Link>
           
          </div>

        </div>

      </div> :
        <div className="m-hrb-main-cont">
          <div style={
            {
              height: 'auto',
              backgroundImage: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/editedWoman.png)`,
              backgroundSize: 'cover'
            }
          }>
            <div className="m-hrb-top gradient-background">
              <h3 style={{
                color: '#FFFFFF',
                fontWeight: 360,
                fontSize: '1.4rem',
                textAlign: 'center',
                paddingTop: '3rem'
              }}
                className='font-1'
              >
                Our Philosophy
              </h3>

              <h1 style={{
                color: 'white',
                fontSize: '1.9rem',
                textAlign: 'center'
              }}
                className='font-2'
              >Health Over Fitness</h1>
              <p className='font-1 m-hrb-top-desc' style={{
                marginBottom: '0rem',
                textAlign: 'center',
                color: 'white',
                marginTop: '1.9rem',
                marginInline: '4.5rem'
              }}>Over the last few years of starting fitnastic, we've seen proof within our community & in the media too that it's not necessary if someone is physically fit or is young - they'll be healthy.</p>
            </div>
          </div>
          <div className='m-hrb-bottom'>
            <div  className='m-hrb-b' style={{
              borderBottom: '1px solid red'
            }}>
              <span className ='m-hrb-b1 font-2' style={{ 
                fontSize: '1rem', 
                backgroundColor: '#CC203B', 
                color: '#FFFFFF', 
                paddingRight: '5px', 
                paddingLeft: '5px', 
                paddingTop: '1px', 
                paddingBottom: '2px' }}>
                  People in their 20s
              </span>
              <p className='font-1 m-hrb-b2' style={{ color: 'black' }}>have a very high disease risk score because of their current lifestyle & stress levels.</p>
            </div>

            <div className='m-hrb-b' style={{
              borderBottom: '1px solid red',
            }}>

              <span className ='m-hrb-b1 font-2' style={{ 
                fontSize: '1rem', 
                backgroundColor: '#CC203B', 
                color: '#FFFFFF', 
                paddingRight: '5px', 
                paddingLeft: '5px', 
                paddingTop: '1px', 
                paddingBottom: '2px' }}>
                  People in their 50s
              </span>
              <p className='font-1 m-hrb-b2' style={{ color: 'black' }}>
                are slightly obese being healthier than people in their 20s & 30s
              </p>
            </div>

            <div className='m-hrb-b3' style={{}}>
              <p className='font-1' style={{ 
                color: 'black'
              }}>Some stories in recent times have shown how big actors who are extremely fit have died due to cardiac arrest at an early age.</p>
            </div>
            <Link to="/freeHealthReport">
            <button style={{
              border: '2px',
              borderColor: '#CC203B',
              borderStyle: 'solid',
              borderRadius: '50px',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '20px',
              paddingRight: '20px',
              marginTop: '2rem',
              color: '#CC203B',
              fontWeight: 500,
              marginBottom:'3.5rem'
            }}>
              GET YOUR FREE HEALTH REPORT
            </button>
            </Link>
          </div>
        </div>
      }
    </>
  );
}

export default HealthReportBox;
