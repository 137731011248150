import React from "react";
import UserSideProfile from "../../Components/UserSideProfile/UserSideProfile";
import "./StaffPage.css";
import StaffTable from "./StaffTable";

const StaffPage = () => {
  return (
    <div className="dhr-main-container">
      <div className="dhr-main-container-left">
        <UserSideProfile />
      </div>
      <div className="dhr-main-container-right">
        <div className="stp-top-div-container">
          <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',gap:'1rem'}}>
            <div className="stp-top-div-item stp-top-div-item-active font-1">Manager</div>
            <div className="stp-top-div-item font-1">Manager</div>
            <div className="stp-top-div-item font-1">Manager</div>
          </div>
        </div>
        <StaffTable />
      </div>
    </div>
  );
};

export default StaffPage;
