import { Image, Container } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import 'swiper/css/bundle';
import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectCoverflow, Autoplay, Pagination, Navigation } from "swiper";

// import asianWomen from '../images/programCrousel/asianwomen.jpg'
// import foodbowl from '../images/programCrousel/foodbowl.jpg'
// import personalTraining from '../images/programCrousel/personalTraining.jpg'
// import microscope from '../images/programCrousel/microscopeview.jpg'
import '../App.css'
import './ProgramsComponent.css';

import PersonalTrainingFormModal from './FormModal/PersonalTrainingForm/PersonalTrainingFormModal';
import PersonalizedNutritionFormModal from './FormModal/PersonalizedNutritionForm/PersonalizedNutritionFormModal';
import MetabolicHealthFormModal from './FormModal/MetabolicHealthFormModal/MetabolicHealthFormModal'
import LiveWorkoutFormModal from './FormModal/LiveWorkoutFormModal/LiveWorkoutFormModal';

import { EffectCoverflow, Autoplay, Pagination, Navigation } from 'swiper/modules';

function ProgramsComponent() {

  const [isPersonalTrainingModalOpen, setPersonalTrainingModalOpen] = useState(false);
  const [isNutritionModalOpen, setNutritionModalOpen] = useState(false);
  const [metabolicHealthModalOpen, setMetabolicHealthModalOpen] = useState(false);
  const [liveWorkoutFormModalOpen, setLiveWorkoutModalOpen] = useState(false);


  const openPersonalTrainingModal = () => setPersonalTrainingModalOpen(true);
  const closePersonalTrainingModal = () => setPersonalTrainingModalOpen(false);

  const openNutritionModal = () => setNutritionModalOpen(true);
  const closeNutritionModal = () => setNutritionModalOpen(false);

  const openMetabolicHealthModal = () => setMetabolicHealthModalOpen(true);
  const closeMetabolicHealthModal = () => setMetabolicHealthModalOpen(false);

  const openLiveWorkoutModal = () => setLiveWorkoutModalOpen(true);
  const closeLiveWorkoutModal = () => setLiveWorkoutModalOpen(false);
  

  return (
    <div className='mainDiv'style={{
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center',
      marginBottom:'110px'
      }}>

      <div className='pcdiv' style={{display: 'flex', gap:'15px', alignItems:'center', justifyContent:'center', marginBottom:'60px', marginTop:'40px'}}>
       <p className='font-1 tt' style={{color:'#CC203B', fontSize:'3.4vw'}}>Our <span className='font-2 tt' style={{fontWeight: 550, color:'#CC203B', fontSize:'3.4vw'}}>Programs</span></p>
      </div>

      <Container className='mySwiperDiv' style={{ height: '320px' }}>

        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'auto'}
          autoplay={
            {
              delay:1500,
              disableOnInteraction:false
            }
          }
          navigation={false}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true
          }}
          
          pagination={false}
          modules={[Navigation, Autoplay, EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide onClick={openLiveWorkoutModal} style={{ height: '325px', width: '400px' }}>
              <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/asianwomen.jpg)`,
                  height:'325px',
                  width:'400px',
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center'
                  }}>
                </div>
                <div>
                  <h2 className='pc-h2' style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>LIVE Workouts</h2>
                </div>
              </div>
          </SwiperSlide>
          
          

          <SwiperSlide onClick={openNutritionModal} style={{ height: '325px', width: '400px' }}>
          <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/foodbowl.jpg)`,
                  width:'400px',
                  height:'325px',
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center'
                  }}>
                </div>
                <div>
                  <h3 className='pc-h2' style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>Personlized Nutrition Plan</h3>
                </div>
              </div>

          </SwiperSlide>
          


          <SwiperSlide onClick={openPersonalTrainingModal} style={{ height: '325px', width: '400px' }}>
          <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/personalTraining.jpg)`,
                  height:'350px',
                  width:'400px',
                  backgroundSize: 'cover', 
                  backgroundRepeat:'no-repeat',
                  backgroundPosition: '0px 0px'
                  }}>
                </div>
                <div>
                  <h2 className='pc-h2' style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>Personal Training</h2>
                </div>
              </div>
          </SwiperSlide>

          <SwiperSlide onClick={openMetabolicHealthModal} style={{ height: '325px', width: '400px' }}>
          <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{
                  display:'flex',
                  background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/programCrousel/microscopeview.jpg)`,
                  height:'325px',
                  width:'400px',
                  backgroundSize: 'cover', 
                  backgroundPosition: 'center'
                  }}>
                </div>
                <div>
                  <h2 className='pc-h2' style={{
                    backgroundColor:'#023020', 
                    color:'white', 
                    zIndex: 10, 
                    position:'absolute', 
                    bottom:'0%',
                    marginBottom:0,
                    marginTop:0,
                    textAlign:'center',
                    width:'400px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    paddingTop:'15px',
                    paddingBottom:'15px'
                  }}>Metabolic Health Report</h2>
                </div>
              </div>
          </SwiperSlide>
        </Swiper>
      </Container>
      <PersonalizedNutritionFormModal isOpen={isNutritionModalOpen} onClose={closeNutritionModal} />
      <PersonalTrainingFormModal isOpen={isPersonalTrainingModalOpen} onClose={closePersonalTrainingModal} />
      <MetabolicHealthFormModal isOpen={metabolicHealthModalOpen} onClose={closeMetabolicHealthModal} />
      <LiveWorkoutFormModal isOpen={liveWorkoutFormModalOpen} onClose={closeLiveWorkoutModal}/>
    </div>
  );
}

export default ProgramsComponent;
