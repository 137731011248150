// src/Collapsible.js
import React, { useState } from 'react';
import './Collapsible.css';
import arrow from '../../../images/TechPageImages/TechBannerImages/downArrow.svg'
import upArrow from '../../../images/TechPageImages/TechBannerImages/upperArrow.svg'

const Collapsible = ({ number, titleText, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <div className="header font-2" onClick={toggleOpen} style={{color:'#CC203B'}}>
        
      <h2>
          <span className="number font-1">{number}</span> <span className='c-tt-title'>{titleText}</span>
        </h2>
       <>
       {isOpen? <div>
        <img src={upArrow} alt="" />
       </div>:
       <div>
        <img src={arrow} alt="" />
        </div>}
       </>
        
      </div>
      {isOpen && <div className="content font-1">{content}</div>}
    </div>
  );
};

export default Collapsible;
