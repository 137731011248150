import React from "react";
import { useState, useEffect } from "react";
// import firstBox from "../../images/Banner1.png";
// import secondBox from "../../images/Banner/Temp.svg"
import { Link } from "react-router-dom";
import updateClickCount from "../../network/ClickCounterApis/UpdateClickCounter";

const BannerPeople = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle = {
    height: "50vw",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center", // Align items to the start
    background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/Banner1.png)`,
    backgroundSize: "contain",
    backgroundPosition: "0px -5px",

    margin: 0,
    padding: 0,
    // position: 'relative', // Position relative to contain absolute positioned elements
  };

  const updateClick = async () => {
    await updateClickCount("HealthReportButton");
  };

  return (
    <>
      {!isMobile ? (
        <div style={containerStyle}>
          <div className="mainStyles">
            <p
              className="font-1"
              style={{ color: "#FFFFFF", fontSize: "3vw", fontWeight: 400 }}
            >
              Join 1,52,283+ People
            </p>
            <h1
              className="font-2"
              style={{
                color: "#FFFFFF",
                fontSize: "3vw",
                marginTop: "-20px",
              }}
            >
              In Getting Healthy!
            </h1>
            <Link to="/freeHealthReport">
              <button
                onClick={updateClick}
                style={{
                  border: "2px",
                  borderColor: "#FFFFFF",
                  borderStyle: "solid",
                  borderRadius: "50px",
                  paddingTop: "0.7rem",
                  paddingBottom: "0.7rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginTop: "5%",
                  color: "#FFFFFF",
                  fontSize: "1.5vw",
                  fontWeight: 550,
                }}
                className="font-1"
              >
               GET FREE HEALTH REPORT
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div style={{background: `url(https://fitnastic-resources.s3.ap-south-1.amazonaws.com/images/New+Mobile+Banner+SVGs/Mobile+Banner+1-01.svg)`,height:'80vh',width:'100%',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPositionY:'100%',display:'flex',alignItems:'start',justifyContent:'center'}}>
          <div style={{width:'100%',textAlign:'center',paddingTop:'15%'}}>
            <p
              className="font-1"
              style={{ color: "#FFFFFF", fontSize: "9vw", fontWeight: 400 ,marginBottom:'0%',lineHeight:'0.8'}}
            >
              Join
            </p>
            <p
              className="font-1"
              style={{ color: "#FFFFFF", fontSize: "9vw", fontWeight: 400 ,marginBottom:'0%'}}
            >
             1,52,283+ People
            </p>
            <h1
              className="font-2"
              style={{
                color: "#FFFFFF",
                fontSize: "9vw",
                lineHeight:'0.8',
                fontWeight:'100'
              }}
            >
              In Getting Healthy!
            </h1>
            <Link to="/freeHealthReport">
              <button
                onClick={updateClick}
                style={{
                  border: "2px",
                  borderColor: "#FFFFFF",
                  borderStyle: "solid",
                  borderRadius: "50px",
                  paddingTop: "0.7rem",
                  paddingBottom: "0.5rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  marginTop: "5%",
                  color: "#FFFFFF",
                  fontSize: "5vw",
                  fontWeight: 550,
                  textAlign:'center'
                }}
                className="font-1"
              >
               GET FREE HEALTH REPORT
              </button>
            </Link>
          </div>


        </div>
      )}
    </>
  );
};

export default BannerPeople;