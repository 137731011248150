import React, { useState } from "react";
import "./ThankModal.css";
import { useNavigate } from "react-router";
import axios from "axios";

const ThankModal = ({ onClose }) => {
 

  return (
    <>
      <div
        className="modal"
        // onRequestClose={onClose}
        contentLabel="Example Modal"
      >
        <div
          style={{
            width: "24rem",
            height: "16rem",
            background: "white",
            borderRadius: "15px",
            boxShadow: "0px 0px 8px 0px #00000040",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            animationName: "fadeIn",
            animationDuration: "0.5s"
          }}
        >
            <div style={{width:'100%',height:'100%', display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'start'}}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',paddingTop:'1.5rem',paddingBottom:'1rem',borderBottomWidth:'1.5px',borderBottomColor:'#CC203B',width:'100%'}}>
            <p className="font-1" style={{fontSize:'1.6rem',color:'#CC203B',marginBottom:'0rem',fontWeight:'600'}}>Thank you!</p>
              </div>
            <p className="font-1" style={{fontSize:'1rem',marginTop:'1.5rem',textAlign:'center'}}>Our team will get in touch <br /> with you shortly.</p>
            <button
            className="cib-btn font-1"
            onClick={onClose}
              style={{
                border: "1.5px",
                borderColor: "#CC203B",
                borderStyle:'solid',
                borderWidth:'2px',
                borderRadius: "50px",
                paddingTop: "0.55rem",
                paddingBottom: "0.55rem",
                paddingLeft: "1.6rem",
                paddingRight: "1.6rem",
                color: "#CC203B",
                fontSize: "0.9rem",
                marginTop: "0.5rem",
                fontWeight: 600,
                width:'7rem'
              }}
            >
              OKAY
            </button>
         
            </div>
          
         
        </div>
      </div>
    </>
  );
};

export default ThankModal;
